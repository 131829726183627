import { erpNextAxiosCall } from 'src/setup/erpnextApiHelper';

export async function getERPNextRoleData(role_id: any) {
  try {
    const { response, error, status }: any = await erpNextAxiosCall({
      action: 'get',
      url: `/api/method/shipmnts.controllers.user_management.get_role`,
      payload: { role_id },
    });
    if (error) throw error;
    return { response, status };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function createNewPermissionTag(data: { tag: string; description: string }) {
  try {
    const { response, error, status }: any = await erpNextAxiosCall({
      action: 'post',
      url: `/api/method/shipmnts.controllers.user_management.create_permission_tag`,
      payload: { data: data },
    });
    if (error) throw error;
    return { response, status };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
