import React, { useRef, useState } from 'react';
import {
  Layout,
  GlobalViewLayout,
  Flex,
  Button,
  Dropdown,
  hasPermission,
  AppHelmet,
  Typography,
  MoreOutlined,
  CustomFieldForm,
} from '@shipmnts/pixel-hub';
import {
  DeleteCustomField,
  DuplicateCustomField,
  EditCustomField,
} from './CustomFieldActions/customFieldActionRender';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import {
  PERMISSION_SYSTEM_ADMINISTRATION_CREATE,
  PERMISSION_SYSTEM_ADMINISTRATION_DELETE,
  PERMISSION_SYSTEM_ADMINISTRATION_EDIT,
} from '../common/constants';

const { Content } = Layout;

const CustomFields = () => {
  const sessionData: SessionDataType = sessionStore.getSessionData();
  const [visible, setVisible] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  const refetchReport = () => {
    setRefetch(!refetch);
  };

  const getGlobalView = () => {
    const globalView: any = {
      id: '1',
      name: 'CustomFields',
      color: '#eeeeee',
      type: 'grid',
      doc_type_id: 'Blueprint::CustomField',
      definition: {
        sort: [],
        fields: [
          {
            id: 'blueprint_fields_label',
            label: 'Label',
            field_type: 'String',
            cell_renderer: {
              args: {
                id_field: 'id',
                doc_type_id: 'Blueprint::CustomField',
              },
              function: 'link_render',
            },
            filterable: true,
            section: 'Basic',
          },
          {
            id: 'blueprint_fields_field_type',
            label: 'Field Type',
            field_type: 'String',
            groupable: true,
            filterable: true,
            section: 'Basic',
          },
          {
            id: 'blueprint_fields_doc_type_id',
            label: 'Object',
            field_type: 'String',
            groupable: true,
            filterable: true,
            section: 'Basic',
          },
          {
            id: 'blueprint_fields_search_doctype',
            label: 'Search Doctype',
            field_type: 'String',
            groupable: true,
            filterable: true,
            section: 'Basic',
          },
          {
            id: 'blueprint_fields_created_at',
            label: 'Created At',
            field_type: 'DateTime',
            sortable: true,
            filterable: true,
            section: 'Basic',
          },
          {
            id: 'blueprint_fields_created_by_id',
            label: 'Created By',
            field_type: 'Search',
            filter_options: JSON.stringify({
              doc_type: 'Network::UserAccount',
            }),
            record_loader_config: {
              doc_type: 'Network::UserAccount',
              attribute: 'name',
            },
            cell_renderer: {
              args: {},
              function: 'record_loader_render',
            },
            filterable: true,
            section: 'Basic',
            groupable: true,
          },
          {
            id: 'blueprint_fields_description',
            label: 'Description',
            field_type: 'String',
            filterable: true,
            section: 'Basic',
          },
        ],
        filters: [],
      },
      standard_report: true,
      is_disabled: false,
      global: true,
    };
    return globalView;
  };

  const actionColumn = {
    headerName: 'Actions',
    lockPosition: 'right',
    suppressMovable: true,
    colId: 'actions',
    field: 'actions',
    valueGetter: 'data',
    width: 100,
    pinned: 'right',
    headerComponent: 'static_header_component',
    suppressNavigable: true,
    cellRenderer: (params: any) => {
      const getItems = () => {
        const items: any[] = [];
        if (
          hasPermission(sessionData.permissions, {
            name: PERMISSION_SYSTEM_ADMINISTRATION_EDIT,
            docType: 'Blueprint::CustomField',
          })
        )
          items.push({
            key: '1',
            label: <EditCustomField data={params?.data} refetchReport={refetchReport} />,
          });
        if (
          hasPermission(sessionData.permissions, {
            name: PERMISSION_SYSTEM_ADMINISTRATION_CREATE,
            docType: 'Blueprint::CustomField',
          })
        )
          items.push({
            key: '2',
            label: <DuplicateCustomField data={params?.data} refetchReport={refetchReport} />,
          });
        if (
          hasPermission(sessionData.permissions, {
            name: PERMISSION_SYSTEM_ADMINISTRATION_DELETE,
            docType: 'Blueprint::CustomField',
          })
        )
          items.push({
            key: '3',
            label: <DeleteCustomField data={params?.data} refetchReport={refetchReport} />,
          });
        return items;
      };

      return (
        <Dropdown
          menu={{
            style: { minWidth: '200px' },
            className: 'actions-menu',
            items: getItems(),
          }}
          trigger={['click']}
        >
          <Button size="small">
            <MoreOutlined rotate={90} />
          </Button>
        </Dropdown>
      );
    },
  };
  const globalView = useRef(getGlobalView());

  const openModal = () => {
    setVisible(true);
  };

  const checkPermition = () => {
    if (
      hasPermission(sessionData.permissions, {
        name: PERMISSION_SYSTEM_ADMINISTRATION_CREATE,
        docType: 'Blueprint::CustomField',
      })
    )
      return true;
    return false;
  };

  const title = 'Custom Fields';
  return (
    <Layout style={{ padding: '0 0 15px 15px' }}>
      <AppHelmet>
        <title>{title}</title>
      </AppHelmet>
      <Flex justify="space-between" style={{ marginBottom: '16px' }}>
        <div>
          <Typography.Text style={{ fontSize: '18px', color: 'var(--ant-color-primary)' }}>
            Custom Fields
          </Typography.Text>
        </div>
        {checkPermition() && (
          <Button size="small" onClick={openModal} type="primary">
            Create Custom Fields
          </Button>
        )}
      </Flex>
      <CustomFieldForm
        // onSuccess={onSuccess}
        // setVisible={setVisible}
        visible={visible}
        // data={field.meta_data}
      />
      <Content>
        <GlobalViewLayout
          defaultFilters={[]}
          doc_type_id={'Blueprint::CustomField'}
          globalView={globalView.current}
          height="70vh"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore //todo: fix type for extraColDefs
          extraColDefs={[actionColumn]}
        />
      </Content>
    </Layout>
  );
};

export default CustomFields;
