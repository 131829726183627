import React from 'react';
import UserList from '../User/UserList';
import { InfoCircleOutlined } from '@shipmnts/pixel-hub';

interface AssignedUserSectionProps {
  users: Array<any>;
  role_name: string;
  role_id?: string;
}

export const AssignedUserSection = ({ users, role_id, role_name }: AssignedUserSectionProps) => {
  return (
    <>
      <div
        style={{
          color: 'var(--primary-grey)',
          fontWeight: 500,
        }}
      >
        <InfoCircleOutlined style={{ color: 'orange' }} />
        <span style={{ marginLeft: '5px' }}>
          <b>Users assigned this role</b>
        </span>
      </div>
      <UserList users={users} />
    </>
  );
};
