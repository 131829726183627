import { useMutation } from '@apollo/client';
import { Popconfirm, QuestionCircleOutlined, message } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { UPDATE_PIPELINE } from 'src/graphQL/pipeline';

type Props = {
  pipeline: {
    blueprint_pipelines_id: string;
    blueprint_pipelines_name: { value: string };
    blueprint_pipelines_is_active: boolean;
  };
  pipeline_id: string;
  refetchData?: () => void;
};

const EnableDisablePipeline = (props: Props) => {
  const [open, setopen] = useState(false);

  const [disableTracker, { data }] = useMutation(UPDATE_PIPELINE);

  useEffect(() => {
    if (data) {
      message.success('Pipeline updated sucessfully');
    }
  }, [data]);

  const actionType = props.pipeline.blueprint_pipelines_is_active ? 'Disable' : 'Enable';

  return (
    <>
      <Popconfirm
        title="Disable Pipeline"
        description={`Are you sure to ${actionType} ${props.pipeline.blueprint_pipelines_name?.value} pipeline?`}
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        open={open}
        onCancel={() => setopen(false)}
        okText={actionType}
        onConfirm={() =>
          disableTracker({
            variables: {
              pipeline: {
                id: props.pipeline_id,
                is_active: actionType === 'Disable' ? false : true,
              },
            },
          }).then((r) => {
            if (props.refetchData) props.refetchData();
            setopen(false);
          })
        }
      >
        <div onClick={() => setopen(true)}>{actionType}</div>
      </Popconfirm>
    </>
  );
};

export default EnableDisablePipeline;
