import { ICellRendererParams } from '@ag-grid-community/core';
import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Action, PerformAction } from './commonTypeDefs';
import { NEGATIVE_TYPE, PRIMARY_TYPE, SECONDARY_TYPE, TERTIARY_TYPE } from './constants';
import { getActionClassName } from './helpers';
import {
  Button,
  DownOutlined,
  Dropdown,
  MenuProps,
  MoreOutlined,
  SizeType,
  Skeleton,
} from '@shipmnts/pixel-hub';
import React from 'react';
import { helperMap } from './ActionRendererHelper';
import { useLazyQuery } from '@apollo/client';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import { useLocation } from 'wouter';

type MenuItem = Required<MenuProps>['items'][number];

interface ActionRendererType extends ICellRendererParams {
  doc_type_id: string;
  refetchData?: () => void;
  selectedActions?: any[];
}
export interface ActionRendererProps {
  id: string;
  doc_type_id: string;
  isDetailScreen?: boolean;
  children?: JSX.Element;
  selectedActions?: any[];
  data?: any;
  refetchData?: () => void;
  buttonSize?: SizeType;
  forceDropDown?: boolean;
}

export default function ActionRendererReportNew(props: ActionRendererType) {
  if (!props.node.data) return <></>;
  if (props.node.rowPinned) return <span></span>;

  return (
    <ActionRenderer
      id={props.node.data?.id}
      doc_type_id={props.doc_type_id}
      refetchData={props.refetchData}
      selectedActions={props.selectedActions}
    />
  );
}

export function ActionRendererDetailReportNew(props: ActionRendererType) {
  if (!props.node.data) return <></>;
  if (props.node.rowPinned) return <span></span>;
  return (
    <ActionRenderer
      id={props.node.data.id}
      data={props.node.data}
      doc_type_id={props.doc_type_id}
      refetchData={props.refetchData}
      selectedActions={props.selectedActions}
    />
  );
}

export function ActionRenderer(props: ActionRendererProps) {
  const {
    id,
    doc_type_id,
    data,
    refetchData,
    selectedActions,
    buttonSize = 'middle',
    forceDropDown,
  } = props;
  const [actionParams, setActionParams] = useState<any>({});
  const [TaskComponent, setTaskComponent] = useState<React.JSXElementConstructor<any>>();
  const [menuItems, setMenuItem] = useState<Action[]>(selectedActions || []);
  const [actionsWithType, setActionWithType] = useState(groupBy(menuItems, 'type'));
  const [record, setRecord] = useState(data);
  const sessionData: SessionDataType = sessionStore.getSessionData();

  const { 1: navigate } = useLocation();

  const helperField = helperMap[doc_type_id];
  const [fetchDataQuery, { data: fetchedData, loading: fetchingData }] = useLazyQuery(
    helperField.query,
    {
      variables: {
        id: id,
      },
    }
  );

  useEffect(() => {
    if (data) {
      setRecord(data);
    }
  }, [data]);

  useEffect(() => {
    setRecord(fetchedData?.[helperField.field_name]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData]);

  useEffect(() => {
    setMenuItem(helperMap[doc_type_id].getActions(record, navigate, refetchData, sessionData));
  }, [doc_type_id, navigate, record, refetchData, sessionData]);

  useEffect(() => {
    if (selectedActions) {
      setMenuItem(selectedActions);
    }
  }, [selectedActions]);

  const refetch = () => {
    if (refetchData) {
      refetchData();
    }
  };

  const fetchData = async () => {
    id && fetchDataQuery();
  };

  useEffect(() => {
    setActionWithType(groupBy(menuItems, 'type'));
  }, [menuItems]);

  const handleActions = async (action: Action) => {
    let result: PerformAction | undefined;
    if (doc_type_id === 'Network::UserAccount') {
      if (action?.performAction) {
        result = await action.performAction({
          user: record,
        });
      }
    } else if (doc_type_id === 'Network::Role') {
      if (action?.performAction) {
        result = await action.performAction({
          role: record,
        });
      }
    }
    if (result) {
      setActionParams(result?.actionParams);
      if (result) {
        setTaskComponent(() => result?.component);
      }
    }
  };

  const onSuccess = (...args: any[]) => {
    if (actionParams?.onSuccess) {
      actionParams.onSuccess(...args);
    }
    refetch();
    if (onCloseModal) onCloseModal();
  };

  const onCloseModal = () => {
    setTaskComponent(undefined);
    setActionParams({});
  };

  const renderActions: Action[][] = [];
  [PRIMARY_TYPE, SECONDARY_TYPE, TERTIARY_TYPE, NEGATIVE_TYPE].forEach((type: string) => {
    if (actionsWithType && actionsWithType[type]) {
      const currentAction = actionsWithType[type].filter((action) => action.isEnable);
      if (currentAction.length > 0) {
        renderActions.push(currentAction);
      }
    }
  });
  const menuItemsList: MenuItem[] = [];

  const renderActionByMenuItems = () => {
    if (data && menuItems.length === 1 && !forceDropDown) {
      if (menuItems[0].childComponents) {
        const childComponentsLength = menuItems[0].childComponents.filter(
          (c: any) => c.isEnable
        ).length;

        const getItem = menuItems[0].childComponents.map((child: any) =>
          child.isEnable
            ? {
                key: child.key,
                onClick: () => {
                  handleActions(child);
                  if (child.onClick) {
                    child.onClick(onSuccess);
                  }
                },
                className: getActionClassName(menuItems[0].type, !!menuItems[0].isAdmin),
                icon: child.icon,
                label: child.displayComponent,
              }
            : null
        );
        return (
          <>
            {childComponentsLength > 0 && (
              <Dropdown
                menu={{ items: getItem }}
                key={menuItems[0].key}
                trigger={['click']}
                placement="bottomLeft"
              >
                <Button type={menuItems[0].type === 'primary' ? 'primary' : undefined}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '8px' }} className="action-icon">
                      {menuItems[0].icon}
                    </span>
                    {menuItems[0].displayComponent}
                  </div>
                </Button>
              </Dropdown>
            )}
          </>
        );
      } else {
        const buttonType = menuItems[0].type === 'primary' ? 'primary' : undefined;
        return (
          <>
            {menuItems[0].isEnable && (
              <Button
                type={buttonType}
                onClick={() => {
                  handleActions(menuItems[0]);
                  if (menuItems[0].onClick) {
                    menuItems[0].onClick(onSuccess);
                  }
                }}
                size={buttonSize}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }} className="action-icon">
                    {menuItems[0].icon}
                  </span>
                  {menuItems[0].displayComponent}
                </div>
              </Button>
            )}
          </>
        );
      }
    } else {
      const getDropDownItems = () => {
        if (record && renderActions.length === 0)
          menuItemsList.push({
            disabled: true,
            label: 'No Actions Available',
            key: '0',
          });
        if ((!record && renderActions.length === 0) || fetchingData) {
          menuItemsList.push({
            key: '0',
            label: <Skeleton active paragraph={{ width: '150px' }} />,
          });
        }
        renderActions.map((actions: Action[], actionsId: number) => {
          return actions.map((item: Action, index: number) => {
            let description = item.displayComponent;
            if (typeof item.displayComponent === 'function') {
              const ComponentWrapper = item.displayComponent;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              description = <ComponentWrapper key={item.key} {...item.componentProps} />;
            }
            if (!item.isEnable) {
              return null;
            }
            if (item.childComponents && item.childComponents.length === 0) return null;
            if (item.childComponents && item.childComponents.length > 0) {
              const childComponentsLength = item.childComponents.filter(
                (c: any) => c.isEnable
              ).length;
              if (childComponentsLength > 0) {
                menuItemsList.push({
                  key: item.key,
                  icon: item.icon,
                  label: description + ` (${childComponentsLength})`,
                  children: item.childComponents.map((child: any) => {
                    if (child.isEnable) {
                      return {
                        key: child.key,
                        onClick: () => {
                          handleActions(child);
                          if (child.onClick) {
                            child.onClick(onSuccess);
                          }
                        },
                        className: getActionClassName(item.type, !!item.isAdmin),
                        icon: child.icon,
                        label: child.displayComponent,
                        disabled: false,
                      };
                    }
                    return null;
                  }),
                });
              }
              if (index === actions.length - 1 && actionsId !== renderActions.length - 1) {
                menuItemsList.push({
                  type: 'divider',
                });
              }
            }
            if (!item.childComponents) {
              menuItemsList.push({
                key: item.key,
                className: getActionClassName(item.type, !!item.isAdmin),
                onClick: () => {
                  handleActions(item);
                  if (item.onClick) {
                    item.onClick(onSuccess);
                  }
                },
                icon: item.icon,
                label: description,
              });
              if (index === actions.length - 1 && actionsId !== renderActions.length - 1)
                menuItemsList.push({
                  type: 'divider',
                });
            }

            return null;
          });
        });
        return menuItemsList;
      };

      return (
        <Dropdown
          placement="bottomLeft"
          disabled={!!TaskComponent}
          menu={{
            style: { minWidth: '200px' },
            className: 'actions-menu',
            items: getDropDownItems(),
          }}
          trigger={['click']}
        >
          {props?.children ? (
            <div style={{ cursor: 'pointer' }} onClick={fetchData}>
              {props?.children}
            </div>
          ) : !props.isDetailScreen ? (
            <Button size="small" onClick={fetchData}>
              <MoreOutlined rotate={90} />
            </Button>
          ) : (
            <Button size="small" onClick={fetchData}>
              Actions <DownOutlined />
            </Button>
          )}
        </Dropdown>
      );
    }
  };

  return (
    <>
      {renderActionByMenuItems()}
      {TaskComponent && (
        <TaskComponent
          {...actionParams}
          visible={true}
          onClose={onCloseModal} // make sure that you are handling onClose and onSuccess
          onCancel={onCloseModal} // if not handled, it will not reopen modal
          onSuccess={onSuccess} // onSuccess it will refetch report
        />
      )}
    </>
  );
}
