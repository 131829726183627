import { gql } from '@apollo/client';

const fields = gql`
  fragment fields on FieldObjectType {
    id
    field_type
    db_column_name
    filter_options
    label
    description
    width
    cell_renderer
    cell_editor
    sortable
    filterable
    groupable
    section
    doc_type_id
    editable
    use_record_loader
    record_loader_config
    hooks
    select_query_display
    type
  }
`;

export const doctypeFields = gql`
  fragment doctypeFields on DocTypeObjectType {
    id
    description
    label
    fields {
      ...fields
    }
    doc_type_associations {
      optional
      to_doc_type {
        id
        label
        fields {
          ...fields
        }
      }
    }
  }
  ${fields}
`;

export const GET_DOCTYPE = gql`
  query doc_type($id: ID!) {
    doc_type(id: $id) {
      ...doctypeFields
    }
  }
  ${doctypeFields}
`;

export const GET_FIELDS = gql`
  query field($id: ID!) {
    field(id: $id) {
      ...fields
    }
  }
  ${fields}
`;

export const GET_SECTION = gql`
  query get_sections($doc_type_id: String!) {
    get_sections(doc_type_id: $doc_type_id)
  }
`;

export const GET_FIELDS_FROM_DOC_TYPE = gql`
  query get_fields_from_doc_type($doc_type_id: String!) {
    get_fields_from_doc_type(doc_type_id: $doc_type_id) {
      id
      db_column_name
      filterable
      width
      field_type
      label
      filter_options
      cell_renderer
      cell_editor
      type
    }
  }
`;

export const UPSERT_FIELDS = gql`
  mutation upsertCustomFields($custom_field: CustomFieldInputType!) {
    upsert_custom_field(custom_field: $custom_field) {
      ...fields
    }
  }
  ${fields}
`;

export const DELETE_CUSTOM_FIELD = gql`
  mutation delete_custom_field($id: ID!) {
    delete_custom_field(id: $id) {
      id
    }
  }
`;
