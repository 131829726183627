import { Flex, Layout } from '@shipmnts/pixel-hub';
import React, { useContext, useState } from 'react';
import ApplicationHeader from './ApplicationHeader';
import SideBar from './SideBar';
import { NAVBAR_HEIGHT } from 'shipmnts_navigation';

const NavbarContext = React.createContext();
export const useNavbarContext = () => useContext(NavbarContext);

const NavbarContextWrapper = React.memo(function ApplicationLayout(props) {
  const [navJson, setNavJson] = useState(undefined);

  return (
    <NavbarContext.Provider value={{ navJson }}>
      <Layout className="layout" style={{ height: '100vh' }}>
        <ApplicationHeader setNavJson={setNavJson} />
        <Flex style={{ height: '100%', width: '100%', paddingTop: NAVBAR_HEIGHT }}>
          <div className="sider">
            <SideBar />
          </div>
          <div style={{ overflow: 'auto', width: '100%', margin: '5px 0', padding: '0 15px' }}>
            {props.children}
          </div>
        </Flex>
      </Layout>
    </NavbarContext.Provider>
  );
});

export default NavbarContextWrapper;
