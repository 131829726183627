import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Checkbox,
  Flex,
  Space,
  TableProps,
  Table,
  message,
  Select,
  Tag,
  TabsProps,
  Tabs,
} from '@shipmnts/pixel-hub';
import { GET_ALL_DOCTYPES_PERMISSIONS } from 'src/graphQL/role';
import { permissionJsonToArrayMapper } from 'src/helpers/helpers';
import LoadingSpinner from '../common/LoadingSpinner';
import { startCase } from 'lodash';

interface PermissionRowType {
  key: string;
  doc_type: string;
  transaction_permissions: any;
  actions_permissions?: any;
  is_collaborator: boolean;
  is_erpnext_permission?: boolean;
}

interface PermissionMatrixProps {
  readonly?: boolean;
  onChange?: (data: any) => void;
  value?: any;
  role_id?: string;
}

const PermissionMatrix = ({ readonly, onChange, value, role_id }: PermissionMatrixProps) => {
  const [defaultPermissions, setDefaulPermissions] = useState<any>({});
  const [permissionTableData, setPermissionTableData] = useState<any>();

  const {
    data: permissionsList,
    error: permissionsError,
    loading: permissionsLoading,
  } = useQuery(GET_ALL_DOCTYPES_PERMISSIONS);

  useEffect(() => {
    if (permissionsError) {
      message.error('Error while fetching Permissions');
    } else if (permissionsList) {
      setDefaulPermissions(permissionsList?.get_all_doctype_permissions);
    }
  }, [permissionsList, permissionsError]);

  useEffect(() => {
    setPermissionTableData({
      transaction_permissions: permissionJsonToArrayMapper(
        value || [],
        defaultPermissions?.transaction_permissions || [],
        readonly
      ),
      master_permissions: permissionJsonToArrayMapper(
        value || [],
        defaultPermissions?.master_permissions || [],
        readonly
      ),
      report_page_permissions: permissionJsonToArrayMapper(
        value || [],
        defaultPermissions?.report_page_permissions || [],
        readonly,
        ['access']
      ),
    });
  }, [value, defaultPermissions, readonly]);

  const transactionColData: TableProps<PermissionRowType>['columns'] = [
    {
      dataIndex: 'doc_type',
      key: 'doc_type',
      hidden: true,
    },
    {
      dataIndex: 'is_erpnext_permission',
      key: 'is_erpnext_permission',
      hidden: true,
    },
    {
      title: 'Data Model',
      dataIndex: 'label',
      key: 'label',
      width: 180,
    },
    {
      title: 'Visibility',
      dataIndex: 'is_collaborator',
      key: 'is_collaborator',
      width: 240,
      render: (is_collaborator, record: PermissionRowType) =>
        record.is_erpnext_permission ? (
          <></>
        ) : (
          <Select
            disabled={readonly}
            value={!!is_collaborator}
            style={{ width: '10rem' }}
            popupMatchSelectWidth={false}
            onChange={(selectValue) => {
              let isExistingPermission = false;
              const updatedPermissions = (value || []).map((permission: any) => {
                if (permission['doc_type'] === record['doc_type']) {
                  permission['is_collaborator'] = selectValue;
                  permission['is_erpnext_permission'] = record?.is_erpnext_permission || false;
                  isExistingPermission = true;
                }
                return permission;
              });
              if (!isExistingPermission) {
                const newPermission = {
                  doc_type: record?.doc_type,
                  is_collaborator: selectValue,
                  is_erpnext_permission: record?.is_erpnext_permission || false,
                  types: {
                    // TODO: find a way to remove these below
                    // eslint-disable-next-line
                    // @ts-ignore
                    ...record?.transaction_permission,
                    // eslint-disable-next-line
                    // @ts-ignore
                    ...record?.action_permission,
                  },
                };
                updatedPermissions.push(newPermission);
              }
              onChange && onChange(updatedPermissions);
            }}
          >
            <Select.Option value={false}>All</Select.Option>
            <Select.Option value={true} style={{ textWrap: 'wrap' }}>
              Show only created & shared records
            </Select.Option>
          </Select>
        ),
    },
    {
      title: 'Transaction Permissions',
      dataIndex: 'transaction_permission',
      key: 'transaction_permission',
      width: 300,
      render: (transaction_permission: { [key: string]: boolean }, record: PermissionRowType) => {
        return (
          <Space wrap={true}>
            {Object.keys(transaction_permission).map((perm: string, ind) => (
              <Checkbox
                key={ind}
                checked={transaction_permission[perm]}
                disabled={readonly}
                onChange={(e) => {
                  let isExistingPermission = false;
                  const updatedPermissions = (value || []).map((permission: any) => {
                    if (permission['doc_type'] === record['doc_type']) {
                      permission['types'][perm] = e.target.checked;
                      permission['is_erpnext_permission'] = record?.is_erpnext_permission || false;
                      isExistingPermission = true;
                    }
                    return permission;
                  });
                  if (!isExistingPermission) {
                    const newPermission = {
                      doc_type: record?.doc_type,
                      is_collaborator: record?.is_collaborator,
                      is_erpnext_permission: record?.is_erpnext_permission || false,
                      types: {
                        // TODO: find a way to remove these below
                        // eslint-disable-next-line
                        // @ts-ignore
                        ...record?.transaction_permission,
                        // eslint-disable-next-line
                        // @ts-ignore
                        ...record?.action_permission,
                      },
                    };
                    newPermission['types'][perm] = e.target.checked;
                    updatedPermissions.push(newPermission);
                  }
                  onChange && onChange(updatedPermissions);
                }}
              >
                {startCase(perm)}
              </Checkbox>
            ))}
          </Space>
        );
      },
    },
    {
      title: 'Actions Permissions',
      key: 'action_permission',
      dataIndex: 'action_permission',
      width: 240,
      render: (action_permission: { [key: string]: boolean }, record: PermissionRowType) => {
        return (
          <Flex wrap="wrap" gap={5}>
            {Object.keys(action_permission).map((perm: string, ind) => (
              <Checkbox
                key={ind}
                checked={action_permission[perm]}
                disabled={readonly}
                onChange={(e) => {
                  let isExistingPermission = false;
                  const updatedPermissions = (value || []).map((permission: any) => {
                    if (permission['doc_type'] === record['doc_type']) {
                      permission['types'][perm] = e.target.checked;
                      permission['is_erpnext_permission'] = record?.is_erpnext_permission || false;
                      isExistingPermission = true;
                    }
                    return permission;
                  });
                  if (!isExistingPermission) {
                    const newPermission = {
                      doc_type: record?.doc_type,
                      is_collaborator: record?.is_collaborator,
                      is_erpnext_permission: record?.is_erpnext_permission || false,
                      types: {
                        // TODO: find a way to remove these below
                        // eslint-disable-next-line
                        // @ts-ignore
                        ...record?.transaction_permission,
                        // eslint-disable-next-line
                        // @ts-ignore
                        ...record?.action_permission,
                      },
                    };
                    newPermission['types'][perm] = e.target.checked;
                    updatedPermissions.push(newPermission);
                  }
                  onChange && onChange(updatedPermissions);
                }}
              >
                {startCase(perm)}
              </Checkbox>
            ))}
          </Flex>
        );
      },
    },
  ];

  const reportPageColData: TableProps<PermissionRowType>['columns'] = [
    {
      dataIndex: 'doc_type',
      key: 'doc_type',
      hidden: true,
    },
    {
      dataIndex: 'is_erpnext_permission',
      key: 'is_erpnext_permission',
      hidden: true,
    },
    {
      title: 'Report Group',
      dataIndex: 'label',
      key: 'label',
      width: 180,
      render: (label) => <div>{label}</div>,
    },
    {
      title: 'Reports Included',
      dataIndex: 'included_reports',
      key: 'included_reports',
      width: 300,
      render: (included_reports) => (
        <div>
          {included_reports.map((report: string) => (
            <Tag key={report} style={{ marginBottom: '3px' }}>
              {startCase(report)}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: 'Visibility',
      dataIndex: 'transaction_permission',
      key: 'transaction_permission',
      width: 150,
      render: (transaction_permission: { [key: string]: boolean }, record: PermissionRowType) => {
        return (
          <Space wrap={true}>
            {Object.keys(transaction_permission).map((perm: string, ind) => (
              <Checkbox
                key={ind}
                checked={transaction_permission[perm]}
                disabled={readonly}
                onChange={(e) => {
                  let isExistingPermission = false;
                  const updatedPermissions = (value || []).map((permission: any) => {
                    if (permission['doc_type'] === record['doc_type']) {
                      permission['types'][perm] = e.target.checked;
                      permission['is_erpnext_permission'] = record?.is_erpnext_permission || false;
                      isExistingPermission = true;
                    }
                    return permission;
                  });
                  if (!isExistingPermission) {
                    const newPermission = {
                      doc_type: record?.doc_type,
                      is_collaborator: record?.is_collaborator,
                      is_erpnext_permission: record?.is_erpnext_permission || false,
                      types: {
                        // TODO: find a way to remove these below
                        // eslint-disable-next-line
                        // @ts-ignore
                        ...record?.transaction_permission,
                        // eslint-disable-next-line
                        // @ts-ignore
                        ...record?.action_permission,
                      },
                    };
                    newPermission['types'][perm] = e.target.checked;
                    updatedPermissions.push(newPermission);
                  }
                  onChange && onChange(updatedPermissions);
                }}
              >
                {startCase(perm)}
              </Checkbox>
            ))}
          </Space>
        );
      },
    },
  ];

  if (permissionsLoading) {
    return <LoadingSpinner loadingMessage="Loading Permissions..." />;
  }

  const tabs: TabsProps['items'] = [
    {
      key: 'transaction_permission',
      label: 'Transaction Permissions',
      children: (
        <Table
          pagination={false}
          columns={transactionColData}
          dataSource={permissionTableData?.transaction_permissions || []}
          scroll={{ y: 400 }}
          locale={{ emptyText: 'No Permissions Found' }}
        />
      ),
    },
    {
      key: 'master_permission',
      label: 'Master Permissions',
      children: (
        <Table
          pagination={false}
          columns={transactionColData}
          dataSource={permissionTableData?.master_permissions || []}
          scroll={{ y: 400 }}
          locale={{ emptyText: 'No Permissions Found' }}
        />
      ),
    },
    {
      key: 'report_permission',
      label: 'Report Permissions',
      children: (
        <Table
          pagination={false}
          columns={reportPageColData}
          dataSource={permissionTableData?.report_page_permissions || []}
          scroll={{ y: 400 }}
          locale={{ emptyText: 'No Permissions Found' }}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs items={tabs} />
    </>
  );
};

export default PermissionMatrix;
