export interface ActionsPossible {
  has_bulk_actions: boolean;
  show_tracker_drawer: boolean;
  has_actions: boolean;
  show_preview: boolean;
  quick_view: boolean;
  display_name?: string;
  template_types?: Array<{ value: string; label: string }>;
  has_custom_field?: boolean;
  has_record_loader?: boolean;
  pretty_display_col?: string;
}

export const FIELD_TYPE_STRING = 'String';
export const FIELD_TYPE_INTEGER = 'Integer';
export const FIELD_TYPE_FLOAT = 'Float';
export const FIELD_TYPE_BOOLEAN = 'Boolean';
export const FIELD_TYPE_DATETIME = 'DateTime';
export const FIELD_TYPE_DATE = 'Date';
export const FIELD_TYPE_DROPDOWN = 'Dropdown';
export const FIELD_TYPE_SEARCH = 'Search';
export const FIELD_TYPE_LINK = 'Link';
export const FIELD_TYPE_ATTACHMENT = 'Attachment';
export const FIELD_TYPE_MULTIATTACHMENT = 'MultiAttachment';
export const FIELD_TYPE_MULTISEARCH = 'MultiSearch';
export const FIELD_TYPE_LIST = [
  FIELD_TYPE_STRING,
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_FLOAT,
  FIELD_TYPE_BOOLEAN,
  FIELD_TYPE_DATETIME,
  FIELD_TYPE_DATE,
  FIELD_TYPE_DROPDOWN,
  FIELD_TYPE_SEARCH,
  FIELD_TYPE_ATTACHMENT,
];
export const OBJECT_LIST_FOR_ALLOW_COLLABORATORS = [
  'Shipment::Shipment',
  'Network::Company',
  'SalesHub::Inquiry',
  'OrderManagement::ProductOrder',
];

const TERMS_AND_CONDITION = {
  value: 'terms_and_condition',
  label: 'Terms And Condition',
};
const PRINT = {
  value: 'print',
  label: 'Print',
};
const DOCUMENTATION_INSTRUCTIONS = {
  value: 'documentation_instructions',
  label: 'Documentation Instructions',
};
const REMARKS = {
  value: 'remarks',
  label: 'Remarks',
};
const DECLARATION = {
  value: 'declaration',
  label: 'Declaration',
};
const LETTER_HEAD = {
  value: 'letter_head',
  label: 'Letter Head',
};

export const DOC_TYPE_METHODS: { [key: string]: ActionsPossible } = {
  'Shipment::Shipment': {
    has_bulk_actions: true,
    show_tracker_drawer: true,
    has_actions: true,
    show_preview: false,
    display_name: 'Shipment',
    quick_view: true,
    has_custom_field: true,
  },
  'Shipment::ShipmentContainer': {
    show_tracker_drawer: true,
    has_bulk_actions: true,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    display_name: 'Shipment Container',
    has_custom_field: true,
  },
  'Shipment::ShipmentContainerDuplicate': {
    show_tracker_drawer: true,
    has_bulk_actions: true,
    has_actions: true,
    show_preview: false,
    quick_view: false,
  },
  'Shipment::ShipmentCustomDetail': {
    show_tracker_drawer: true,
    has_bulk_actions: true,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    display_name: 'Shipment Custom Detail',
    has_custom_field: true,
  },
  'Shipment::OceanTransportOrder': {
    show_tracker_drawer: false,
    has_bulk_actions: true,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Ocean Transport Order',
    template_types: [TERMS_AND_CONDITION],
  },
  'Shipment::BookingRequest': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
  },
  'Shipment::ShipmentDocument': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: true,
    quick_view: false,
  },
  'Shipment::StockOrderItem': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
  },
  'Email::Message': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
  },
  'Network::VoyageSchedule': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
  },
  'NewAccounting::FreightContract': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
  },
  'Network::Company': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: true,
    has_record_loader: true,
    has_custom_field: true,
    display_name: 'Company',
    pretty_display_col: 'registered_name',
  },
  'SalesHub::Inquiry': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Inquiry',
    has_custom_field: true,
    template_types: [
      {
        value: PRINT.value,
        label: PRINT.label,
      },
    ],
  },
  'SalesHub::InquiryGlobal': {
    has_bulk_actions: false,
    show_tracker_drawer: true,
    has_actions: true,
    show_preview: false,
    quick_view: true,
  },
  'SalesHub::InquiryOption': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Quotation',
    has_custom_field: true,
    template_types: [TERMS_AND_CONDITION],
  },
  'ReportManager::ViewSubscription': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
  },
  'Shipment::Vehicle': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    has_record_loader: true,
    quick_view: false,
    display_name: 'Vehicle',
  },
  'Network::Template': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
  },
  'Shipment::Driver': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    has_record_loader: true,
    quick_view: false,
    display_name: 'Driver',
  },
  'OrderManagement::ProductOrder': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    has_custom_field: true,
    display_name: 'Product Order',
  },
  'OrderManagement::ProductOrderGlobal': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
  },
  'OrderManagement::ProductOrderItem': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_custom_field: true,
    has_record_loader: true,
    display_name: 'Product Order Item',
  },
  'OrderManagement::SalesOrder': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Sales Order',
    template_types: [TERMS_AND_CONDITION, PRINT, DOCUMENTATION_INSTRUCTIONS],
  },
  'OrderManagement::PurchaseOrder': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Purchase Order',
    template_types: [TERMS_AND_CONDITION, PRINT, DOCUMENTATION_INSTRUCTIONS],
  },
  'Document::DeliveryOrder': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Document Delivery Order',
    template_types: [TERMS_AND_CONDITION],
  },
  'Network::CompanyAccount': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Company Account',
    template_types: [LETTER_HEAD],
  },
  'Docgen::GeneratedDocuments': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Generated Documents',
    template_types: [LETTER_HEAD],
  },
  'Shipment::ShipmentInvoice': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    display_name: 'Commerical Invoice',
    has_custom_field: true,
    template_types: [TERMS_AND_CONDITION, PRINT, REMARKS, DECLARATION],
  },
  'Shipment::ShipmentManifest': {
    has_bulk_actions: false,
    show_tracker_drawer: true,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    display_name: 'Shipment Manifest',
  },
  'Network::SalesPerson': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Sales Person',
  },
  'Network::UserContact': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'User Contact',
    pretty_display_col: 'name',
  },
  'Blueprint::CustomField': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    display_name: 'Custom Fields',
  },
  'Network::UserAccount': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: false,
    display_name: 'User Account',
  },
  'Network::BranchAccount': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Branch Account',
  },
  'Global::OceanVessel': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Ocean Vessel',
  },
  'Global::Location': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Location',
  },
  'Global::Carrier': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Carrier',
  },
  'Network::BusinessVertical': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Business Vertical',
  },
  'Network::Address': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Address',
    pretty_display_col: 'print_address',
  },
  'ServiceManagement::Ticket::Ticket': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    display_name: 'Ticket',
    quick_view: true,
    has_custom_field: true,
  },
  'Wms::Product': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: true,
    has_custom_field: true,
    display_name: 'Warehouse Product',
  },
  'Wms::WarehouseTransaction': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    has_custom_field: false,
    display_name: 'Warehouse Transaction',
  },
  'Wms::WarehouseTransactionProduct': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    display_name: 'Warehouse Transaction Product',
    has_custom_field: true,
  },
  'Wms::IncomingShipment': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Incoming Shipment',
    has_custom_field: true,
  },
  'Wms::Receipt': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Receipt',
    has_custom_field: true,
  },
  'Wms::GoodsTransfer': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Goods Transfer',
    has_custom_field: true,
  },
  'Wms::OutgoingShipment': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Outgoing Shipment',
    has_custom_field: true,
  },
  'Wms::Delivery': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Delivery',
    has_custom_field: true,
  },
  'Wms::StockLedger': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    display_name: 'Stock Ledger',
    has_custom_field: true,
  },
};
