import React, { useEffect } from 'react';
import {
  Flex,
  Form,
  FormInstance,
  QuestionCircleOutlined,
  Select,
  Table,
  Tooltip,
  Typography,
} from '@shipmnts/pixel-hub';
import { startCase } from 'lodash';
import { LOAD_TYPE_OPTIONS, SERVICES } from 'src/components/common/constants';

const { Option } = Select;

const ServiceRuleTable = ({
  form,
  disabled = false,
}: {
  form: FormInstance;
  disabled?: boolean;
}) => {
  const freightType = Form.useWatch(['fields', 'freight_type'], form);
  const options: { [key: string]: any } = {
    freight_type: ['ocean', 'air', 'road'],
    trade_type: {
      ocean: ['export', 'import'],
      air: ['export', 'import'],
      road: ['export', 'import', 'cross_trade', 'domestic'],
    },
    shipment_type: ['general', 'warehouse'],
    load_type: LOAD_TYPE_OPTIONS,
    services: SERVICES.map((service) => {
      return {
        value: service.key,
        label: service.display_name,
      };
    }),
  };

  const columns = [
    { title: 'Field', dataIndex: 'field', key: 'field' },
    {
      title: 'Value',
      width: '70%',
      dataIndex: 'value',
      key: 'value',
      render: (value: string[], record: { field: any; key: string; mode?: any }) => {
        return (
          <Form.Item name={record.key} style={{ marginBottom: 0 }}>
            <Select mode={record.mode} placeholder={'ALL'} disabled={disabled} allowClear>
              {(['load_type', 'trade_type'].includes(record.key[1])
                ? Array.from(new Set(options[record.key[1]][freightType] || []))
                : options[record.key[1]]
              )?.map((item: any) => (
                <Option key={item} value={item.value ? item.value : item.toLowerCase()}>
                  {item?.label ? item.label : startCase(item)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    },
  ];

  const dataSource: any = [
    { key: ['fields', 'freight_type'], field: 'Freight Type' },
    { key: ['fields', 'trade_type'], field: 'Trade Type' },
    { key: ['fields', 'load_type'], field: 'Load Type' },
    { key: ['fields', 'shipment_type'], field: 'Shipment Type' },
    { key: ['fields', 'services'], field: 'Services', mode: 'multiple' },
  ];

  useEffect(() => {
    const currentFreightType = form.getFieldValue(['fields', 'freight_type']);

    const updateFieldValue = (fieldKey: 'load_type' | 'trade_type') => {
      const fieldValue = form.getFieldValue(['fields', fieldKey]);
      if (!fieldValue) return undefined;
      const validValues = (options[fieldKey][currentFreightType] || []).map((item: any) => {
        if (fieldKey === 'load_type') return item.value?.toLowerCase();
        return item.toLowerCase();
      });
      return validValues.includes(fieldValue.toLowerCase()) ? fieldValue : undefined;
    };

    const updatedFields = {
      load_type: updateFieldValue('load_type'),
      trade_type: updateFieldValue('trade_type'),
    };

    form.setFieldsValue({
      fields: updatedFields,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freightType]);

  return (
    <>
      <Flex gap={5}>
        <Typography>Rules</Typography>
        <Tooltip title="Select rules for which this service is applicable.">
          <QuestionCircleOutlined style={{ opacity: '50%' }} />
        </Tooltip>
      </Flex>
      <Table
        style={{ width: '100%', marginBottom: '24px' }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey="key"
        size="small"
      />
    </>
  );
};

export default ServiceRuleTable;
