import React, { useMemo } from 'react';
import { Link, useLocation } from 'wouter';
import { observer } from 'mobx-react-lite';
import {
  BellOutlined,
  EditOutlined,
  hasPermission,
  Layout,
  Menu,
  NodeIndexOutlined,
  UserOutlined,
} from '@shipmnts/pixel-hub';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import { PERMISSION_SYSTEM_ADMINISTRATION_LIST } from '../common/constants';

interface SiderBarCategory {
  label: string;
  key: string;
  children: SiderBarCategoryChildren[];
}

interface SiderBarCategoryChildren {
  icon: React.ReactNode;
  label: React.ReactNode;
  key: string;
}

const SideBar = () => {
  const sessionData: SessionDataType = sessionStore.getSessionData();
  const { 0: location } = useLocation();
  let showSubscription = false;
  if (
    sessionData.company_account.seats.length > 0 &&
    sessionData.company_account.seats.some(
      (seat) => seat.tenant_id === sessionData.company_account.id
    )
  )
    showSubscription = true;

  const selfProfileManagementItems: SiderBarCategory = useMemo(() => {
    return {
      label: 'MY PROFILE',
      key: 'self_profile_management',
      children: [
        {
          icon: <UserOutlined />,
          label: <Link to={`~/my-profile`}>Profile</Link>,
          key: `/my-profile`,
        },
      ],
    };
  }, []);
  const companyProfileItems: SiderBarCategory = useMemo(() => {
    return {
      label: 'MY COMPANY',
      key: 'company_profile_management',
      children: [],
    };
  }, []);
  const dataManagementItems: SiderBarCategory = useMemo(() => {
    return {
      label: 'DATA MANAGEMENT',
      key: 'data_management',
      children: [],
    };
  }, []);

  if (sessionData.user_level === 'admin') {
    companyProfileItems.children.push(
      {
        icon: <UserOutlined />,
        label: <Link to={'~/view/users'}>Users</Link>,
        key: '/view/users',
      },
      {
        icon: <UserOutlined />,
        label: <Link to={'~/view/roles'}>Roles</Link>,
        key: '/view/roles',
      }
    );

    if (showSubscription) {
      companyProfileItems.children.push({
        icon: <UserOutlined />,
        label: <Link to={'~/view/subscription'}>Subscription and Billing</Link>,
        key: '/view/subscription',
      });
    }

    dataManagementItems.children.push(
      {
        icon: <BellOutlined />,
        label: <Link to={'~/auto_email_templates'}>Notification Preferences</Link>,
        key: '/auto_email_templates',
      },
      {
        icon: <NodeIndexOutlined />,
        label: <Link to={'~/view/pipelines'}>Pipelines</Link>,
        key: '/view/pipelines',
      },
      {
        icon: <EditOutlined />,
        label: <Link to={'~/view/company_groups'}>Company Groups</Link>,
        key: '/view/company_groups',
      },
      {
        icon: <EditOutlined />,
        label: <Link to={'~/view/business_verticals'}>Business Verticals</Link>,
        key: '/view/business_verticals',
      }
    );

    hasPermission(sessionData.permissions ?? [], {
      name: PERMISSION_SYSTEM_ADMINISTRATION_LIST,
      docType: 'Blueprint::CustomField',
    }) &&
      dataManagementItems.children.push({
        icon: <EditOutlined />,
        label: <Link to={'~/view/custom_fields'}>Custom Fields</Link>,
        key: '/view/custom_fields',
      });
  }

  const siderNavItems: any = useMemo(
    () => [
      selfProfileManagementItems,
      companyProfileItems.children.length > 0 && { type: 'divider' },
      companyProfileItems.children.length > 0 && companyProfileItems,
      dataManagementItems.children.length > 0 && { type: 'divider' },
      dataManagementItems.children.length > 0 && dataManagementItems,
    ],
    [companyProfileItems, dataManagementItems, selfProfileManagementItems]
  );

  return (
    <Layout.Sider style={{ height: '100%', width: '100% !important' }}>
      <Menu
        style={{ height: '100%' }}
        mode="inline"
        items={siderNavItems}
        expandIcon={null}
        selectedKeys={[location]}
        openKeys={siderNavItems.map((navItem: any) => navItem.key)}
      />
    </Layout.Sider>
  );
};

export default observer(SideBar);
