import { gql } from '@apollo/client';

export const CREATE_PIPELINE = gql`
  mutation create_pipeline($pipeline: CreatePipelineInputType!) {
    create_pipeline(pipeline: $pipeline) {
      id
      name
    }
  }
`;

export const FETCH_PIPELINE = gql`
  query blueprint_pipeline($id: ID!) {
    blueprint_pipeline(id: $id) {
      id
      name
      doctype
      state_definitions {
        id
        name
        mapped_to_state_name
        form {
          id
          title
          form_fields {
            id
            label
            required
            description
            field {
              label
              id
            }
          }
        }
      }
      description
    }
  }
`;

export const UPDATE_PIPELINE = gql`
  mutation update_pipeline($pipeline: UpdatePipelineInputType!) {
    update_pipeline(pipeline: $pipeline) {
      id
      name
    }
  }
`;

export const FETCH_POSSIBLE_STATES = gql`
  query blueprint_pipeline_possible_states($object: String!) {
    blueprint_pipeline_possible_states(object: $object)
  }
`;

export const GET_FIELDS_FROM_DOC_TYPE = gql`
  query get_fields_from_doc_type($doc_type_id: String!) {
    get_fields_from_doc_type(doc_type_id: $doc_type_id, editable: true) {
      id
      db_column_name
      filterable
      width
      field_type
      label
      filter_options
      cell_renderer
      cell_editor
      type
      doc_type_id
      section
    }
  }
`;

export const CREATE_FORM = gql`
  mutation create_form($form: CreateFormInputType!) {
    create_form(form: $form) {
      id
    }
  }
`;
