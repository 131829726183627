import { gql } from '@apollo/client';

export const FETCH_ALL_BUSINESS_VERTICALS = gql`
  query fetch_business_verticals {
    fetch_business_verticals {
      id
      fields
      name
      code
      is_disabled
      created_at
      is_standard
    }
  }
`;

export const FETCH_BUSINESS_VERTICAL = gql`
  query fetch_business_vertical($id: ID!) {
    fetch_business_vertical(id: $id) {
      id
      name
      code
      fields
      is_disabled
      is_standard
      created_at
    }
  }
`;

export const UPDATE_BUSINESS_VERTICAL = gql`
  mutation update_business_vertical($id: ID!, $business_vertical: BusinessVerticalInputType!) {
    update_business_vertical(id: $id, business_vertical: $business_vertical) {
      id
    }
  }
`;

export const CREATE_BUSINESS_VERTICAL = gql`
  mutation create_business_vertical($business_vertical: BusinessVerticalInputType!) {
    create_business_vertical(business_vertical: $business_vertical) {
      id
    }
  }
`;
