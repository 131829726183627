import { ReportContext } from '@shipmnts/pixel-hub';
import React, { ReactNode } from 'react';
import { Route } from 'wouter';
import ActionRendererReport from './components/common/ActionRenderer';

type DocTypeMethods = {
  has_bulk_actions: boolean;
  show_tracker_drawer: boolean;
  has_actions: boolean;
  show_preview: boolean;
  display_name: string;
  quick_view: boolean;
  has_custom_field: boolean;
};

const DOC_TYPE_METHODS: {
  [key: string]: DocTypeMethods;
} = {
  'Blueprint::Pipeline::Pipeline': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    display_name: 'Pipeline',
    quick_view: true,
    has_custom_field: false,
  },
  'Blueprint::CustomField': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    display_name: 'Custom Fields',
    quick_view: true,
    has_custom_field: false,
  },
  'Network::CompanyGroup': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    display_name: 'Company Group',
    quick_view: true,
    has_custom_field: false,
  },
  'Network::Role': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    display_name: 'Role',
    quick_view: true,
    has_custom_field: false,
  },
  'Network::UserAccount': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    display_name: 'User Account',
    quick_view: true,
    has_custom_field: false,
  },
};

const WorkspaceRoute = (props: {
  path: string;
  doctype: string;
  children: any;
  LayoutComponent: any;

  LINK_MAP?: (
    doc_type_id: string,
    id: string,
    extra_fields?: { [key: string]: string }
  ) => string | undefined;
  extraProps?: {
    extraDocTypesForMethods?: Array<string>;
    extraLayoutComponents?: Record<string, ReactNode>;
    extraDependentComponents?: Record<string, ReactNode>;
  };
}) => {
  const { path, children, doctype, LayoutComponent, LINK_MAP, extraProps } = props;
  return (
    <Route path={path}>
      <ReportContext.Provider
        value={{
          doc_type_to_layout: {
            [doctype]: LayoutComponent,
            ...(extraProps?.extraLayoutComponents ?? {}),
          },
          DOC_TYPE_METHODS: {
            [doctype]: DOC_TYPE_METHODS[doctype],
            ...Object.fromEntries(
              (extraProps?.extraDocTypesForMethods ?? []).map((doctype) => [
                doctype,
                DOC_TYPE_METHODS?.[doctype],
              ])
            ),
          },
          dependent_component: {
            ActionRendererReport: ActionRendererReport,
            ...(extraProps?.extraDependentComponents ?? {}),
          },
          LINK_MAP: LINK_MAP,
        }}
      >
        {children}
      </ReportContext.Provider>
    </Route>
  );
};

export default WorkspaceRoute;
