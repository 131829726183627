/*
TODO:
- Focus issue on opening of select on stages
- Add validation for number of stages
*/

import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Drawer,
  EditOutlined,
  Form,
  InfoCircleOutlined,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  message,
} from '@shipmnts/pixel-hub';
import PipelineTable from 'src/components/Pipeline/PipelineTable';
import {
  CREATE_PIPELINE,
  FETCH_PIPELINE,
  FETCH_POSSIBLE_STATES,
  UPDATE_PIPELINE,
} from 'src/graphQL/pipeline';
import { useLazyQuery, useMutation } from '@apollo/client';
import LoadingSpinner from 'src/components/common/LoadingSpinner';
import { pick } from 'lodash';

const PipelineForm = (props: any) => {
  // Props
  const { visible, setVisible, id } = props;

  // Contexts and Refs
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();

  // States
  const [initialTableData, setInitialTableData] = useState([]);
  const [updateMode, setUpdateMode] = useState(false);
  const [possibleStatesOptions, setPossibleStatesOptions] = useState<
    | [
        {
          label: string;
          value: string;
        },
      ]
    | undefined
  >(undefined);

  // Queries
  const [fetchPipeline, { data: pipelineData, loading: loadingPipelines, error: errorFetch }] =
    useLazyQuery(FETCH_PIPELINE, {
      fetchPolicy: 'network-only',
    });
  const [fetchStates, { data: possibleStates, loading: loadingStates, error: errorStates }] =
    useLazyQuery(FETCH_POSSIBLE_STATES);
  const [createPipeline, { data, loading: loadingCreate, error }] = useMutation(CREATE_PIPELINE);
  const [updatePipeline, { data: updateData, loading: loadingUpdate, error: errorUpdate }] =
    useMutation(UPDATE_PIPELINE);

  // Functions and constants
  const onClose = () => {
    if (setVisible) setVisible(false);
  };
  const labelStyler = (label: string) => {
    return (
      <label
        style={{
          fontWeight: 'bold',
          color: label === 'Pipeline Configuration' ? 'var(--ant-color-primary)' : 'inherit',
        }}
      >
        {label}
      </label>
    );
  };
  const handleObjectChange = (value: string) => {
    fetchStates({
      variables: {
        object: value,
      },
    });
  };
  const FormItems = () => {
    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          const payload = {
            pipeline: {
              ...values,
              id: id,
              state_definitions: values.state_definitions.map((config: any) => {
                const form_fields = config.form?.form_fields.map((f: any) => ({
                  id: f.id,
                  label: f.label,
                  required: f.required,
                  field_id: f.field_id || f.field?.id,
                  description: f.description,
                }));
                return {
                  ...pick(config, 'name', 'mapped_to_state_name'),
                  form:
                    config.form?.id || form_fields?.length > 0
                      ? { id: config.form?.id, form_fields }
                      : undefined,
                  id:
                    typeof config?.key === 'string' && config.key.includes('new')
                      ? undefined
                      : config.key,
                };
              }),
            },
          };
          try {
            if (
              !payload.pipeline.state_definitions ||
              payload.pipeline.state_definitions.length === 0
            ) {
              message.error('Pipeline can not be saved without states');
            } else {
              await tableForm.validateFields();
              !updateMode
                ? createPipeline({
                    variables: payload,
                  })
                : updatePipeline({
                    variables: payload,
                  });
            }
          } catch (err) {
            message.error('Failed to save the pipeline');
          }
        }}
      >
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label={labelStyler('Pipeline Name')}
              name={'name'}
              rules={[{ required: true, message: 'Please enter Pipeline Name' }]}
            >
              <Input placeholder="Name of the pipeline" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={labelStyler('Link Object')}
              name={'doctype'}
              rules={[{ required: true, message: 'Please Select Object' }]}
            >
              <Select
                placeholder="Select Object"
                // disabled={updateMode}
                onChange={handleObjectChange}
                options={[
                  { label: 'Company', value: 'Network::Company' },
                  { label: 'Ticket', value: 'ServiceManagement::Ticket::Ticket' },
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={labelStyler('Pipeline Description')} name={'description'}>
          <Input placeholder="Add a description for this pipeline" />
        </Form.Item>
        <Form.Item
          label={
            <>
              {labelStyler('Pipeline Configuration')}
              <Tooltip title="Add stages to configure the pipeline">
                <InfoCircleOutlined
                  style={{ color: 'var(--ant-color-primary)', marginLeft: '5px' }}
                />
              </Tooltip>
            </>
          }
          name={'state_definitions'}
        >
          {form.getFieldValue('doctype') && (
            <PipelineTable
              form={form}
              tableForm={tableForm}
              selectOptions={possibleStatesOptions}
              initialTableData={
                updateMode
                  ? initialTableData
                  : possibleStatesOptions?.map((state, index) => {
                      return {
                        key: `${Math.random() * 10000}-new`,
                        name: '',
                        mapped_to_state_name: state.value,
                      };
                    })
              }
              doctype={form.getFieldValue('doctype')}
            />
          )}
        </Form.Item>
      </Form>
    );
  };
  const title = !updateMode
    ? 'Create New Pipeline'
    : `Update Pipeline ${pipelineData?.blueprint_pipeline?.name}`;

  // Effects
  useEffect(() => {
    if (error) {
      message.error('Failed to create pipeline');
    } else if (data) {
      message.success('Pipeline created successfully');
      if (props?.refetchData) props.refetchData();
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loadingCreate, error]);
  useEffect(() => {
    if (errorUpdate) {
      message.error('Failed to update pipeline');
    } else if (updateData) {
      message.success('Pipeline updated successfully');
      onClose();
      if (props?.onClose) props.onClose();
      if (props?.onSuccess) props.onSuccess();
      if (props?.refetchData) props.refetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData, loadingUpdate, errorUpdate]);
  useEffect(() => {
    if (id) {
      setUpdateMode(true);
      fetchPipeline({
        variables: {
          id: id,
        },
      });

      if (errorFetch) {
        message.error('Failed to fetch pipeline');
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, errorFetch]);
  useEffect(() => {
    if (pipelineData) {
      form.setFieldsValue(pipelineData.blueprint_pipeline);
      fetchStates({
        variables: {
          object: pipelineData.blueprint_pipeline?.doctype,
        },
      });
      setInitialTableData(
        pipelineData?.blueprint_pipeline?.state_definitions?.map((config: any) => {
          return {
            ...config,
            key: config.id,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineData, loadingPipelines, errorFetch, updateMode]);
  useEffect(() => {
    if (possibleStates) {
      setPossibleStatesOptions(
        possibleStates?.blueprint_pipeline_possible_states?.map((state: string) => ({
          label: state,
          value: state,
        }))
      );
    }
  }, [possibleStates, loadingStates, errorStates]);

  return (
    <Drawer
      title={
        <>
          <EditOutlined /> {title}
        </>
      }
      onClose={onClose}
      open={visible}
      width={'725px'}
      footer={
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button key="cancel_pipeline" onClick={onClose} disabled={loadingCreate || loadingUpdate}>
            Cancel
          </Button>

          <Button
            style={{ marginLeft: '3px' }}
            type="primary"
            key="create_pipeline"
            onClick={() => {
              form.submit();
            }}
            loading={loadingCreate || loadingUpdate}
            disabled={loadingCreate || loadingUpdate}
          >
            {!updateMode ? <>Create Pipeline</> : <>Update Pipeline</>}
          </Button>
        </Space>
      }
      closeIcon={true}
    >
      {loadingPipelines || loadingStates ? (
        <LoadingSpinner loadingMessage="Fetching Pipeline ..." />
      ) : (
        <FormItems />
      )}
    </Drawer>
  );
};

export default PipelineForm;
