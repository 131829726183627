import { SessionDataType } from 'src/stores/SessionStore';
import React from 'react';
import { PRIMARY_TYPE } from '../common/constants';
import { CopyOutlined, CustomIcon } from '@shipmnts/pixel-hub';
import { Action, PerformAction } from '../common/commonTypeDefs';
import { GetAssignedUsers } from './RoleActions';

export const getRoleActions: (
  role: any,
  navigate: any,
  refetchData?: () => void | undefined,
  sessionData?: SessionDataType
) => Action[] = (role, navigate, refetchData?, sessionData?) => {
  const actions: Action[] = [];
  actions.push({
    key: 'duplicate_role',
    type: PRIMARY_TYPE,
    displayComponent: 'Duplicate',
    icon: <CopyOutlined height="15" width="15" />,
    description: 'duplicate_role',
    isEnable: true,
    onClick: () => {
      navigate('~/form/role/new', { state: { role, role_id: role?.id } });
    },
  });
  actions.push({
    key: 'edit_role',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit',
    icon: <CustomIcon height="15" icon="Edit" width="15" />,
    description: 'edit_role',
    isEnable: true,
    onClick: () => {
      navigate('~/form/role/' + role?.id);
    },
  });
  actions.push({
    key: 'view_assigned_users',
    type: PRIMARY_TYPE,
    displayComponent: 'View assigned users',
    icon: <CustomIcon height="15" icon="User" width="15" />,
    description: 'view_assigned_users',
    isEnable: true,
    performAction: viewAssignedUsersRenderer,
  });
  return actions;
};

const viewAssignedUsersRenderer = (payload: any): PerformAction => {
  const { role } = payload;

  return {
    actionParams: {
      id: role?.id,
      defaultOpen: true,
    },
    component: GetAssignedUsers,
  };
};
