import React, { ReactNode, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_PROFILE } from '../graphQL/user_profile';
import { axiosInstance, setAxiosDefaults, setErpNextAxiosDefaults } from './axiosDefaults';
import LoadingSpinner from 'src/components/common/LoadingSpinner';
import { Button, ConfigProvider, enUS, Result } from '@shipmnts/pixel-hub';
import { Link, useParams } from 'wouter';
import { useApplicationContentContext } from './ApplicationContext';
import { defaultValidateMessages } from './antFormMessages';
import '@shipmnts/pixel-hub/dist/index.css';
import { useAuth } from '@workos-inc/authkit-react';
import { sessionKey } from 'src/components/common/constants';

const App = (props: { store: any; children: ReactNode; logoutUser: () => void }): JSX.Element => {
  const { store, children, logoutUser } = props;
  const { getAccessToken } = useAuth();
  const [getUserProfile, { error, data, refetch }] = useLazyQuery(GET_USER_PROFILE);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!error && data) {
      store && store.setSessionData(data?.user_profile);
      localStorage.setItem(sessionKey, JSON.stringify(data?.user_profile));

      setAxiosDefaults(getAccessToken, logoutUser);
      setErpNextAxiosDefaults(
        getAccessToken,
        logoutUser,
        data?.user_profile?.company_account?.subdomain
      );
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, data, error, loading]);

  if (error) {
    return (
      <Result
        status="500"
        title="Unable to fetch company information"
        subTitle="Sorry, something went wrong when fetching your basic details. Either the servers have caught cold or there is some problem with your internet."
        extra={[
          <Button key="retry" type="primary" onClick={() => refetch()}>
            Retry
          </Button>,
          <Button key="logout" type="primary" onClick={() => logoutUser()}>
            Logout
          </Button>,
        ]}
      />
    );
  }

  if (loading && !store?.getSessionData()?.id) {
    return <LoadingSpinner loadingMessage="Loading..." />;
  }

  return (
    <ConfigProvider
      utils={{
        axios: axiosInstance,
        hooks: {
          useSession: () => store?.getSessionData(),
          Link: Link,
          useApplicationContentContext: useApplicationContentContext,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          useHistory: () => null,
          useParams: useParams,
        },
        ENV: {
          FIREBASE_APPLICATION_CONFIG: process.env.FIREBASE_APPLICATION_CONFIG,
          NETWORK_API_URL: process.env.REACT_APP_NETWORK_API_URL,
          GLOBAL_API_URL: process.env.REACT_APP_GLOBAL_API_URL,
          REPORT_MANAGER_API_URL: process.env.REACT_APP_REPORT_MANAGER_API_URL,
          SHIPMNTS_WEB_URL: process.env.REACT_APP_SHIPMNTS_WEB_URL,
        },
      }}
      ui={{
        locale: enUS,
        form: { validateMessages: defaultValidateMessages },
        theme: {
          cssVar: { key: 'app' },
          token: {
            colorPrimary: '#141414',
            colorLink: '#5e1b0c',
            colorSuccess: '#29845a',
            colorTextBase: 'var(--text-primary-gray-1)',
            colorTextDescription: 'var(--text-secondary-gray-2)',
            colorSuccessText: '#0c5132',
            colorWarning: '#e86427',
            colorError: '#d91f11',
            colorBgContainer: '#fff',
            colorBgLayout: '#fff',
            fontFamily: 'Inter',
            colorText: 'var(--text-primary-gray-1)',
            controlItemBgActive: 'var(--bg-secondary-gray-4)',
            colorLinkHover: 'var(--brand-burnt-red)',
          },
          components: {
            Tooltip: {
              colorBgElevated: '#042051',
            },
            Checkbox: {
              colorTextDisabled: 'rgba(0, 0, 0, 0.75)',
            },
            Menu: {
              subMenuItemBg: '#f3f4f8',
              itemBg: '#f3f4f8',
              itemSelectedBg: '#fff',
              itemColor: '#141414',
              itemSelectedColor: '#141414',
              itemBorderRadius: 0,
              itemMarginBlock: 4,
              itemMarginInline: 0,
              itemPaddingInline: 0,
              itemHoverBg: '#FFFFFF',
              itemHeight: 40,
            },
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default App;
