import React, { useState } from 'react';
import PipelineForm from './PipelineForm';

export const EditActionDrawer = (props: any) => {
  const [visible, setVisible] = useState(false);
  return !visible ? (
    <span onClick={() => setVisible(true)}>Edit Pipeline</span>
  ) : (
    <PipelineForm
      visible={visible}
      setVisible={setVisible}
      {...props}
      onClose={() => setVisible(false)}
    />
  );
};
