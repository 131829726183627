import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_OVERVIEW = gql`
  query get_subscription_overview {
    get_subscription_overview
  }
`;
export const GET_TRANSACTIONS = gql`
  query get_subscription_transactions($filters: JSON) {
    get_subscription_transactions(filters: $filters)
  }
`;

export const BUY_SEAT = gql`
  mutation buy_seat($id: ID!, $seat_to_add: Int!) {
    buy_seat(id: $id, seat_to_add: $seat_to_add) {
      id
      plan_name
      billing_seats
    }
  }
`;

export const REDUCE_SEAT = gql`
  mutation reduce_seat($id: ID!, $seat_to_reduce: Int!) {
    reduce_seat(id: $id, seat_to_reduce: $seat_to_reduce) {
      id
      plan_name
      billing_seats
    }
  }
`;

export const UPDATE_BILLING_EMAILS = gql`
  mutation update_billing_emails($emails: String!) {
    update_billing_emails(emails: $emails)
  }
`;

export const NOTIFY_INTERNAL_TEAM = gql`
  mutation notify_internal_team($action: String!) {
    notify_internal_team(action: $action)
  }
`;
