import React, { useEffect, useState } from 'react';
import {
  AppHelmet,
  BaseTable,
  Button,
  Dropdown,
  message,
  MoreOutlined,
  Skeleton,
  Typography,
} from '@shipmnts/pixel-hub';
import BusinessVerticalForm from './BusinessVerticalForm';
import { useQuery } from '@apollo/client';
import { FETCH_ALL_BUSINESS_VERTICALS } from 'src/graphQL/business_vertical';
import { startCase as _startCase } from 'lodash';
import BusinessVerticalDrawer from './BusinessVerticalDrawer';
import BusinessVerticalDisable from './BusinessVerticalDisable';
export default function BusinessVerticalView(props: any) {
  const [businessVerticals, setBusinessVerticals] = useState<any[]>([]);
  const { data, loading, error, refetch } = useQuery(FETCH_ALL_BUSINESS_VERTICALS);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (data?.fetch_business_verticals) {
      setBusinessVerticals(
        (data?.fetch_business_verticals || []).map((bv: any) => ({
          ...bv,
          freight_type: _startCase(bv?.fields?.freight_type),
          trade_type: _startCase(bv?.fields?.trade_type),
          load_type: _startCase(bv?.fields?.load_type),
        }))
      );
    }
    if (error) {
      message.error(error.message);
    }
  }, [data, error]);

  const columnDefs: any[] = [
    {
      headerName: 'Name',
      field: 'name',
      colId: 'name',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Code',
      field: 'code',
      colId: 'code',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Is Standard',
      field: 'is_standard',
      colId: 'is_standard',
      cellRenderer: (params: any) => {
        if (params.value) {
          return 'Yes';
        } else {
          return 'No';
        }
      },
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Status',
      field: 'is_disabled',
      colId: 'is_disabled',
      cellRenderer: (params: any) => {
        if (params.value) {
          return 'Disabled';
        } else {
          return 'Enabled';
        }
      },
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Freight Type',
      field: 'freight_type',
      colId: 'freight_type',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Trade Type',
      field: 'trade_type',
      colId: 'trade_type',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Load Type',
      field: 'load_type',
      colId: 'load_type',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      colId: 'created_at',
      field_type: 'DateTime',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: (params: any) => {
        return (
          <>
            {new Date(params.value * 1000).toLocaleTimeString('en-US', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </>
        );
      },
      minWidth: 180,
    },
    {
      headerName: 'Actions',
      lockPosition: 'right',
      suppressMovable: true,
      colId: 'actions',
      field: 'actions',
      valueGetter: 'data',
      width: 100,
      pinned: 'right',
      headerComponent: 'static_header_component',
      suppressNavigable: true,
      cellRenderer: (params: any) => {
        const menuItems = [];
        if (!params.data.is_standard && !params.data.is_disabled) {
          menuItems.push({
            key: '2',
            label: (
              <BusinessVerticalForm onClose={() => refetch()} businessVerticalId={params.data.id} />
            ),
          });
        }
        menuItems.push({
          key: '3',
          label: (
            <BusinessVerticalDisable
              onClose={() => refetch()}
              businessVerticalId={params.data.id}
            />
          ),
        });
        return (
          <Dropdown
            menu={{
              style: { minWidth: '200px' },
              className: 'actions-menu',
              items: menuItems,
            }}
            trigger={['click']}
          >
            <Button size="small">
              <MoreOutlined rotate={90} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  if (loading && !businessVerticals) {
    return <Skeleton />;
  }

  return (
    <div style={{ padding: '0 0 15px 15px' }}>
      <AppHelmet>
        <title>Business Verticals</title>
      </AppHelmet>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <div>
          <Typography.Text style={{ fontSize: '18px', color: 'var(--ant-color-primary)' }}>
            Business Verticals
          </Typography.Text>
        </div>
        <Button type="primary" onClick={() => setVisible(true)}>
          Create Business Vertical
        </Button>
      </div>
      <div style={{ marginTop: '5px', width: '100%' }}>
        <BaseTable
          reportName="auto_email_subcription_list_3"
          columns={columnDefs}
          rowData={businessVerticals || []}
          reportConfig={{
            // components: components,
            rowHeight: 40,
          }}
          height={'70vh'}
        />
      </div>
      {visible && (
        <BusinessVerticalDrawer
          onClose={() => {
            setVisible(false);
            refetch();
          }}
        />
      )}
    </div>
  );
}
