import { Layout, PageHeader, Tabs, TabsProps, Typography } from '@shipmnts/pixel-hub';
import React, { useMemo } from 'react';
import SubscriptionTransactions from 'src/components/Subscription/Transactions';
import SubscriptionOverview from 'src/components/Subscription/Overview';

const SubscriptionAndBilling = () => {
  const tabs: TabsProps['items'] = useMemo(
    () => [
      {
        key: 'overview',
        label: 'Overview',
        children: <SubscriptionOverview />,
      },
      {
        key: 'transactions',
        label: 'Transactions',
        children: <SubscriptionTransactions />,
      },
    ],
    []
  );

  return (
    <Layout className="subs-and-billing-layout">
      <PageHeader>
        <Typography.Title style={{ color: '#36414C', marginBottom: 0 }} level={4}>
          Subscription & Billing
        </Typography.Title>
      </PageHeader>
      <Tabs defaultActiveKey={'overview'} animated={true} items={tabs} destroyInactiveTabPane />
    </Layout>
  );
};

export default SubscriptionAndBilling;
