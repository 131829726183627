import React, { useEffect, useState } from 'react';
import { Layout, GlobalViewLayout, AppHelmet, Button, Flex } from '@shipmnts/pixel-hub';
import { getActionColDef } from 'src/components/common/ActionRenderer';
import SummaryCard from 'src/components/User/SummaryCard';
import { Link } from 'wouter';
import { DEPARTMENTS } from 'src/components/common/constants';
import { GET_ACTIVE_USER_SUMMARY } from 'src/graphQL/user';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import { useQuery } from '@apollo/client';
import { BuySeatButton } from 'src/components/Subscription/Overview';

const { Content } = Layout;

const Users = () => {
  const title = 'Users';
  const sessionData: SessionDataType = sessionStore.getSessionData();
  const createUserAllowed: boolean = sessionStore.canCreateActivateUser();
  const showBuySeatsBtn =
    sessionData.company_account.seats.length > 0 &&
    sessionData.company_account.seats.some(
      (seat) => seat.tenant_id === sessionData.company_account.id
    );
  const { data, refetch: refetchSummary } = useQuery(GET_ACTIVE_USER_SUMMARY, {
    variables: {
      id: sessionData.tenant_id,
    },
  });

  const [globalView, setGlobalView] = useState<any>(null);

  useEffect(() => {
    updateGlobalView();
  }, []);

  const updateGlobalView = () => {
    const globalView: any = {
      id: '1',
      name: 'UserAccount',
      color: '#eeeeee',
      doc_type_id: 'Network::UserAccount',
      type: 'grid',
      definition: {
        sort: [
          {
            field_id: 'user_accounts_created_at',
            direction: 'desc',
          },
        ],
        fields: [
          {
            id: 'user_accounts_first_name',
            label: 'Name',
            field_type: 'Link',
            width: 200,
            section: 'Basic',
            sortable: true,
            searchable: true,
            filterable: true,
            cell_renderer: {
              args: {
                id_field: 'id',
                doc_type_id: 'Network::UserAccount',
              },
              function: 'link_render',
            },
          },
          {
            id: 'roles_id',
            label: 'Role',
            field_type: 'Search',
            width: 220,
            section: 'Basic',
            filterable: true,
            groupable: true,
            use_record_loader: true,
            record_loader_config: { doc_type: 'Network::Role', attribute: 'view_display' },
            cell_renderer: {
              args: {
                id_field: 'id',
                value_field: 'role_name',
                doc_type_id: 'Network::Role',
              },
              function: 'link_render',
            },
            filter_options: JSON.stringify({
              doc_type: 'Network::Role',
              attribute: 'id',
            }),
          },
          {
            id: 'user_accounts_email',
            label: 'Email',
            field_type: 'String',
            width: 250,
            section: 'Basic',
            sortable: true,
            searchable: true,
            filterable: true,
          },
          {
            id: 'user_accounts_user_level',
            label: 'Type of User',
            field_type: 'Dropdown',
            width: 100,
            section: 'Basic',
            filterable: true,
            groupable: true,
            filter_options: JSON.stringify({ values: ['admin', 'user'] }),
          },
          {
            id: 'user_accounts_mobile_number',
            label: 'Contact #',
            field_type: 'String',
            width: 150,
            section: 'Basic',
            filterable: true,
          },
          {
            //todo: make this multi search and pixel hub search
            id: 'user_accounts_department',
            label: 'Departments',
            field_type: 'MultiSearch',
            width: 250,
            section: 'Basic',
            filterable: true,
            cell_renderer: {
              args: {},
              function: 'tags_render',
            },
            filter_options: JSON.stringify({
              values: DEPARTMENTS,
              display_hash: {},
              options: DEPARTMENTS.map((d) => ({ value: d, label: d })),
            }),
          },
          // {
          //   id: 'branch_accounts_name',
          //   label: 'Branch',
          //   width: 200,
          //   field_type: 'String',
          //   section: 'Basic',
          //   doc_type_id: 'Network::BranchAccount',
          //   filterable: true,
          //   groupable: true,
          // },
          {
            id: 'user_accounts_disabled',
            label: 'Disabled',
            width: 100,
            field_type: 'Boolean',
            section: 'Basic',
            filterable: true,
            groupable: true,
          },
          {
            id: 'user_accounts_created_at',
            label: 'Created At',
            field_type: 'DateTime',
            width: 200,
            section: 'Basic',
            sortable: true,
            filterable: true,
          },
          {
            id: 'user_accounts_created_by_id',
            label: 'Created By',
            width: 100,
            field_type: 'Search',
            section: 'Basic',
            filterable: true,
            groupable: true,
            use_record_loader: true,
            record_loader_config: { doc_type: 'Network::UserAccount', attribute: 'name' },
            cell_renderer: { args: {}, function: 'record_loader_render' },
            searchable: true,
            filter_options: JSON.stringify({
              doc_type: 'Network::UserAccount',
              attribute: 'name',
            }),
          },
          {
            id: 'user_accounts_updated_at',
            label: 'Updated At',
            width: 200,
            section: 'Basic',
            field_type: 'DateTime',
            sortable: true,
            filterable: true,
          },
          {
            id: 'user_accounts_updated_by_id',
            label: 'Updated By',
            width: 100,
            field_type: 'Search',
            section: 'Basic',
            filterable: true,
            groupable: true,
            use_record_loader: true,
            record_loader_config: { doc_type: 'Network::UserAccount', attribute: 'name' },
            cell_renderer: { args: {}, function: 'record_loader_render' },
            searchable: true,
            filter_options: JSON.stringify({
              doc_type: 'Network::UserAccount',
              attribute: 'name',
            }),
          },
        ],
        filters: [
          {
            field_id: 'user_accounts_disabled',
            condition: { type: 'equals', value: false },
          },
        ],
      },
      standard_report: true,
      is_disabled: false,
      global: true,
    };
    setGlobalView(globalView);
  };

  const updateDefaultFilter = (newFilter: any) => {
    setGlobalView((prev: any) => {
      const newGlobalView = { ...prev };
      const index = newGlobalView.definition.filters.findIndex(
        (filter: any) => filter.field_id === newFilter.field_id
      );
      if (index >= 0) {
        newGlobalView.definition.filters.map((filter: any) => {
          if (filter.field_id === newFilter.field_id) {
            filter.condition = newFilter.condition;
          }
          return filter;
        });
      } else {
        newGlobalView.definition.filters.push(newFilter);
      }
      return newGlobalView;
    });
  };

  const handleRefetch = () => {
    refetchSummary();
    updateGlobalView();
  };

  return (
    <Layout>
      <AppHelmet>
        <title>{title}</title>
      </AppHelmet>
      <Flex align="flex-end" gap={10} style={{ margin: '6px' }}>
        <SummaryCard updateDefaultFilter={updateDefaultFilter} data={data} />
        <Button
          type="primary"
          disabled={!createUserAllowed}
          title={!createUserAllowed ? 'Buy seats to enable it' : 'Create a new user in the system'}
        >
          <Link to={'/form/user/new'}>Create User</Link>
        </Button>
        {!createUserAllowed && showBuySeatsBtn && (
          <Link to="/view/subscription" state={{ with_buy_form_open: true }}>
            <BuySeatButton />
          </Link>
        )}
      </Flex>
      <Content>
        {globalView && (
          <GlobalViewLayout
            key={JSON.stringify(globalView?.definition.filters)}
            defaultFilters={[]}
            doc_type_id={'Network::UserAccount'}
            globalView={globalView}
            height="70vh"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore //todo: fix type for extraColDefs
            extraColDefs={[getActionColDef('Network::UserAccount', handleRefetch)]}
            label="Users"
          />
        )}
      </Content>
    </Layout>
  );
};

export default Users;
