import React from 'react';
import { Spin } from '@shipmnts/pixel-hub';

type LoadingSpinnerProps = {
  loadingMessage: string;
  isFullPage?: boolean;
};

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { loadingMessage, isFullPage = true } = props;

  return (
    <Spin style={{ height: isFullPage ? '100vh' : '100%' }} tip={loadingMessage}>
      <></>
    </Spin>
  );
};

export default LoadingSpinner;
