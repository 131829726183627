import { Avatar, CustomIcon, Flex, List } from '@shipmnts/pixel-hub';
import React from 'react';

interface UserListProps {
  users: any[];
}

const UserList = ({ users }: UserListProps) => {
  return (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={users}
      renderItem={(user: any) => (
        <List.Item style={{ cursor: 'pointer' }}>
          <Flex gap={10}>
            <Avatar
              shape="circle"
              size="large"
              style={{ background: 'var(--secondary-bg-color)', color: '#6C95DF' }}
            >
              <b>{`${user?.first_name[0]} ${user?.last_name?.[0]}`}</b>
            </Avatar>
            <div>
              <a href={`/view/user/${user?.id}`} target="_blank" rel="noopener noreferrer">
                <Flex gap={10} align="center" style={{ fontWeight: 'normal' }}>
                  <span style={{ color: 'var(--ant-color-primary)', fontWeight: '600' }}>
                    {`${user?.first_name} ${user?.last_name ?? ''}`}
                  </span>
                  <span>
                    <CustomIcon icon="ExternalLinkIcon" />
                  </span>
                </Flex>
              </a>
              <div>{user.email}</div>
            </div>
          </Flex>
        </List.Item>
      )}
    />
  );
};

export default UserList;
