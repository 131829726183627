import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  LoadingOutlined,
  Space,
  Spin,
  Typography,
  message,
} from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
import { RESET_PASSWORD } from 'src/graphQL/user';
import { getGeneratedPassword } from 'src/helpers/helpers';

interface ResetPasswordFormProps {
  user_id: string;
  onSuccess?: () => void;
}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const { user_id, onSuccess } = props;
  const [resetPassword, { loading, data }] = useMutation(RESET_PASSWORD);
  const [generateRandomPasswordLoading, setGenerateRandomPasswordLoading] = React.useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      if (data.reset_password) {
        form.resetFields();
        message.success('Password Updated Successfully');
        onSuccess && onSuccess();
      } else {
        message.error('Failed to update password.. Please try again.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const generateRandomPassword = async () => {
    setGenerateRandomPasswordLoading(true);
    try {
      const { data } = await getGeneratedPassword();
      form.setFieldsValue({
        new_password: data?.password,
        confirm_password: data?.password,
      });
    } catch (error) {
      message.error('Error generating password, please try again');
    }
    setGenerateRandomPasswordLoading(false);
    setIsPasswordVisible(true);
  };

  return (
    <Form
      form={form}
      onFinish={(values: any) => {
        resetPassword({
          variables: {
            id: user_id,
            new_password: values.new_password,
          },
        });
      }}
      labelAlign="left"
      colon={false}
      scrollToFirstError={true}
      layout="vertical"
    >
      <Form.Item
        label={
          <Space size={'small'}>
            Password
            {generateRandomPasswordLoading ? (
              <Spin indicator={<LoadingOutlined spin />} />
            ) : (
              <Typography.Link onClick={() => generateRandomPassword()}>
                Click here to generate password
              </Typography.Link>
            )}
          </Space>
        }
        name={'new_password'}
        rules={[{ required: true, message: 'Please Enter New Password' }]}
      >
        <Input.Password
          placeholder="Enter New Password"
          minLength={8}
          visibilityToggle={{
            visible: isPasswordVisible,
            onVisibleChange: (visible) => {
              setIsPasswordVisible(visible);
            },
          }}
        />
      </Form.Item>
      <Form.Item
        label={'Confirm Password'}
        name={'confirm_password'}
        dependencies={['new_password']}
        rules={[
          { required: true, message: 'Please Enter Confirm Password' },
          {
            validator: (rule, value, callback) => {
              if (value && value !== form.getFieldValue('new_password')) {
                callback('Passwords do not match!');
              } else {
                callback();
              }
            },
          },
        ]}
      >
        <Input.Password placeholder="Enter Confirm Password" />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Update Password
      </Button>
    </Form>
  );
};

export default ResetPasswordForm;
