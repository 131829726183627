import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ROLE } from 'src/graphQL/role';
import PermissionMatrix from '../Role/TransactionPermissionMatrix';
import LoadingSpinner from '../common/LoadingSpinner';
import { getAllCombinedPermissionsFromRoleObject } from 'src/helpers/helpers';
import ErrorPage from '../common/ErrorPage';

interface UserRoleDetailsProps {
  role_id: string;
}

const UserRoleDetails = ({ role_id }: UserRoleDetailsProps) => {
  const { loading, error, data } = useQuery(GET_ROLE, {
    variables: {
      id: role_id,
    },
  });
  const [permissions, setPermissions] = useState<any>([]);

  useEffect(() => {
    setPermissions(getAllCombinedPermissionsFromRoleObject(data?.role));
  }, [data]);

  if (loading) {
    return <LoadingSpinner loadingMessage="Loading Role" />;
  }

  if (error) {
    return <ErrorPage title="Something went wrong!" />;
  }

  return <PermissionMatrix role_id={role_id} value={permissions} readonly={true} />;
};

export default UserRoleDetails;
