import { gql } from '@apollo/client';

export const UPSERT_COMPANY_GROUP = gql`
  mutation upsert_company_group($company_group: CompanyGroupInputType!) {
    upsert_company_group(company_group: $company_group) {
      id
      name
    }
  }
`;

export const DISABLE_COMPANY_GROUP = gql`
  mutation disable_company_group($company_group: CompanyGroupInputType!) {
    disable_company_group(company_group: $company_group) {
      id
      name
      is_disabled
    }
  }
`;

export const FETCH_COMPANY_GROUP = gql`
  query company_groups($id: ID!) {
    company_groups(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_COMPANY_GROUP = gql`
  mutation delete_company_group($company_group: CompanyGroupInputType!) {
    delete_company_group(company_group: $company_group) {
      id
      name
    }
  }
`;
