import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import App from './App';
import { getClient } from './configureAppolloClient';
import LoadingSpinner from 'src/components/common/LoadingSpinner';
import '../index.css';
import { observer } from 'mobx-react-lite';
import NavbarContextWrapper from 'src/components/Navbar/NavWrapperContext';
import ApplicationLayoutContent from './ApplicationContext';
import { useAuth, AuthKitProvider } from '@workos-inc/authkit-react';
import { logoutUser } from 'shipmnts_navigation';
import { sessionKey } from 'src/components/common/constants';
import { useLocation } from 'wouter';

const ApolloWrapper = (props: { store: any; children: ReactNode }): JSX.Element => {
  const { store, children } = props;
  const { user, getAccessToken, isLoading, signIn, signOut } = useAuth();
  const [apolloClient, setApolloClient] = useState<ApolloClient<any>>();

  const logOut = useCallback(() => {
    logoutUser({
      skipAuthSet: false,
      sessionKey: sessionKey,
      alexUrl: process.env.REACT_APP_ALEX_URL,
      onSuccess: signOut,
    });
  }, [signOut]);

  useEffect(() => {
    if (isLoading) return;
    if (!user) {
      signIn({
        state: {
          path: window.location.pathname,
          search: window.location.search,
        },
      });
    } else {
      const setClient = async () => {
        setApolloClient(await getClient(getAccessToken, logOut));
      };
      setClient();
    }
  }, [getAccessToken, isLoading, logOut, signIn, user]);

  if (apolloClient) {
    return (
      <ApolloProvider client={apolloClient}>
        <App store={store} logoutUser={logOut}>
          <ApplicationLayoutContent>
            <NavbarContextWrapper>{children}</NavbarContextWrapper>
          </ApplicationLayoutContent>
        </App>
      </ApolloProvider>
    );
  }
  return <LoadingSpinner loadingMessage="Loading..." />;
};

const AppWrapper = (props: { store: any; children: ReactNode }): JSX.Element => {
  const clientId = process.env.REACT_APP_WORKOS_CLIENT_ID || '';
  const devMode = process.env.REACT_APP_WORKOS_DEVMODE === 'true' || false;
  const apiHostname = 'auth.api.shipmnts.com';
  const { 1: navigate } = useLocation();
  return (
    <AuthKitProvider
      clientId={clientId}
      devMode={devMode}
      apiHostname={apiHostname}
      onRedirectCallback={(params: any) => {
        const { state } = params;
        // setTimeout just to wait for page to load in history stack after redirection from auth system
        setTimeout(() => {
          navigate(`~${state?.path}${state?.search}`, { replace: true });
        }, 1);
      }}
    >
      <iframe
        title="Session Iframe"
        id="session_iframe"
        src={process.env.REACT_APP_SESSION_SHIPMNTS_URL}
        style={{ display: 'none' }}
        loading="eager"
      />
      <ApolloWrapper {...props} />
    </AuthKitProvider>
  );
};

export default observer(AppWrapper);
