import React, { useRef, useState } from 'react';
import { AppHelmet, Button, Flex, GlobalViewLayout } from '@shipmnts/pixel-hub';
import CompanyGroupForm from './CompanyGroupForm';
import { getActionColDef } from 'src/components/common/ActionRenderer';

const getGlobalView = () => {
  const globalView: any = {
    id: '1',
    name: 'All Company Groups',
    color: '#F6F7F7',
    type: 'grid',
    doc_type_id: 'Network::CompanyGroup',
    definition: {
      sort: [],
      fields: [
        {
          id: 'company_groups_name',
          label: 'Company Group',
          width: 250,
          field_type: 'String',
          section: 'Basic',
          seachable: true,
          filterable: true,
        },
        {
          id: 'company_groups_is_standard',
          label: 'Is Standard',
          width: 100,
          field_type: 'Boolean',
          section: 'Basic',
          seachable: true,
          filterable: true,
        },
        {
          id: 'company_groups_is_disabled',
          label: 'Is Disabled',
          width: 100,
          field_type: 'Boolean',
          section: 'Basic',
          seachable: true,
          filterable: true,
        },
      ],
      filters: [],
    },
    standard_report: true,
    is_disabled: false,
    global: true,
  };
  return globalView;
};

const CompanyGroupList = () => {
  const [visible, setVisible] = useState(false);
  const globalView = useRef(getGlobalView());
  const title = 'Company Groups';

  return (
    <div>
      <AppHelmet>
        <title>{title}</title>
      </AppHelmet>
      <Flex justify="end" style={{ marginBottom: '5px' }}>
        <Button type="primary" onClick={() => setVisible(true)}>
          {' '}
          New Company Group{' '}
        </Button>
      </Flex>
      <GlobalViewLayout
        defaultFilters={[]}
        globalView={globalView.current}
        doc_type_id={'Network::CompanyGroup'}
        height="80vh"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore //todo: fix type for extraColDefs
        extraColDefs={[getActionColDef('Network::CompanyGroup')]}
      />
      {visible && <CompanyGroupForm visible={visible} setVisible={setVisible} />}
    </div>
  );
};

export default CompanyGroupList;
