import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  DeleteOutlined,
  MenuOutlined,
  Select,
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  FormInstance,
  message,
  Drawer,
  FormCreator,
  Space,
} from '@shipmnts/pixel-hub';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useQuery } from '@apollo/client';
import { GET_FIELDS_FROM_DOC_TYPE } from 'src/graphQL/pipeline';
import _ from 'lodash';
import { DOC_TYPE_METHODS } from '../CustomFields/constants';
import './pipeline.css';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  mapped_to_state_name: string;
  id?: string;
  form?: any;
}

interface EditableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  'data-row-key': string;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
  selectOptions:
    | [
        {
          label: string;
          value: string;
        },
      ]
    | undefined;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  selectOptions,
  ...restProps
}) => {
  // Refs and context
  const inputRef = useRef<any>(null);
  const selectRef = useRef<any>(null);
  const form = useContext<any>(EditableContext);

  // Constatns and Methods
  const save = async () => {
    const myvals = form.getFieldsValue();
    handleSave({ ...record, ...myvals[record.key] });
    try {
      await form?.validateFields();
      form?.setFieldsValue({ [dataIndex]: record[dataIndex] });
    } catch (errInfo) {
      message.error('Save failed');
    }
  };

  let childNode = children;
  if (editable) {
    childNode = (
      <Form.Item
        style={{ margin: 0 }}
        name={[record.key, dataIndex]}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
        initialValue={dataIndex ? record?.[dataIndex] : undefined}
      >
        {dataIndex === 'name' ? (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        ) : (
          <Select
            ref={selectRef}
            onSelect={save}
            onBlur={save}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={() => selectRef?.current?.focus()}
            options={selectOptions}
          />
        )}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  name: string;
  age: string;
  address: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface PipeTableProps {
  form: FormInstance;
  tableForm: FormInstance;
  onChange?: (data: any) => void;
  initialTableData?: any;
  selectOptions:
    | [
        {
          label: string;
          value: string;
        },
      ]
    | undefined;
  doctype: string;
}
const PipelineTable = (props: PipeTableProps) => {
  const {
    onChange,
    initialTableData,
    selectOptions = [
      { label: 'Open', value: 'Open' },
      { label: 'Close', value: 'Close' },
    ],
    doctype,
  } = props;

  // States
  const [dataSource, setDataSource] = useState(initialTableData || []);
  const [count, setCount] = useState(2);

  // Queries/Mutations
  const { data } = useQuery(GET_FIELDS_FROM_DOC_TYPE, {
    variables: {
      doc_type_id: doctype,
    },
  });

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item: any) => item.key !== key);
    setDataSource(newData);
    if (onChange) onChange(newData);
  };

  const handleCustomFieldsUpdate = useCallback(
    (key: React.Key, values: any) => {
      const newData = [...dataSource];
      const index = _.findIndex(newData, { key: key });
      const data = {
        ...dataSource[index],
        form: {
          id: dataSource[index]?.form?.id,
          form_fields: values.map((v: any) => ({ ...v, field_id: v.id, id: undefined })),
        },
      };
      newData.splice(index, 1, { ...data });
      setDataSource(newData);
      if (onChange) onChange(newData);
    },
    [dataSource, onChange]
  );

  const defaultColumns = [
    {
      key: 'sort',
      align: 'center',
      width: '5%',
    },
    {
      title: 'Pipeline Status Name',
      dataIndex: 'name',
      width: '50%',
      editable: true,
      align: 'center',
    },
    {
      title: 'Map Status',
      dataIndex: 'mapped_to_state_name',
      editable: true,
      selectOptions,
    },
    {
      title: 'Conditional Fields',
      dataIndex: 'form',
      render: (_: string, record: any) => {
        return (
          <CondionalPropsForm
            handleCustomFieldsUpdate={handleCustomFieldsUpdate}
            data={data}
            record={record}
            doctype={props.doctype}
            dataS={dataSource}
          />
        );
      },
    },

    {
      title: 'Action',
      render: (_: string, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DeleteOutlined style={{ color: '#E70000D9', cursor: 'pointer', fontSize: '17px' }} />
            </div>
          </Popconfirm>
        ) : null,
      align: 'center',
      width: '5%',
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: (Math.random() * 100000).toString() + 'new',
      name: ``,
      mapped_to_state_name: '',
    };

    setDataSource([...dataSource, newData]);
    setCount(count + 1);
    if (onChange) onChange([...dataSource, newData]);
  };

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    if (onChange) onChange(newData);
  };

  const EditableRow: React.FC<EditableRowProps> = ({ children, index, ...rowProps }) => {
    const [form] = Form.useForm();

    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: rowProps['data-row-key'],
    });

    const style: React.CSSProperties = {
      ...rowProps.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return (
      <Form
        component={false}
        form={props.tableForm}
        onValuesChange={async () => {
          try {
            await form.validateFields();
          } catch (err) {
            message.error('Please fill all the fields');
          }
        }}
        // onFinish={(values) => {}}
      >
        <EditableContext.Provider value={props.tableForm}>
          <tr {...rowProps} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
              if ((child as React.ReactElement).key === 'sort') {
                return React.cloneElement(child as React.ReactElement, {
                  children: (
                    <MenuOutlined
                      ref={setActivatorNodeRef}
                      style={{ touchAction: 'none', cursor: 'move' }}
                      {...listeners}
                    />
                  ),
                });
              }
              return child;
            })}
          </tr>
        </EditableContext.Provider>
      </Form>
    );
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        selectOptions: col.selectOptions,
      }),
    };
  });
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous: any) => {
        const activeIndex = previous.findIndex((i: any) => i.key === active.id);
        const overIndex = previous.findIndex((i: any) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    props.form.setFieldsValue({ state_definitions: dataSource });
  }, [dataSource, props.form]);

  return (
    <>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dataSource.map((i: any) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={components}
            rowClassName={'editable-row'}
            bordered
            dataSource={dataSource}
            columns={columns as ColumnTypes}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
      <Button type="primary" ghost onClick={handleAdd} style={{ marginTop: '12px' }}>
        + Add New Stage
      </Button>
    </>
  );
};

export default PipelineTable;

type Props = {
  data?: any;
  record: Item;
  doctype: string;
  handleCustomFieldsUpdate: (key: React.Key, values: any) => void;
  dataS?: any;
};

const CondionalPropsForm = React.memo(function CondionalPropsFormq(props: Props) {
  // States
  const [open, setopen] = useState(false);
  const [formValues, setFormValues] = useState<[any] | undefined>(
    props.record?.form?.form_fields || undefined
  );

  const allModelColumns = useMemo(() => {
    const models = [];
    if (props.data?.get_fields_from_doc_type) {
      models.push({
        id: props.doctype,
        label: DOC_TYPE_METHODS[props.doctype]?.display_name || props.doctype,
        fields: props.data?.get_fields_from_doc_type,
      });
    }
    return models;
  }, [props.data?.get_fields_from_doc_type, props.doctype]);

  //
  const onChange = (values: any) => {
    setFormValues(values);
  };
  const updatedData = props.dataS?.find((e: any) => e.name === props.record.name);

  return (
    <>
      <Button onClick={() => setopen(true)} style={{ minWidth: '150px' }}>
        {formValues?.length && formValues?.length > 0
          ? `Selected (${formValues?.length})`
          : 'Select Fields'}
      </Button>
      <Drawer
        title="Select Conditional Fields"
        size="large"
        open={open}
        onClose={() => setopen(false)}
        footer={
          <Space style={{ direction: 'rtl', width: '100%' }}>
            <Button
              type="primary"
              onClick={() => {
                props.handleCustomFieldsUpdate(props.record.key, formValues);
              }}
            >
              Set
            </Button>
            <Button onClick={() => setopen(false)}>Cancel</Button>
          </Space>
        }
      >
        <FormCreator
          form={{
            ...props.record.form,
            form_fields: Array.isArray(updatedData.form?.form_fields)
              ? updatedData.form?.form_fields?.map((fv: any) => ({
                  ...fv,
                  id: fv.id || fv.field_id || fv.field,
                  field: { ...fv.field },
                }))
              : props.record.form?.form_fields.map((fv: any) => ({
                  ...fv,
                  id: fv.id || fv.field_id || fv.field,
                  field: { ...fv.field },
                })),
          }}
          allModelColumns={allModelColumns}
          onChange={onChange}
        />
      </Drawer>
    </>
  );
});
