import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'wouter';
import { useQuery } from '@apollo/client';
import {
  Flex,
  Button,
  Tag,
  TabsProps,
  Tabs,
  message,
  DownOutlined,
  PageHeader,
  AppHelmet,
  CustomIcon,
  Row,
  Col,
} from '@shipmnts/pixel-hub';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { GET_USER } from 'src/graphQL/user';
import LoadingSpinner from '../common/LoadingSpinner';
import Overview from './Overview';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import { DisableUser } from './UserActions';
import { ActionRenderer } from '../common/ActionRendererNew';
import ErrorPage from '../common/ErrorPage';

dayjs.extend(customParseFormat);

const UserDetails = (props: { id?: string }) => {
  const sessionData: SessionDataType = sessionStore.getSessionData();
  const [disablePopUpOpen, toggleDisablePopUpOpen] = useState(false);

  const params = useParams();
  let { user_id } = params;
  user_id = props.id || user_id;
  if (!user_id) {
    user_id = sessionData.id;
  }
  // eslint-disable-next-line eqeqeq
  const is_self_profile = useMemo(() => user_id == sessionData.id, [user_id, sessionData.id]);
  const {
    refetch: refetchUser,
    loading,
    error,
    data: userData,
  } = useQuery(GET_USER, {
    variables: { id: user_id },
  });

  const tabs: TabsProps['items'] = useMemo(
    () => [
      {
        key: 'overview',
        label: 'Overview',
        children: <Overview userData={userData} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userData]
  );

  if (loading) {
    return <LoadingSpinner loadingMessage="Loading User Data" />;
  }

  if (error) {
    message.error('Error while loading user data!');
    return <ErrorPage title="Something went wrong!" />;
  }

  return (
    <>
      <AppHelmet>
        <title>{`Profile - ${userData?.user?.first_name} ${userData?.user?.last_name ?? ''}`}</title>
      </AppHelmet>
      <Flex vertical gap={12} style={{ padding: '1rem' }}>
        <PageHeader
          ghost={false}
          title={
            <Flex gap={10} align="center" style={{ fontWeight: 'normal' }}>
              <Tag type="warning" style={{ marginRight: 0, textTransform: 'uppercase' }}>
                {`${userData?.user?.first_name?.[0]}${userData?.user?.last_name?.[0] ?? ''}`}
              </Tag>
              <span style={{ color: 'var(--ant-color-primary)', fontWeight: '600' }}>
                {`${userData?.user?.first_name} ${userData?.user?.last_name ?? ''}`}
              </span>
              <span>
                {!is_self_profile && !params.user_id && (
                  <Link to={`~/view/user/${userData?.user?.id}`} target="_blank" rel="noreferrer">
                    <CustomIcon icon="ExternalLinkIcon" />
                  </Link>
                )}
              </span>
              <Tag type="success" style={{ textTransform: 'capitalize' }}>
                {userData?.user?.user_level}
              </Tag>
              <Tag
                type={userData?.user?.disabled ? 'critical' : 'success-secondary'}
                style={{ textTransform: 'capitalize' }}
              >
                {userData?.user?.disabled ? 'Disabled' : 'Active'}{' '}
              </Tag>
            </Flex>
          }
          extra={
            <Flex gap={10}>
              {sessionData.user_level === 'admin' && !is_self_profile && (
                <Button
                  className={userData?.user?.disabled && 'default-bordered-button'}
                  danger={!userData?.user?.disabled}
                  onClick={() => toggleDisablePopUpOpen(true)}
                  size="small"
                >
                  <DisableUser
                    user={{
                      id: userData?.user?.id,
                      disabled: userData?.user?.disabled,
                    }}
                    onSuccess={refetchUser}
                    open={disablePopUpOpen}
                    toggleOpen={toggleDisablePopUpOpen}
                  />
                  {userData?.user?.disabled ? 'Enable' : 'Disable'}
                </Button>
              )}
              <ActionRenderer
                id={userData?.user?.id}
                data={userData?.user}
                doc_type_id="Network::UserAccount"
                refetchData={refetchUser}
                forceDropDown={true}
              >
                <Button size="small" className="default-bordered-button">
                  Actions <DownOutlined />
                </Button>
              </ActionRenderer>
            </Flex>
          }
        >
          <Row>
            <Col span={8}>
              <Flex vertical gap="middle">
                <Flex vertical gap="small">
                  <span className="item-label">Business Email</span>
                  <span>{userData?.user?.email}</span>
                </Flex>
                <Flex vertical gap="small">
                  <span className="item-label">Mobile #</span>
                  <span>{userData?.user?.mobile_number}</span>
                </Flex>
              </Flex>
            </Col>
            <Col span={8}>
              <Flex vertical gap="small">
                <span className="item-label">Department</span>
                <div>
                  {userData?.user?.department.map((department: any) => (
                    <Tag type="info" style={{ margin: '0 3px 3px 0' }} key={department}>
                      {department}
                    </Tag>
                  ))}
                </div>
              </Flex>
            </Col>
            <Col span={8}>
              <Flex vertical gap="middle">
                <Flex vertical gap="small">
                  <span className="item-label">Created At</span>
                  <span>
                    {dayjs(userData?.user?.created_at).format('ddd, DD MMM YYYY, h:mm a')}
                  </span>
                </Flex>
                <Flex vertical gap="small">
                  <span className="item-label">Created By</span>
                  {userData?.user?.created_by ? (
                    <p>
                      By {userData?.user?.created_by?.first_name}{' '}
                      {userData?.user?.created_by?.last_name}{' '}
                    </p>
                  ) : (
                    '-'
                  )}
                </Flex>
              </Flex>
            </Col>
          </Row>
        </PageHeader>
        <Tabs defaultActiveKey="roles_and_permission" animated={true} items={tabs} />
      </Flex>
    </>
  );
};

export default UserDetails;
