import axios from 'axios';
import { Modal } from '@shipmnts/pixel-hub';
let modalShown = false;

export function showLogoutModal(e, logoutFunction) {
  if (e) console.error(e);
  if (modalShown) return;
  modalShown = true;
  Modal.error({
    title: 'Your session has expired. Please login again.',
    content: 'This happens usually when you have logged out from another tab/window.',
    centered: true,
    keyboard: false,
    onOk: logoutFunction,
    okText: 'Login again',
  });
}

export const axiosInstance = axios.create();

export function setAxiosDefaults(getTokenFunction, logoutFunction) {
  axiosInstance.defaults.timeout = 10000;
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
  axiosInstance.defaults.headers.common.Accept = 'application/json';

  axiosInstance.interceptors.request.use(async function (config) {
    let token;
    try {
      token = await getTokenFunction();
    } catch (e) {
      showLogoutModal(e, logoutFunction);
      throw e;
    }
    if (token) {
      // config.headers.common = config.headers.common || {}
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
  axiosInstance.interceptors.response.use(undefined, (error) => {
    if (error.response && error.response.status === 401) {
      showLogoutModal(error);
    }
    return Promise.reject(error);
  });
}

export const erpNextAxios = axios.create();

export const setErpNextAxiosDefaults = async (getTokenFunction, logoutFunction, subdomain) => {
  erpNextAxios.defaults.timeout = 10000;
  erpNextAxios.defaults.headers.common['Content-Type'] = 'application/json';
  erpNextAxios.defaults.headers.common.Accept = 'application/json';
  erpNextAxios.defaults.baseURL = subdomain;
  erpNextAxios.interceptors.request.use(async function (config) {
    let token;
    try {
      token = await getTokenFunction();
    } catch (e) {
      showLogoutModal(e, logoutFunction);
      throw e;
    }
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
  erpNextAxios.interceptors.response.use(undefined, (error) => {
    if (error.response && error.response.status === 401) {
      showLogoutModal(error, logoutFunction);
    }
    return Promise.reject(error);
  });
};
