import { AutoEmailTemplateForm } from '@shipmnts/pixel-hub';
import React, { useState } from 'react';

interface EditAutoEmailSubscriptionTemplateProps {
  viewSubscriptionTemplate: any;
  onClose?: () => void;
}
export default function EditAutoEmailSubscriptionTemplate(
  props: EditAutoEmailSubscriptionTemplateProps
) {
  const { viewSubscriptionTemplate, onClose } = props;
  const [visible, setVisible] = useState(false);
  return (
    <>
      <div onClick={() => setVisible(true)}>Edit Email Template</div>
      <AutoEmailTemplateForm
        isVisible={visible}
        setIsVisible={setVisible}
        viewSubscriptionTemplateId={viewSubscriptionTemplate?.id}
        onClose={() => {
          setVisible(false);
          onClose && onClose();
        }}
      />
    </>
  );
}
