import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  BaseTable,
  Button,
  Checkbox,
  Col,
  DatePicker,
  DateTypeRenderer,
  Flex,
  Form,
  Row,
  Select,
  Typography,
} from '@shipmnts/pixel-hub';
import { GET_TRANSACTIONS } from 'src/graphQL/subscription';
import LoadingSpinner from '../common/LoadingSpinner';
import ErrorPage from '../common/ErrorPage';

const { Link } = Typography;

const SubscriptionTransactions = () => {
  const [getTransactions, { data, error, loading }] = useLazyQuery(GET_TRANSACTIONS);
  const [transactions, setTransactions] = useState<any>([]);
  const [form] = Form.useForm();
  const staticRows = ['Opening', 'Closing', 'Total'];

  const convertUTCtoIST = (date: Date) => {
    if (date) {
      date.setHours(date.getHours() + 5);
      date.setMinutes(date.getMinutes() + 30);
    }
    return date;
  };

  const columnDefs: { [colId: string]: any } = {
    posting_date: {
      headerName: 'Transaction Date',
      field: 'posting_date',
      colId: 'posting_date',
      width: 140,
      cellRenderer: (props: any) => {
        const posting_date = props.value;
        if (posting_date) {
          const pd = posting_date.split('-');
          return `${pd[2]}-${pd[1]}-${pd[0]}`;
        }
        return '';
      },
    },
    voucher_type: {
      headerName: 'Transaction Type',
      field: 'voucher_type',
      colId: 'voucher_type',
      width: 140,
    },
    voucher_no: {
      headerName: 'Transaction #',
      field: 'voucher_no',
      colId: 'voucher_no',
      width: 180,
      cellRenderer: (props: any) => {
        if (!staticRows.includes(props.value)) {
          if (props.data?.voucher_public_link) {
            return (
              <Link
                href={props.data?.voucher_public_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.value}
              </Link>
            );
          }
          return <span>{props.value}</span>;
        } else return <b>{props.value}</b>;
      },
    },
    currency: {
      headerName: 'Txn Curr (TC)',
      field: 'currency',
      colId: 'currency',
      width: 110,
      hide: !form.getFieldValue('show_in_txn_currency'),
    },
    debit_in_fc: {
      headerName: 'Debit (TC)',
      field: 'debit_in_fc',
      colId: 'debit_in_fc',
      align: 'right',
      hide: !form.getFieldValue('show_in_txn_currency'),
      columnType: 'Float',
      cellRendererParams: {
        precision: 2,
      },
      width: 140,
      aggFunc: undefined,
    },
    credit_in_fc: {
      headerName: 'Credit (TC)',
      field: 'credit_in_fc',
      colId: 'credit_in_fc',
      align: 'right',
      hide: !form.getFieldValue('show_in_txn_currency'),
      columnType: 'Float',
      cellRendererParams: {
        precision: 2,
      },
      width: 140,
      aggFunc: undefined,
    },
    ex_rate: {
      headerName: 'Ex. Rate',
      field: 'ex_rate',
      colId: 'ex_rate',
      columnType: 'Float',
      cellRendererParams: {
        precision: 2,
      },
      hide: !form.getFieldValue('show_in_txn_currency'),
      width: 100,
      aggFunc: undefined,
    },
    base_debit: {
      headerName: 'Debit (INR)',
      field: 'base_debit',
      colId: 'base_debit',
      align: 'right',
      columnType: 'Float',
      cellRendererParams: {
        precision: 2,
      },
      width: 140,
      aggFunc: undefined,
    },
    base_credit: {
      headerName: 'Credit (INR)',
      field: 'base_credit',
      colId: 'base_credit',
      align: 'right',
      columnType: 'Float',
      cellRendererParams: {
        precision: 2,
      },
      width: 140,
      aggFunc: undefined,
    },
    balance: {
      headerName: 'Balance (INR)',
      field: 'balance',
      colId: 'balance',
      align: 'right',
      columnType: 'Float',
      cellRendererParams: {
        precision: 2,
      },
      width: 140,
      aggFunc: undefined,
    },
    reference_number: {
      headerName: 'Ref/Bill/Cheque #',
      field: 'reference_number',
      colId: 'reference_number',
      width: 180,
    },
    reference_date: {
      headerName: 'Ref/Bill/Cheque Date',
      field: 'reference_date',
      colId: 'reference_date',
      width: 160,
      cellRenderer: (props: any) => {
        const reference_date = props.value;
        if (reference_date) {
          const pd = reference_date.split('-');
          return `${pd[2]}-${pd[1]}-${pd[0]}`;
        }
        return '';
      },
    },
    remarks: {
      headerName: 'Remarks',
      field: 'remarks',
      colId: 'remarks',
    },
  };

  const getTransactionLedgerData = () => {
    const start_date = convertUTCtoIST(form.getFieldsValue().date_range?.[0].toDate());
    const end_date = convertUTCtoIST(form.getFieldsValue().date_range?.[1].toDate());

    const filters = {
      start_date: start_date,
      end_date: end_date,
      transaction_type: form.getFieldsValue().transaction_type,
    };

    getTransactions({
      variables: { filters },
    });
  };

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  useEffect(() => {
    if (data?.get_subscription_transactions) {
      setTransactions(data.get_subscription_transactions);
    }
  }, [data]);

  if (loading) return <LoadingSpinner loadingMessage="Loading transactions" />;
  if (error) return <ErrorPage title="Error while fetching transactions!" />;

  return (
    <Flex vertical style={{ marginTop: '1rem' }}>
      <Form form={form}>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="date_range" label="Date Range">
              <DatePicker.RangePicker picker="date" format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="transaction_type" label="Transaction Type">
              <Select
                allowClear
                options={[
                  { key: 'Sales Invoice', value: 'Sales Invoice', label: 'Sales Invoice' },
                  { key: 'Payment Entry', value: 'Payment Entry', label: 'Payment Entry' },
                  {
                    key: 'Forex Payment Entry',
                    value: 'Forex Payment Entry',
                    label: 'Forex Payment Entry',
                  },
                  { key: 'Journal Entry', value: 'Journal Entry', label: 'Journal Entry' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              name="show_in_txn_currency"
              label="Show in Transaction Currency"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Flex justify="space-evenly">
              <Button type="primary" onClick={getTransactionLedgerData}>
                Generate
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>

      <BaseTable
        reportName="transaction_ledger"
        columns={Object.values(columnDefs)}
        rowData={transactions?.data}
        reportConfig={{
          components: {
            date_type_renderer: DateTypeRenderer,
          },
          pinnedTopRowData: [{ ...transactions?.opening, hideRowIndex: true }],
          pinnedBottomRowData: [
            { ...transactions?.totals, hideRowIndex: true },
            { ...transactions?.closing, hideRowIndex: true },
          ],
        }}
        height="60vh"
        skipCacheColumns={['currency', 'debit_in_fc', 'credit_in_fc', 'ex_rate']}
      />
    </Flex>
  );
};

export default SubscriptionTransactions;
