import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { showLogoutModal } from './axiosDefaults';
import { message as Message } from '@shipmnts/pixel-hub';

const cache = new InMemoryCache();

const baseUrl = process.env.REACT_APP_GRAPHQL_API_URL;

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

let client = null;

async function _create(getToken, logoutUser, graphqlUrl) {
  try {
    let link = graphqlUrl || baseUrl;

    const authMiddleware = setContext(async (req, { headers }) => {
      const token = await getToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const httpLink = new BatchHttpLink({
      uri: link,
      credentials: 'same-origin',
      batchMax: 5,
      batchInterval: 30,
      batchDebounce: true,
    });

    const logoutLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          Message.error(message);
        });
      }
      if (networkError) console.error(`[Network error]: ${networkError}`);
      if (networkError?.statusCode === 401 && logoutUser) {
        showLogoutModal(networkError, logoutUser);
      }
    });

    return new ApolloClient({
      link: from([authMiddleware, logoutLink, httpLink]),
      cache,
      defaultOptions,
      connectToDevTools: process.env.REAL_ENV !== 'production',
    });
  } catch (e) {
    console.error(e);
  }
}

export async function getClient(getToken, logoutUser, graphqlUrl = '') {
  if (!client) client = await _create(getToken, logoutUser, graphqlUrl);
  return client;
}

let publicClient = null;

export const createPublicClient = () => {
  publicClient = new ApolloClient({
    uri: `${process.env.REACT_APP_ALEX_URL}/graphql_public`,
    cache,
  });
};

export const getPublicClient = () => {
  if (!publicClient) publicClient = createPublicClient();
  return publicClient;
};
