/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback, memo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Select, Spin, Button, Flex, Typography, Col } from '@shipmnts/pixel-hub';
import { pick as _pick } from 'lodash';
import { GET_ROLE, GET_ROLES } from 'src/graphQL/role';
import PermissionMatrix from '../Role/TransactionPermissionMatrix';
import { getAllCombinedPermissionsFromRoleObject } from 'src/helpers/helpers';

interface RoleValue {
  id: string;
  role_name: string;
}

const { Option } = Select;

export interface RoleSearchProps {
  value?: RoleValue | RoleValue[] | undefined;
  onChange?: (value: RoleValue | RoleValue[] | undefined | null) => void;
  selectMode?: 'multiple';
  disabled?: boolean;
}

const RoleSearch = (props: RoleSearchProps) => {
  const { value, onChange, selectMode, disabled } = props;
  const [Roles, setRoles] = useState<Array<RoleValue>>([]);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [fetchRoles, { loading, data, error, refetch }] = useLazyQuery(GET_ROLES);
  const [fetchRole, { data: roleData, error: roleError }] = useLazyQuery(GET_ROLE);

  useEffect(() => {
    if (data?.roles?.length > 0) {
      setRoles((data.roles || []).map((ca: RoleValue) => _pick(ca, ['id', 'role_name'])));
    }
  }, [data]);

  useEffect(() => {
    setPermissions(getAllCombinedPermissionsFromRoleObject(roleData?.role));
  }, [roleData]);

  useEffect(() => {
    if (Roles.length === 0) fetchRoles();
  }, [fetchRoles, Roles]);

  useEffect(() => {
    if (!value) return;
    fetchRole({ variables: { id: value } });
  }, [fetchRole, value]);

  const onSelectChange = useCallback(
    (value: any, data: any) => {
      if (onChange) {
        if (selectMode && selectMode === 'multiple') {
          const selected = (data || []).map((d: any) => d.value);
          onChange(selected);
        } else {
          onChange(data?.value);
        }
      }
    },

    [onChange, selectMode]
  );

  let notFoundContent = undefined;
  if (loading) {
    notFoundContent = <Spin size="small" />;
  } else if (error) {
    notFoundContent = (
      <div className="text-color-secondary">
        Unable to Roles.
        <Button
          type="link"
          onClick={() => {
            if (refetch) refetch();
          }}
        >
          Retry
        </Button>
      </div>
    );
  }
  return (
    <Flex vertical gap={10}>
      <Col span={8}>
        <Select
          allowClear
          disabled={disabled}
          mode={selectMode}
          showSearch
          optionFilterProp="label"
          onChange={onSelectChange}
          notFoundContent={notFoundContent}
          value={value}
          style={{ width: '100%' }}
          placeholder={'Select Role'}
        >
          {Roles.map((ba) => {
            return (
              <Option label={ba?.role_name || ''} value={ba.id} key={ba.id}>
                {ba.role_name}
              </Option>
            );
          })}
        </Select>
      </Col>
      {roleData && (
        <PermissionMatrix role_id={roleData?.role?.id} value={permissions} readonly={true} />
      )}
      {roleError && <Typography.Text>Error in fetching role permissions</Typography.Text>}
    </Flex>
  );
};

RoleSearch.displayName = 'RoleSearch';

export default memo(RoleSearch);
