import React, { useState } from 'react';
import BusinessVerticalDrawer from './BusinessVerticalDrawer';

export default function BusinessVerticalForm(props: {
  businessVerticalId: string;
  onClose: () => void;
}) {
  const { businessVerticalId, onClose } = props;
  const [visible, setVisible] = useState(false);
  return (
    <>
      {visible && (
        <BusinessVerticalDrawer
          onClose={() => {
            setVisible(false);
            onClose();
          }}
          businessVerticalId={businessVerticalId}
        />
      )}
      <div onClick={() => setVisible(!visible)}>Edit Business Vertical</div>
    </>
  );
}
