import { erpNextAxios } from './axiosDefaults';

export function erpNextAxiosCall(props) {
  const { action, url, payload, params, timeout, headers } = props;
  return erpNextAxios({
    method: action,
    url: url,
    data: payload,
    params: { limit_page_length: 50, ...params },
    timeout: timeout || 30000,
    headers: headers || {},
  })
    .then((response) => ({ response }))
    .catch((error) => ({
      error: error.message,
      status: error?.response?.status,
    }));
}
