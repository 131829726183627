import React from 'react';
import { Flex, Space, Typography } from '@shipmnts/pixel-hub';

const SummaryCard = ({ updateDefaultFilter, data }: any) => {
  return (
    <Flex
      justify="center"
      style={{
        padding: '10px',
        textAlign: 'center',
        flex: 1,
      }}
    >
      <Flex style={{ fontSize: '16px', width: '60%' }} justify="space-evenly">
        <Space
          direction="vertical"
          onClick={() =>
            updateDefaultFilter({
              field_id: 'user_accounts_disabled',
              condition: {
                type: 'equals',
                value: 'false',
              },
            })
          }
        >
          <Typography.Link style={{ color: '#777980', fontSize: '16px' }}>
            Enabled Users
          </Typography.Link>
          <Typography.Link style={{ margin: 0, color: '#1133B3' }}>
            {data?.get_active_user_summary.enabled}
          </Typography.Link>
        </Space>
        <Space
          direction="vertical"
          onClick={() =>
            updateDefaultFilter({
              field_id: 'user_accounts_disabled',
              condition: {
                type: 'equals',
                value: 'true',
              },
            })
          }
        >
          <Typography.Link style={{ color: '#777980', fontSize: '16px' }}>
            Disabled Users
          </Typography.Link>
          <Typography.Link style={{ margin: 0, color: '#A8071A' }}>
            {data?.get_active_user_summary.disabled}
          </Typography.Link>
        </Space>
        {/* <Space direction="vertical">
          <Typography.Link style={{ color: '#777980', fontSize: '16px' }}>
            Active Users (Last 60 days)
          </Typography.Link>
          <Typography.Text style={{ margin: 0, color: '#1133B3' }}>
            {data?.get_active_user_summary.active_users}
          </Typography.Text>
        </Space> */}
      </Flex>
    </Flex>
  );
};

export default SummaryCard;
