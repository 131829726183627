import { useQuery } from '@apollo/client';
import { Modal, TeamOutlined } from '@shipmnts/pixel-hub';
import React, { useState } from 'react';
import { GET_ROLE_WITH_ASSIGNED_USERS } from 'src/graphQL/role';
import UserList from '../User/UserList';

export const GetAssignedUsers = (props: any) => {
  const { id, defaultOpen, onCancel, onClose } = props;
  const { data } = useQuery(GET_ROLE_WITH_ASSIGNED_USERS, {
    variables: {
      id: id,
    },
  });
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Modal
        open={defaultOpen ? true : isOpen}
        onCancel={() => {
          setIsOpen(false);
          onClose && onClose();
          onCancel && onCancel();
        }}
        footer={null}
        title={
          <div
            style={{
              color: 'var(--primary-grey)',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            <TeamOutlined />
            <span style={{ marginLeft: '5px' }}>
              <b>Users assigned this role</b>
            </span>
          </div>
        }
        style={{ padding: '10px', width: 'fit-content' }}
      >
        <UserList users={data?.role?.assigned_users} />
      </Modal>
    </>
  );
};
