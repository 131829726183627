import { Flex } from '@shipmnts/pixel-hub';
import React from 'react';

const OverviewItem = (props: any) => {
  const { children, styles = {} } = props;
  return (
    <Flex
      vertical
      gap={10}
      style={{
        background: '#FFFFFF',
        padding: '1rem',
        width: '100%',
        ...styles,
      }}
    >
      {children}
    </Flex>
  );
};

export default OverviewItem;
