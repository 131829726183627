import React, { ReactNode, useEffect, useState } from 'react';
import { Link, useLocation } from 'wouter';
import { Button, Dropdown, MoreOutlined } from '@shipmnts/pixel-hub';
import { SessionDataType } from 'src/stores/SessionStore';
import { DisableUser, ResendInvitationEmail, ResetPassword } from '../User/UserActions';
import { CompanyGroupActionModal } from '../CompanyGroup/CompanyGroupForm';
import { GetAssignedUsers } from '../Role/RoleActions';
import { EditActionDrawer } from '../Pipeline/EditActionDrawer';
import EnableDisablePipeline from '../Pipeline/EnableDisablePipeline';

interface ActionRendererProps {
  id?: string;
  doc_type_id: string;
  data?: any;
  refetchData?: () => void;
  selectedActions?: any[];
  children?: ReactNode;
  sessionData?: SessionDataType;
}

interface Action {
  key: string;
  label: string | JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
}

interface ActionRendererType {
  node: any;
  doc_type_id: string;
  refetchData?: () => void;
  selectedActions?: any[];
}

// todo: give proper types
const getUserActions: (
  user: any,
  navigate: any,
  refetchData?: () => void | undefined,
  sessionData?: SessionDataType
) => Action[] = (user, navigate, refetchData?, sessionData?) => {
  const _user = {
    ...user,
    department: user?.user_accounts_department || user?.department,
    user_level: user?.user_accounts_user_level || user?.user_level,
    designation: user?.user_accounts_designation || user?.designation,
    branch_accounts: user?.user_accounts_branch_accounts || user?.branch_accounts,
    business_verticals: user?.business_verticals,
    role: user?.user_accounts_role_id || user?.role,
    disabled: user?.user_accounts_disabled || user?.disabled,
    id: user?.user_accounts_first_name?.extra_fields?.id || user?.id,
  };
  const handleDuplicateUser = () => {
    navigate('~/form/user/new', { state: { user: _user } }); // todo map the state
  };
  const id = user?.user_accounts_first_name?.extra_fields?.id || user?.id;

  if (sessionData?.id === id) {
    return [
      {
        key: '4',
        label: (
          <ResetPassword
            user={{
              id: user?.user_accounts_first_name?.extra_fields.id || user?.id,
            }}
            disabled={_user?.disabled}
          />
        ),
        disabled: _user?.disabled,
      },
    ];
  }

  return [
    {
      key: '1',
      label: <Link to={`~/form/user/${id}`}>Edit</Link>,
      disabled: _user?.disabled,
    },
    {
      key: '2',
      label: 'Duplicate',
      onClick: () => handleDuplicateUser(),
      disabled: false,
    },
    {
      key: '3',
      label: (
        <DisableUser
          user={{
            disabled: user?.user_accounts_disabled || user?.disabled,
            id: id,
          }}
          onSuccess={refetchData}
        />
      ),
      // TODO: find a better solution
      // need to check with sessionData because need to show this only in detail screen and not on workspace(where sessionData is not available)
      disabled: sessionData ? true : false,
    },
    {
      key: '4',
      label: (
        <ResetPassword
          user={{
            id: user?.user_accounts_first_name?.extra_fields.id || user?.id,
          }}
          disabled={_user?.disabled}
        />
      ),
      disabled: _user?.disabled,
    },
    {
      key: '5',
      label: (
        <ResendInvitationEmail
          user={{
            id: user?.user_accounts_first_name?.extra_fields.id || user?.id,
          }}
          disabled={_user?.disabled}
        />
      ),
      disabled: _user?.disabled,
    },
  ].filter((action) => !action.disabled);
};

const getRoleActions: (role: any, navigate: any) => Action[] = (role, navigate) => {
  const id = role?.roles_role_name?.extra_fields?.id || role?.id;
  const handleDuplicateRole = () => {
    navigate('~/form/role/new', { state: { role, role_id: id } });
  };
  return [
    {
      key: '1',
      label: <Link to={`~/form/role/${id}`}>Edit</Link>,
    },
    {
      key: '2',
      label: 'Duplicate',
      onClick: () => handleDuplicateRole(),
    },
    {
      key: '3',
      label: <GetAssignedUsers id={id} />,
    },
  ];
};

const getPipelineActions: (pipeline: any, refetchData: any) => Action[] = (
  pipeline,
  refetchData
) => {
  const id = pipeline?.blueprint_pipelines_name?.extra_fields?.id;
  return [
    {
      key: '1',
      label: <EditActionDrawer id={id} refetchData={refetchData} />,
    },
    {
      key: '2',
      label: (
        <EnableDisablePipeline pipeline={pipeline} pipeline_id={id} refetchData={refetchData} />
      ),
    },
  ];
};

const getCompanyGroupActions: (companygroup: any, navigate: any) => Action[] = (
  companygroup,
  navigate
) => {
  if (companygroup.company_groups_is_standard) return [];
  const id = companygroup?.id;
  return [
    // {
    //   key: '1',
    //   label: <CompanyGroupActionModal action='update' id={id} />,
    // },
    {
      key: '1',
      label: (
        <CompanyGroupActionModal
          action={companygroup.company_groups_is_disabled ? 'enable' : 'disable'}
          is_disabled={companygroup.company_groups_is_disabled}
          name={companygroup?.company_groups_name}
          id={id}
        />
      ),
    },
    {
      key: '2',
      label: (
        <CompanyGroupActionModal action="delete" id={id} name={companygroup?.company_groups_name} />
      ),
    },
  ];
};

export default function ActionRendererReport(props: ActionRendererType) {
  if (!props.node.data) return <></>;
  if (props.node.rowPinned) return <span></span>;
  return (
    <ActionRenderer
      data={props.node.data}
      id={props.node.data?.id}
      doc_type_id={props.doc_type_id}
      refetchData={props.refetchData}
      selectedActions={props.selectedActions}
    />
  );
}

export const getActionColDef = (doc_type_id: string, refetch?: any) => {
  return {
    headerName: 'Actions',
    lockPosition: 'right',
    suppressMovable: true,
    colId: 'actions',
    field: 'actions',
    valueGetter: 'data',
    cellRenderer: 'actions_render',
    width: 80,
    pinned: 'right',
    headerComponent: 'static_header_component',
    suppressNavigable: true,
    cellRendererParams: {
      doc_type_id,
      refetchData: refetch,
    },
  };
};

export const ActionRenderer = (props: ActionRendererProps) => {
  const { doc_type_id, data, refetchData, children, sessionData } = props;
  const [menuItems, setMenuItems] = useState<Action[]>([]);
  const [row, setRow] = useState({});
  const { 1: navigate } = useLocation();

  useEffect(() => {
    if (data) {
      setRow(data);
    }
  }, [data]);

  useEffect(() => {
    if (doc_type_id === 'Network::UserAccount') {
      setMenuItems(getUserActions(row, navigate, refetchData, sessionData));
    } else if (doc_type_id === 'Network::Role') {
      setMenuItems(getRoleActions(row, navigate));
    } else if (doc_type_id === 'Blueprint::Pipeline::Pipeline') {
      setMenuItems(getPipelineActions(row, refetchData));
    } else if (doc_type_id === 'Network::CompanyGroup') {
      setMenuItems(getCompanyGroupActions(row, navigate));
    }
  }, [doc_type_id, navigate, refetchData, row, sessionData]);

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Dropdown menu={{ items: menuItems }} trigger={['click']}>
        {children ? (
          children
        ) : (
          <Button style={{ color: 'var(--ant-color-primary)', borderRadius: 0 }} size="small">
            <MoreOutlined rotate={90} />
          </Button>
        )}
      </Dropdown>
    </div>
  );
};
