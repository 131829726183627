import React from 'react';
import ReactDOM from 'react-dom/client';
import BaseLayout from './BaseLayout';
import 'mobx-react-lite/batchingForReactDom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // Strict Mode Commented to fix react draggable: https://stackoverflow.com/questions/67780314/react-beautiful-dnd-invariant-failed-cannot-find-droppable-entry-with-id
  // <React.StrictMode>
  <BaseLayout />
  // </React.StrictMode>
);
