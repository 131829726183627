import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'wouter';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AppHelmet,
  Button,
  Checkbox,
  CopyOutlined,
  EditOutlined,
  Flex,
  Form,
  PageHeader,
  Steps,
  Typography,
  message,
} from '@shipmnts/pixel-hub';
import {
  CREATE_USER_ACCOUNT,
  GET_USER_FOR_EDIT_OR_DUPLICATE,
  UPDATE_USER_ACCOUNT,
} from 'src/graphQL/user';
import BasicDetailsForm from 'src/components/User/BasicDetailsForm';
import LoadingSpinner from 'src/components/common/LoadingSpinner';
import PermissionDetailsForm from 'src/components/User/PermissionDetailsForm';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import { getFilteredBusinessVerticals } from 'src/helpers/helpers';

const UserForm = () => {
  const params = useParams();
  const { 1: navigate } = useLocation();
  const { user_id } = params;
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(window.history?.state?.step_index - 1 || 0);
  const [user, setUser] = useState<any>(window.history?.state?.user);
  const [basicDetailsFormValues, setBasicDetailsFormValues] = useState<any>({});
  const [allowNext, setAllowNext] = useState(false);
  const [sendInvitation, setSendInvitation] = useState(true);
  const [forceUpdateKey, setForceUpdateKey] = useState(0);

  const inEditMode = user_id !== 'new';

  const [getUser, { data: userData, error: userError, loading: userLoading }] = useLazyQuery(
    GET_USER_FOR_EDIT_OR_DUPLICATE
  );
  const [
    createUserAccount,
    { data: createUserData, loading: createUserLoading, error: createUserError },
  ] = useMutation(CREATE_USER_ACCOUNT);
  const [
    updateUserAccount,
    { data: updateUserData, loading: updateUserLoading, error: updateUserError },
  ] = useMutation(UPDATE_USER_ACCOUNT);

  // TO REVERT THE COMMENT

  const sessionData: SessionDataType = sessionStore.getSessionData();
  const is_create_user_disabled =
    sessionData?.company_account?.seats[0]?.billing_seats -
      sessionData?.company_account?.total_active_users_count <=
    0;

  const steps = useMemo(() => {
    return [
      {
        title: 'Step 1',
        description: 'Enter Basic Details',
        content: <BasicDetailsForm form={form} setAllowNext={setAllowNext} />,
      },
      {
        title: 'Step 2',
        description: 'Setup User Permissions',
        content: <PermissionDetailsForm form={form} forceUpdateKey={forceUpdateKey} />,
      },
    ];
  }, [form, forceUpdateKey]);

  const getFirstStepValues = useCallback(() => {
    if (user) {
      if (!inEditMode && !window.history?.state?.step_index) {
        return {
          department: user?.department,
          user_level: user?.user_level,
          title: user?.title,
          personal_email: user?.personal_email,
        };
      }
      return {
        email: user?.email,
        first_name: user?.first_name,
        last_name: user?.last_name,
        mobile_number: user?.mobile_number,
        department: user?.department,
        user_level: user?.user_level,
        title: user?.title,
        personal_email: user?.personal_email,
        business_vertical_ids: getFilteredBusinessVerticals(user?.business_verticals).map(
          (vertical: any) => vertical.id
        ),
      };
    }
    return {};
  }, [inEditMode, user]);

  const getSecondStepValues = useCallback(() => {
    if (user) {
      return {
        branch_account_ids: user?.branch_accounts,
        role_id: user?.role?.id?.toString(),
        permission_tags: user?.permission_tags || [],
        business_vertical_ids: getFilteredBusinessVerticals(user?.business_verticals).map(
          (vertical: any) => vertical.id
        ),
      };
    }
    return {};
  }, [user]);

  const next = useCallback(() => {
    form
      .validateFields()
      .then(() => {
        setBasicDetailsFormValues(form.getFieldsValue());
        form.setFieldsValue(getSecondStepValues());
        setCurrent(current + 1);
      })
      .catch(() => {
        message.error('Please fill all the required fields');
      });
  }, [current, form, getSecondStepValues]);

  const prev = useCallback(() => {
    setCurrent(current - 1);
    form.setFieldsValue(basicDetailsFormValues);
  }, [current, form, basicDetailsFormValues]);

  const cancel = useCallback(() => {
    navigate('~/view/users');
  }, [navigate]);

  useEffect(() => {
    if (userError) {
      message.error('Error while fetching user data');
    } else if (userData?.user) {
      setUser(userData.user);
    }
  }, [userData, userError]);

  useEffect(() => {
    if (createUserData?.create_user_account?.id || updateUserData?.update_user_account?.id) {
      const msg = `User ${user_id && inEditMode ? 'Updated' : 'Created'} Successfully`;
      message.success(msg);
      if (!(user_id && inEditMode)) sessionStore.increaseTotalActiveUser();
      navigate(`~/view/users`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUserData, createUserError, updateUserData, updateUserError]);

  useEffect(() => {
    if (window.history.state?.step_index === 2) {
      setBasicDetailsFormValues(getFirstStepValues());
      form.setFieldsValue(getSecondStepValues());
    }
  }, [form, getFirstStepValues, getSecondStepValues]);

  useEffect(() => {
    if (inEditMode) {
      getUser({
        variables: {
          id: user_id,
        },
      });
    }
  }, [getUser, inEditMode, user_id]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue(getFirstStepValues());
    }
  }, [form, getFirstStepValues, user]);

  if (userLoading) {
    return <LoadingSpinner loadingMessage="Loading User Data..." />;
  }
  if (createUserLoading || updateUserLoading) {
    const message = createUserLoading ? 'Creating User...' : 'Updating User...';
    return <LoadingSpinner loadingMessage={message} />;
  }

  const title = !inEditMode
    ? window.history.state?.user
      ? `Duplicate user`
      : 'Create new user'
    : `Edit | ${user?.first_name} ${user?.last_name}`;

  if (is_create_user_disabled && !inEditMode) return <></>;

  return (
    <Flex vertical>
      <AppHelmet>
        <title>{title}</title>
      </AppHelmet>
      <PageHeader
        className="fixed-header full-width"
        onBack={() => {
          window.history.back();
        }}
        title={
          <div
            style={{
              padding: '15px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography.Title level={5} style={{ marginBottom: 0, marginRight: '10px' }}>
              {!inEditMode ? (
                window.history.state?.user ? (
                  <CopyOutlined />
                ) : (
                  <EditOutlined />
                )
              ) : (
                <EditOutlined />
              )}
              <span style={{ marginLeft: '10px' }}>{title}</span>
            </Typography.Title>
          </div>
        }
      />
      <Steps current={current} items={steps}></Steps>
      <Form
        form={form}
        onFinish={(values) => {
          const finalValues: any = {
            ...basicDetailsFormValues,
            ...values,
            business_vertical_ids: (values.business_vertical_ids || []).map((vertical_id: string) =>
              parseInt(vertical_id)
            ),
            branch_account_ids: values.branch_account_ids.map((branch: any) => parseInt(branch.id)),
            id: inEditMode ? user.id : undefined,
            confirm_password: undefined,
            send_invitation_email: !inEditMode ? sendInvitation : false,
          };
          if (!inEditMode) {
            createUserAccount({ variables: { user: finalValues } });
          } else {
            updateUserAccount({ variables: { id: user.id, user: finalValues } });
          }
        }}
        onValuesChange={(values) => {
          if ('permission_tags' in values) setForceUpdateKey(1 - forceUpdateKey);
        }}
        labelAlign="left"
        colon={false}
        scrollToFirstError={true}
        layout="vertical"
      >
        <div style={{ marginBottom: '48px' }}>{steps[current].content}</div>
        <Flex
          justify="flex-end"
          gap={10}
          style={{
            position: 'absolute',
            bottom: '0',
            right: '0',
            background: '#fff',
            padding: '0 24px',
            paddingTop: '12px',
            width: '100%',
          }}
        >
          {current === steps.length - 1 && !inEditMode && (
            <Form.Item>
              <Checkbox
                defaultChecked={true}
                style={{ marginTop: '10px' }}
                onChange={(e) => {
                  setSendInvitation(e.target.checked);
                }}
              >
                Send Invitation Email
              </Checkbox>
            </Form.Item>
          )}
          <div style={{ display: 'flex', gap: '10px', marginBottom: '24px' }}>
            <Button onClick={() => cancel()}>Cancel</Button>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()} disabled={!allowNext}>
                Go To Next
              </Button>
            )}
            {current > 0 && <Button onClick={() => prev()}>Go To Previous</Button>}
            {current === steps.length - 1 && (
              <Button type="primary" htmlType="submit">
                {inEditMode ? 'Update ' : 'Create '} User
              </Button>
            )}
          </div>
        </Flex>
      </Form>
    </Flex>
  );
};

export default UserForm;
