import React from 'react';

import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import { Action, PerformAction } from '../common/commonTypeDefs';
import {
  CopyOutlined,
  CustomIcon,
  KeyOutlined,
  MailOutlined,
  message,
  StopOutlined,
} from '@shipmnts/pixel-hub';
import { DisableUser, ResetPasswordDrawer } from './UserActions';
import { NEGATIVE_TYPE, PRIMARY_TYPE } from '../common/constants';
import { axiosInstance } from 'src/setup/axiosDefaults';

export const getDisableUserAction = (user: any): Action => {
  const updateUserAllowed = !user?.disabled || sessionStore.canCreateActivateUser();

  return {
    key: 'toggle_disabled',
    type: user?.disabled ? PRIMARY_TYPE : NEGATIVE_TYPE,
    displayComponent: `${user?.disabled ? 'Enable' : 'Disable'} User`,
    icon: <StopOutlined height="15" width="15" />,
    performAction: toggleDisabledAction,
    description: 'duplicate_user',
    isEnable: updateUserAllowed,
  };
};

export const getResetPasswordAction = (user: any): Action => ({
  key: 'reset_password',
  type: PRIMARY_TYPE,
  displayComponent: 'Reset Password',
  performAction: resetPasswordAction,
  icon: <KeyOutlined />,
  description: 'reset_password',
  isEnable: !user?.disabled,
});

export const getResendInviteEmailAction = (user: any): Action => ({
  key: 'resend_invite_email',
  type: PRIMARY_TYPE,
  displayComponent: 'Resend Invite Email',
  icon: <MailOutlined />,
  onClick: async () => {
    message.success('Sending Email....');
    await axiosInstance
      .get(process.env.REACT_APP_NETWORK_API_URL + '/user_accounts/send_invitation_email', {
        params: {
          id: user.id,
        },
      })
      .catch((e: any) => {
        message.error('Error sending email, please try again');
      });
  },
  description: 'resend_invite_email',
  isEnable: !user?.disabled,
});

export const getUserActions: (
  user: any,
  navigate: any,
  refetchData?: () => void | undefined,
  sessionData?: SessionDataType
) => Action[] = (user, navigate, refetchData?, sessionData?) => {
  const hideAction = user?.disabled || sessionData?.user_level !== 'admin';

  const actions: Action[] = [];

  actions.push({
    key: 'edit_user',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit',
    icon: <CustomIcon height="15" icon="Edit" width="15" />,
    description: 'edit_user',
    isEnable: !hideAction,
    onClick: () => {
      navigate('~/form/user/' + user?.id);
    },
  });

  actions.push({
    key: 'duplicate_user',
    type: PRIMARY_TYPE,
    displayComponent: 'Duplicate',
    icon: <CopyOutlined height="15" width="15" />,
    description: 'duplicate_user',
    isEnable: !hideAction,
    onClick: () => {
      navigate('~/form/user/new', { state: { user } });
    },
  });

  actions.push(getResetPasswordAction(user));
  if (user?.id !== sessionData?.id) {
    actions.push(getDisableUserAction(user));
    actions.push(getResendInviteEmailAction(user));
  }

  return actions;
};

const toggleDisabledAction = (payload: any): PerformAction => {
  const { user } = payload;
  return {
    actionParams: {
      user: user,
    },
    component: DisableUser,
  };
};

const resetPasswordAction = (payload: any): PerformAction => {
  const { user } = payload;
  return {
    actionParams: {
      user: user,
    },
    component: ResetPasswordDrawer,
  };
};
