import { Result } from '@shipmnts/pixel-hub';
import React from 'react';

type ErrorPageProps = {
  title: string;
  extra?: any;
};

const ErrorPage = (props: ErrorPageProps) => {
  return <Result status={404} title={props.title} extra={props.extra} />;
};

export default ErrorPage;
