import { action, makeObservable, observable, configure } from 'mobx';

type CompanyAccountType = {
  id: string;
  name: string;
  subdomain: string;
  registered_name: string;
  seats: Array<{
    billing_seats: number;
    tenant_id: string;
  }>;
  total_active_users_count: number;
  country_of_incorporation: string;
  default_company: {
    id: string;
    registered_name: string;
  };
};

export type SessionDataType = {
  id: string;
  first_name: string;
  last_name?: string;
  email: string;
  company_account: CompanyAccountType;
  tenant_id: string;
  user_level: string;
  role: any;
  permissions: any;
};

class SessionStore {
  // ! NOTE: Cannot mark this as private, but always try to use Getters and Setters to get/set value
  sessionData = {} as SessionDataType;

  constructor() {
    configure({ isolateGlobalState: true }); // Pixelhub hotfix for mobx errors
    makeObservable(this, {
      sessionData: observable,
      setSessionData: action,
    });
  }

  setSessionData = (data: SessionDataType) => {
    const permissions = data?.role?.permissions || [];
    this.sessionData = { ...data, permissions };
  };

  canCreateActivateUser = () => {
    const available_seats =
      this.sessionData?.company_account?.seats[0]?.billing_seats -
      this.sessionData?.company_account?.total_active_users_count;

    return available_seats > 0;
  };

  getSessionData = (): SessionDataType => {
    return this.sessionData as SessionDataType;
  };

  updateSeats = (seats: number): void => {
    this.sessionData.company_account.seats[0].billing_seats = seats;
  };

  increaseTotalActiveUser = (): void => {
    this.sessionData.company_account.total_active_users_count++;
  };
  decreaseTotalActiveUser = (): void => {
    this.sessionData.company_account.total_active_users_count--;
  };
}
const sessionStore = new SessionStore();

export default sessionStore;
