import { gql, useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
const UPDATE_VIEW_SUBSCRIPTION_TEMPLATE = gql`
  mutation updateViewSubscriptionTemplate(
    $id: ID!
    $view_subscription_template: UpdateViewSubscriptionInputType!
  ) {
    update_view_subscription_template(
      id: $id
      view_subscription_template: $view_subscription_template
    ) {
      id
    }
  }
`;
interface StatusUpdateViewSubscriptionProps {
  viewSubscriptionTemplate: any;
  onClose?: () => void;
  onSuccess?: () => void;
}
function StatusUpdateViewSubscriptionTemplate(props: StatusUpdateViewSubscriptionProps) {
  const { onClose, viewSubscriptionTemplate, onSuccess } = props;
  const [disableVS, { data, error }] = useMutation(UPDATE_VIEW_SUBSCRIPTION_TEMPLATE);
  const activeSubscription = viewSubscriptionTemplate?.status === 'active';
  const actionName = activeSubscription ? 'Disable' : 'Enable';

  const [modal, contextHolder] = Modal.useModal();
  useEffect(() => {
    if (error) {
      message.error(error.message);
      return;
    }
    if (data?.update_view_subscription_template) {
      message.success(`Subscription ${actionName}d successfully!`);
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  }, [data, error, onSuccess, onClose, actionName]);

  const config = {
    title: `Are you sure to ${actionName} this Subscription Template?`,
    content: `Once you click on Confirm, It will ${actionName} Subscription Template`,
    okText: `Confirm ${actionName}`,
    cancelText: 'Cancel',
    onCancel: () => {
      if (onClose) onClose();
    },
    onOk: async () => {
      disableVS({
        variables: {
          id: viewSubscriptionTemplate?.id,
          view_subscription_template: {
            status: activeSubscription ? 'disabled' : 'active',
          },
        },
      });
    },
  };

  return (
    <>
      {contextHolder}
      <div onClick={() => modal.confirm(config)}>{actionName} Email Template</div>
    </>
  );
}

export default StatusUpdateViewSubscriptionTemplate;
