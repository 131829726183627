import { Button, EditOutlined, Flex, List, message, Typography } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import OverviewItem from './OverviewItem';
import { UPDATE_BILLING_EMAILS } from 'src/graphQL/subscription';
import { useMutation } from '@apollo/client';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const textColor: React.CSSProperties = {
  color: 'var(--secondary-black-color)',
  fontFamily: 'Inter',
};

const BillingInfo = (props: any) => {
  const { billing_contacts = [], billing_address = '', onSuccess } = props;
  const sessionData: SessionDataType = sessionStore.getSessionData();

  const [editBillingContacts, setEditBillingContacts] = useState(false);
  const [billingContacts, setBillingContacts] = useState<any>(billing_contacts);

  useEffect(() => setBillingContacts(billing_contacts), [billing_contacts]);

  const [updateBillingEmails, { data: updateBillingData, error: updateBillingError }] =
    useMutation(UPDATE_BILLING_EMAILS);

  useEffect(() => {
    if (!updateBillingError && updateBillingData) {
      message.success('Contact emails updated successfully!');
      setEditBillingContacts(false);
      onSuccess(billingContacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBillingData, updateBillingError]);

  const updateBillingContacts = () => {
    const data = {
      emails: billingContacts.map((bc: any) => bc.email).join(','),
    };
    updateBillingEmails({ variables: data });
  };

  return (
    <>
      <Typography.Title level={5} style={textColor}>
        Billing Information
      </Typography.Title>
      <Flex gap={16}>
        <OverviewItem styles={{ background: '#F7F7F7' }}>
          <Flex justify="space-between" align="center">
            <Typography.Text style={textColor}>BILLING CONTACT</Typography.Text>
            {editBillingContacts ? (
              <Button
                style={{ border: 'none', background: 'none', padding: 0 }}
                onClick={() => {
                  setBillingContacts(billing_contacts);
                  setEditBillingContacts(false);
                }}
              >
                X
              </Button>
            ) : (
              <Button
                style={{ border: 'none', background: 'none', padding: 0 }}
                onClick={() => setEditBillingContacts(true)}
              >
                <EditOutlined />
              </Button>
            )}
          </Flex>
          {editBillingContacts ? (
            <>
              <GlobalSearch
                doc_type="Network::UserContact"
                extraProps={{
                  company: sessionData.company_account.default_company,
                }}
                selectMode="multiple"
                value={billingContacts}
                onChange={(newValue: any) => {
                  setBillingContacts(newValue);
                }}
              />
              <Button onClick={updateBillingContacts}>Update</Button>
            </>
          ) : (
            <List bordered>
              {billingContacts.map((bc: any) => (
                <List.Item style={{ background: '#FFFFFF', padding: 6 }} key={bc.email}>
                  {bc.email}
                </List.Item>
              ))}
            </List>
          )}
        </OverviewItem>
        <OverviewItem styles={{ background: '#F7F7F7' }}>
          <Typography.Text style={textColor}>BILLING ADDRESS</Typography.Text>
          <div dangerouslySetInnerHTML={{ __html: billing_address }} />
        </OverviewItem>
      </Flex>
    </>
  );
};

export default BillingInfo;
