import React, { useEffect } from 'react';
import {
  Col,
  Typography,
  Form,
  Input,
  Row,
  Select,
  Space,
  FormInstance,
  Spin,
  LoadingOutlined,
  Alert,
} from '@shipmnts/pixel-hub';
import { useParams } from 'wouter';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_ACCOUNTS } from 'src/graphQL/user';
import sessionStore from 'src/stores/SessionStore';
import { DEPARTMENTS } from '../common/constants';
import { getGeneratedPassword } from 'src/helpers/helpers';
import UserList from './UserList';

const typeOfUsers = [
  {
    value: 'admin',
    title: 'System Admin',
    description: 'Admin level access to all modules as per License',
  },
  {
    value: 'user',
    title: 'User',
    description: 'Access to all modules as per License',
  },
];

const OptionItem = ({ title, description }: any) => {
  return (
    <div style={{ padding: '5px' }}>
      <span style={{ display: 'block', fontSize: '14px', fontWeight: 'bold' }}>{title}</span>
      <span>{description}</span>
    </div>
  );
};

interface BasicDetailsProps {
  form: FormInstance;
  setAllowNext?: (value: boolean) => void;
}

const colProps = {
  span: 12,
  xs: { span: 24 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 12 },
};

const BasicDetailsForm = ({ form, setAllowNext }: BasicDetailsProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const [duplicateUsers, setDuplicateUsers] = React.useState([]);
  const sessionData = sessionStore.getSessionData();
  const [generateRandomPasswordLoading, setGenerateRandomPasswordLoading] = React.useState(false);

  const [getUserAccounts, { loading: fetchingUserAccounts, data: userAccountsData }] =
    useLazyQuery(GET_USER_ACCOUNTS);

  const params = useParams();
  const { user_id } = params;

  const generateRandomPassword = async () => {
    setGenerateRandomPasswordLoading(true);
    const { data } = await getGeneratedPassword();
    form.setFieldsValue({
      password: data?.password,
      confirm_password: data?.password,
    });
    setGenerateRandomPasswordLoading(false);
    setIsPasswordVisible(true);
  };

  useEffect(() => {
    if (userAccountsData && userAccountsData?.user_account_search) {
      setDuplicateUsers([]);
      const usersWithThisEmail = userAccountsData?.user_account_search?.filter(
        (user: any) => user.email === form.getFieldValue('email')
      );
      setDuplicateUsers(usersWithThisEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountsData]);

  useEffect(() => {
    setAllowNext && setAllowNext(duplicateUsers.length === 0);
  }, [duplicateUsers, setAllowNext]);

  const checkEmailDuplicate = () => {
    const email = form.getFieldValue('email');
    if (email) {
      getUserAccounts({
        variables: {
          organization_id: sessionData?.company_account?.id,
          query: email,
        },
      });
    }
  };

  return (
    <>
      <Row gutter={24}>
        <Col {...colProps}>
          <Form.Item
            label={'Business Email'}
            name={'email'}
            rules={[{ required: true, message: 'Please Enter Business email of the User' }]}
            validateStatus={duplicateUsers.length > 0 ? 'error' : 'success'}
            help={duplicateUsers.length > 0 ? 'Looks like a duplicate email' : null}
          >
            <Input
              placeholder="Enter Business Email of the User"
              disabled={user_id !== 'new'}
              onBlur={() => checkEmailDuplicate()}
            />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            label={'Mobile Number'}
            name={'mobile_number'}
            rules={[
              { required: true, message: 'Please Enter Mobile Number' },
              {
                len: 10,
                message: 'mobile number should be of exact 10 digit',
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input placeholder="Mobile Number" />
            {/* <PhoneNumberInput defaultValueDialCode={'IN'} /> */}
          </Form.Item>
        </Col>
      </Row>
      {!fetchingUserAccounts && userAccountsData && duplicateUsers.length > 0 && (
        <Row>
          <Col span={24}>
            <Alert
              type="error"
              banner
              closable
              message="Are you sure it isn't any one of the following users?"
              style={{ width: 'fit-content' }}
            />
            <UserList users={userAccountsData?.user_account_search} />
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col {...colProps}>
          <Form.Item
            label={'First Name'}
            name={'first_name'}
            rules={[{ required: true, message: 'Please Enter First Name' }]}
          >
            <Input placeholder="Enter First Name" />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item label={'Last Name'} name={'last_name'}>
            <Input placeholder="Enter Last Name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...colProps}>
          {user_id === 'new' && (
            <Form.Item
              label={
                <Space size={'small'}>
                  Password
                  {generateRandomPasswordLoading ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    <Typography.Link onClick={() => generateRandomPassword()}>
                      Click here to generate password
                    </Typography.Link>
                  )}
                </Space>
              }
              name={'password'}
              rules={[{ required: true, message: 'Please Enter Password' }]}
            >
              <Input.Password
                placeholder="Enter Password"
                minLength={8}
                visibilityToggle={{
                  visible: isPasswordVisible,
                  onVisibleChange: (visible) => {
                    setIsPasswordVisible(visible);
                  },
                }}
              />
            </Form.Item>
          )}
        </Col>
        <Col {...colProps}>
          {user_id === 'new' && (
            <Form.Item
              label={'Confirm Password'}
              name={'confirm_password'}
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please Enter Confirm Password' },
                {
                  validator: (rule, value, callback) => {
                    if (value && value !== form.getFieldValue('password')) {
                      callback('Passwords do not match!');
                    } else {
                      callback();
                    }
                  },
                },
              ]}
            >
              <Input.Password placeholder="Enter Confirm Password" />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...colProps}>
          <Form.Item label={'Department'} name={'department'}>
            <Select mode="multiple" placeholder="Select The Department of the User">
              {DEPARTMENTS.sort().map((item) => (
                <Select.Option value={item} title={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...colProps}>
          <Form.Item
            label={'Type of User'}
            name={'user_level'}
            rules={[{ required: true, message: 'Please Select User Level' }]}
            initialValue={'user'}
          >
            <Select placeholder={'Select type of User'} optionLabelProp="title">
              {typeOfUsers.map((item, ind) => (
                <Select.Option value={item.value} title={item.title} key={ind}>
                  <OptionItem title={item.title} description={item.description} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item label={'Alternate Email'} name={'personal_email'}>
            <Input placeholder="Enter Personal Email of the User" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...colProps}>
          <Form.Item label={'Designation'} name={'title'}>
            <Input placeholder="Enter the Designation of the User" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BasicDetailsForm;
