import React from 'react';
import { message, Modal } from '@shipmnts/pixel-hub';
import { erpNextAxiosCall } from 'src/setup/erpnextApiHelper';

const disableTemplate = async (payload: any) => {
  try {
    const url =
      'api/method/shipmnts.custom_script.custom_auto_email_report.custom_auto_email_report.enable_disable_template';
    const response = await erpNextAxiosCall({
      action: 'post',
      url,
      payload: payload,
    });
    return { response };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

interface DisableFinanceAutoEmailTemplateProps {
  template_name: string;
  enabled: string;
  onClose?: () => void;
}
export default function DisableFinanceAutoEmailTemplate(
  props: DisableFinanceAutoEmailTemplateProps
) {
  const { template_name, enabled, onClose } = props;
  const [modal, contextHolder] = Modal.useModal();
  const status = enabled === 'Disable' ? 'Enable' : 'Disable';

  return (
    <>
      {contextHolder}
      <div
        onClick={() => {
          modal.confirm({
            title: `Are you sure you want to ${status}?`,
            okText: status,
            centered: true,
            onOk: async () => {
              const { response }: any = await disableTemplate({
                template_name: template_name,
              });
              if (response?.response) {
                message.success(`Email Subscription ${status}d successfully`);
                onClose && onClose();
              }
            },
          });
        }}
      >
        {status} Email Template
      </div>
    </>
  );
}
