import {
  Button,
  Col,
  Flex,
  Layout,
  Modal,
  Progress,
  Row,
  Space,
  Typography,
} from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import BuySeatsForm from './BuySeatsForm';
import { Link } from 'wouter';
import OverviewItem from './OverviewItem';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SUBSCRIPTION_OVERVIEW, NOTIFY_INTERNAL_TEAM } from 'src/graphQL/subscription';
import LoadingSpinner from '../common/LoadingSpinner';
import ErrorPage from '../common/ErrorPage';
import ReduceSeatsForm from './ReduceSeatsForm';
import BillingInfo from './BillingInfo';
import { amountRendererWithCurrency } from 'src/helpers/helpers';
import sessionStore from 'src/stores/SessionStore';

const textColor: React.CSSProperties = {
  color: 'var(--secondary-black-color)',
  fontFamily: 'Inter',
};

type AddOn = {
  id: number;
  description: string;
  label: string;
};

type Plan = {
  current_seats: number;
  description: string;
  label: string;
  ops_seat_id: number;
  plan_type: string;
  price: number;
  quantity: number;
};

type SubscriptionData = {
  billing_contacts: any;
  billing_address: string;
  plans: Plan[];
  add_ons: AddOn[];
  overdue: number;
};

export const BuySeatButton = ({ onClick }: { onClick?: () => void }) => {
  const [notifyTeam] = useMutation(NOTIFY_INTERNAL_TEAM);
  return (
    <Button
      type="primary"
      onClick={() => {
        notifyTeam({ variables: { action: 'buy seats' } });
        onClick && onClick();
      }}
    >
      Buy Seats
    </Button>
  );
};

export const ReduceSeatButton = ({ onClick }: { onClick: () => void }) => {
  const [notifyTeam] = useMutation(NOTIFY_INTERNAL_TEAM);
  return (
    <Button
      danger
      onClick={() => {
        notifyTeam({ variables: { action: 'reduce seats' } });
        onClick();
      }}
    >
      Reduce Seats
    </Button>
  );
};

const SubscriptionOverview = () => {
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionData>();
  const [isBuySeatsModalVisible, setIsBuySeatsModalVisible] = useState(
    window.history?.state?.with_buy_form_open ?? false
  );
  const [isReduceSeatsModalVisible, setIsReduceSeatsModalVisible] = useState(false);

  const {
    refetch,
    data,
    error: subscriptionDataError,
    loading: subscriptionDataLoading,
  } = useQuery(GET_SUBSCRIPTION_OVERVIEW);

  useEffect(() => {
    data?.get_subscription_overview && setSubscriptionData(data?.get_subscription_overview);
    sessionStore.updateSeats(data?.get_subscription_overview?.plans[0].quantity);
  }, [data]);

  if (subscriptionDataLoading) return <LoadingSpinner loadingMessage="Loading data" />;

  if (subscriptionDataError) return <ErrorPage title="Something went wrong!" />;

  if (!data.get_subscription_overview) return <ErrorPage title="No Subscriptions Found!" />;

  return (
    <Flex vertical gap={12} style={{ background: '#F7F7F7', padding: '1rem' }}>
      <Row gutter={24} style={{ paddingRight: '12px' }}>
        <Col span={6}>
          <Flex vertical gap={10}>
            <OverviewItem>
              <Typography.Title level={5} style={textColor}>
                Overdue Amount
              </Typography.Title>
              <strong style={{ fontSize: '20px' }}>
                <span style={{ color: 'var(--bg-critical-red-2' }}>
                  {amountRendererWithCurrency({
                    value: subscriptionData?.overdue || 0,
                    currency: 'INR',
                    precision: 2,
                  })}
                </span>
              </strong>
            </OverviewItem>
          </Flex>
        </Col>

        <Col span={18} style={{ background: '#FFFFFF', padding: '1rem' }}>
          <BillingInfo
            billing_contacts={subscriptionData?.billing_contacts}
            billing_address={subscriptionData?.billing_address}
            onSuccess={(billingContacts: any) => {
              const newData = {
                ...subscriptionData,
                billing_contacts: billingContacts,
              } as SubscriptionData;
              setSubscriptionData(newData);
            }}
          />
        </Col>
      </Row>

      <Layout style={{ padding: '1rem', position: 'relative' }}>
        <Typography.Title level={5} style={textColor}>
          Your Subscriptions
        </Typography.Title>
        <Flex vertical gap={10}>
          {subscriptionData?.plans.map((plan: Plan) => {
            return (
              <div key={plan.ops_seat_id}>
                <Modal
                  open={isBuySeatsModalVisible}
                  footer={null}
                  width={300}
                  onCancel={() => setIsBuySeatsModalVisible(false)}
                >
                  <BuySeatsForm
                    key={plan.quantity}
                    seat={plan}
                    onFinish={() => {
                      setIsBuySeatsModalVisible(false);
                      refetch();
                    }}
                  />
                </Modal>
                <Modal
                  open={isReduceSeatsModalVisible}
                  footer={null}
                  width={300}
                  onCancel={() => setIsReduceSeatsModalVisible(false)}
                >
                  <ReduceSeatsForm
                    key={plan.quantity}
                    seat={plan}
                    onFinish={() => {
                      setIsReduceSeatsModalVisible(false);
                      refetch();
                    }}
                  />
                </Modal>
                <OverviewItem
                  key={plan.ops_seat_id}
                  styles={{ background: '#F7F7F7', width: '100%' }}
                >
                  <Flex vertical>
                    <Typography.Text style={textColor}>{plan.plan_type}</Typography.Text>
                    <Typography.Paragraph style={{ margin: 0, fontSize: '24px' }}>
                      {plan.label}{' '}
                      <span style={{ fontSize: '18px', ...textColor }}>
                        ({plan.quantity} Seats)
                      </span>
                    </Typography.Paragraph>
                    <Typography.Paragraph style={textColor}>
                      <div dangerouslySetInnerHTML={{ __html: plan.description }} />
                    </Typography.Paragraph>
                    <OverviewItem styles={{ width: '100%' }}>
                      <Typography.Text style={{ textTransform: 'uppercase', ...textColor }}>
                        Seat Utilisation
                      </Typography.Text>
                      <Typography.Text style={textColor}>
                        <Flex gap={8} justify="space-between">
                          <p>
                            <span style={{ fontSize: '24px', fontWeight: 600, lineHeight: '24px' }}>
                              {plan.current_seats} / {plan.quantity}
                            </span>{' '}
                            seats are assigned.
                          </p>
                          <Link to="/view/users">
                            <Button>View Users</Button>
                          </Link>
                        </Flex>
                        <Progress
                          percent={
                            100 - ((plan.quantity - plan.current_seats) / plan.quantity) * 100
                          }
                          showInfo={false}
                        />
                      </Typography.Text>
                      <Flex gap={8} justify="flex-end">
                        {plan.quantity - plan.current_seats !== 0 && (
                          <ReduceSeatButton
                            onClick={() => {
                              setIsReduceSeatsModalVisible(true);
                            }}
                          />
                        )}
                        <BuySeatButton
                          onClick={() => {
                            setIsBuySeatsModalVisible(true);
                          }}
                        />
                      </Flex>
                    </OverviewItem>
                  </Flex>
                </OverviewItem>
              </div>
            );
          })}
        </Flex>
      </Layout>
      {(subscriptionData?.add_ons || []).length > 0 && (
        <OverviewItem styles={{ width: '100%' }}>
          <Typography.Title level={4} style={textColor}>
            Add-Ons
            {/* <Button type="primary" style={{ float: 'right' }} disabled>
            Buy Credits
          </Button> */}
          </Typography.Title>
          <Space direction="vertical">
            <Flex wrap="wrap" gap={14} justify="start">
              {(subscriptionData?.add_ons || []).map((item: AddOn) => (
                <OverviewItem
                  key={item.id}
                  styles={{ background: '#F7F7F7', justifyContent: 'space-between', width: '32%' }}
                >
                  <Space direction="vertical">
                    <Typography.Text style={{ textTransform: 'uppercase', ...textColor }}>
                      {item.label}
                    </Typography.Text>
                    <Typography.Text style={textColor}>
                      <div dangerouslySetInnerHTML={{ __html: item.description }} />
                    </Typography.Text>
                  </Space>
                </OverviewItem>
              ))}
            </Flex>
          </Space>
        </OverviewItem>
      )}
    </Flex>
  );
};

export default SubscriptionOverview;
