import { gql } from '@apollo/client';
import { Button, Card, Flex, Form, Input, message, Space, Typography } from '@shipmnts/pixel-hub';
import React from 'react';
import { getPublicClient } from 'src/setup/configureAppolloClient';
import { useParams } from 'wouter';

const ResetPassword = () => {
  const { source } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const redirectTo =
    searchParams.get('redirect_to') ||
    process.env.REACT_APP_OPERATIONS_URL ||
    window.location.origin;

  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const [form] = Form.useForm();

  const resetUserPassword = (newPassword: string) => {
    const publicClient = getPublicClient();

    const data: any = {
      new_password: newPassword,
      token: searchParams.get('token'),
      source: source,
    };

    publicClient
      .mutate({
        variables: data,
        mutation: gql`
          mutation update_user_password($token: String!, $new_password: String!, $source: String!) {
            update_user_password(token: $token, new_password: $new_password, source: $source)
          }
        `,
      })
      .then((result: any) => {
        if (result.data?.update_user_password?.success) {
          window.location.href = redirectTo;
        }
      })
      .catch((error: any) => {
        message.error(error.message);
      });
  };

  return (
    <>
      <Flex justify="center">
        <Card
          title={
            <Typography.Title level={3} style={{ textAlign: 'center' }}>
              Reset Password
            </Typography.Title>
          }
          style={{ width: 300, marginTop: '20vh' }}
        >
          <Form
            form={form}
            onFinish={(values) => {
              resetUserPassword(values.password);
            }}
            labelAlign="left"
            colon={false}
            scrollToFirstError={true}
            layout="vertical"
          >
            <Form.Item
              label={<Space size={'small'}>Password</Space>}
              name={'password'}
              rules={[{ required: true, message: 'Please Enter Password' }]}
            >
              <Input.Password
                placeholder="Enter Password"
                minLength={8}
                visibilityToggle={{
                  visible: isPasswordVisible,
                  onVisibleChange: (visible) => {
                    setIsPasswordVisible(visible);
                  },
                }}
              />
            </Form.Item>
            <Form.Item
              label={'Confirm Password'}
              name={'confirm_password'}
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please Enter Confirm Password' },
                {
                  validator: (rule, value, callback) => {
                    if (value && value !== form.getFieldValue('password')) {
                      callback('Passwords do not match!');
                    } else {
                      callback();
                    }
                  },
                },
              ]}
            >
              <Input.Password placeholder="Enter Confirm Password" />
            </Form.Item>
            <Flex justify="center">
              <Button type="primary" htmlType="submit">
                Update Password
              </Button>
            </Flex>
          </Form>
        </Card>
      </Flex>
    </>
  );
};

export default ResetPassword;
