import React, { Suspense, useEffect } from 'react';
import { Router, Switch, Route, Link } from 'wouter';
import sessionStore from './stores/SessionStore';
import AppWrapper from './setup/AppWrapper';
import UserDetails from './components/User/UserDetails';
import Home from './pages/Home';
import { Button, FallbackSkeleton, Result } from '@shipmnts/pixel-hub';
import NotificationPreferenceSetting from './components/NotificationPreference/NotificationPreferenceSetting';
import WorkspaceRoute from './WorkspaceRoute';
import AdminRoutes from './components/common/AdminRoutes';
import PipelineForm from './components/Pipeline/PipelineForm';
import PipelineList from './pages/Pipeline';
import ResetPassword from './components/ResetPassword/ResetPassword';
import { createPublicClient } from './setup/configureAppolloClient';

const BaseLayout = () => {
  useEffect(() => {
    createPublicClient();
  }, []);
  return (
    <Router>
      <Switch>
        <Route path={'/reset-password/:source'}>
          <ResetPassword />
        </Route>
        <AppWrapper store={sessionStore}>
          <Switch>
            <Route path={'/'}>
              <Suspense fallback={<FallbackSkeleton />}>
                <Home />
              </Suspense>
            </Route>
            <Route path={'/my-profile'}>
              <UserDetails />
            </Route>

            <Route path={'/auto_email_templates'}>
              <Suspense fallback={<FallbackSkeleton />}>
                <NotificationPreferenceSetting />
              </Suspense>
            </Route>

            <WorkspaceRoute
              path={'/view/pipelines'}
              doctype={'Blueprint::Pipeline::Pipeline'}
              LayoutComponent={PipelineForm}
            >
              <PipelineList />
            </WorkspaceRoute>

            <AdminRoutes />

            {/* Fallback Route */}
            <Route>
              <Result
                status={404}
                title="Page Not Found"
                extra={
                  <Button type="primary">
                    <Link to="/">Go Home</Link>
                  </Button>
                }
              />
            </Route>
          </Switch>
        </AppWrapper>
      </Switch>
    </Router>
  );
};

export default BaseLayout;
