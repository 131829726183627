import { GET_USER_FOR_EDIT_OR_DUPLICATE } from 'src/graphQL/user';
import { Action } from './commonTypeDefs';
import { getUserActions } from '../User/UserActionHelper';
import { GET_ROLE } from 'src/graphQL/role';
import { getRoleActions } from '../Role/RoleActionsHelper';

interface helperMapValues {
  query: any;
  field_name: string;
  getActions: (data: any, navigate?: any, refetch?: any, sessionData?: any) => Action[];
}

export const helperMap: Record<string, helperMapValues> = {
  'Network::UserAccount': {
    query: GET_USER_FOR_EDIT_OR_DUPLICATE,
    field_name: 'user',
    getActions: (data, navigate, refetch, sessionData) =>
      getUserActions(data, navigate, refetch, sessionData),
  },
  'Network::Role': {
    query: GET_ROLE,
    field_name: 'role',
    getActions: (data, navigate, refetch, sessionData) =>
      getRoleActions(data, navigate, refetch, sessionData),
  },
};
