import React, { useEffect, useState } from 'react';
import {
  Col,
  Drawer,
  DrawerFooter,
  Form,
  Input,
  message,
  Row,
  Skeleton,
} from '@shipmnts/pixel-hub';
import { ROW_GUTTER } from 'src/components/common/constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_BUSINESS_VERTICAL,
  FETCH_BUSINESS_VERTICAL,
  UPDATE_BUSINESS_VERTICAL,
} from 'src/graphQL/business_vertical';
import ServiceRuleTable from './ServiceRuleTable';

export default function BusinessVerticalDrawer(props: {
  businessVerticalId?: string;
  onClose: () => void;
}) {
  const { businessVerticalId, onClose } = props;
  const [businessVertical, setBusinessVertical] = useState<any>(null);
  const [form] = Form.useForm();
  const formatBusinessVerticalService = (business_vertical: any) => {
    if (!business_vertical.fields) return [];
    const extractedKeys = Object.keys(business_vertical?.fields)
      .filter((key) => key.startsWith('services.') && business_vertical.fields[key] === true)
      .map((val) => val.split('.')[1]);
    return extractedKeys;
  };
  const removeBusinessVerticalService = (business_vertical: any) => {
    if (!business_vertical.fields) return {};
    const extractedObject = Object.keys(business_vertical?.fields)
      .filter((key) => !key.startsWith('services.') && key !== 'services')
      .reduce((acc: any, key: string) => {
        acc[key] = business_vertical?.fields[key];
        return acc;
      }, {});
    return extractedObject;
  };
  const [fetchBusinessVertical, { data, loading, error }] = useLazyQuery(FETCH_BUSINESS_VERTICAL);
  const [
    updateBusinessVertical,
    {
      data: updateBusinessVerticalData,
      loading: updateBusinessVerticalLoading,
      error: updateBusinessVerticalError,
    },
  ] = useMutation(UPDATE_BUSINESS_VERTICAL);
  const [
    createBusinessVertical,
    {
      data: createBusinessVerticalData,
      loading: createBusinessVerticalLoading,
      error: createBusinessVerticalError,
    },
  ] = useMutation(CREATE_BUSINESS_VERTICAL);

  useEffect(() => {
    if (
      updateBusinessVerticalData?.update_business_vertical ||
      createBusinessVerticalData?.create_business_vertical
    ) {
      onClose();
      let successMsg = 'Business vertical created successfully';
      if (businessVerticalId) successMsg = 'Business vertical updated successfully';
      message.success(successMsg);
    }
    if (updateBusinessVerticalError) {
      message.error(updateBusinessVerticalError.message);
    }
    if (createBusinessVerticalError) {
      message.error(createBusinessVerticalError.message);
    }
  }, [
    createBusinessVerticalData,
    createBusinessVerticalError,
    onClose,
    updateBusinessVerticalData,
    updateBusinessVerticalError,
    businessVerticalId,
  ]);

  useEffect(() => {
    if (businessVerticalId) {
      fetchBusinessVertical({
        variables: {
          id: businessVerticalId,
        },
      });
    }
  }, [businessVerticalId, fetchBusinessVertical]);

  useEffect(() => {
    if (data?.fetch_business_vertical) {
      const fetchedBusinessVertical = {
        ...data?.fetch_business_vertical,
        fields: {
          ...data?.fetch_business_vertical?.fields,
          services: formatBusinessVerticalService(data?.fetch_business_vertical),
        },
      };
      setBusinessVertical(fetchedBusinessVertical);
      form.setFieldsValue(fetchedBusinessVertical);
    }
    if (error) {
      message.error(error.message);
    }
  }, [data, error, form]);

  if (loading) {
    return <Skeleton />;
  }
  return (
    <Drawer
      open={true}
      title={
        businessVertical
          ? `Edit Business Vertical ${businessVertical?.name}`
          : 'Create Business Vertical'
      }
      onClose={onClose}
      width={'70%'}
      footer={
        <DrawerFooter
          saveText={businessVertical ? 'Save' : 'Create'}
          onSave={() => {
            form.submit();
          }}
          loading={updateBusinessVerticalLoading || createBusinessVerticalLoading}
          onClose={onClose}
        />
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={async (data) => {
          const seriveObject = form
            .getFieldValue(['fields', 'services'])
            ?.reduce((acc: any, key: string) => {
              acc[`services.${key}`] = true;
              return acc;
            }, {});
          data.fields = { ...removeBusinessVerticalService(data), ...seriveObject };
          data.services = undefined;
          console.log(data, 'daatatat');
          if (businessVerticalId) {
            updateBusinessVertical({
              variables: { id: businessVerticalId, business_vertical: data },
            });
          } else {
            createBusinessVertical({
              variables: { business_vertical: data },
            });
          }
        }}
        initialValues={{
          fields: businessVertical?.fields,
        }}
      >
        <Row gutter={ROW_GUTTER}>
          <Col span={6}>
            <Form.Item
              required
              rules={[{ required: true, message: 'Please enter Name' }]}
              name="name"
              label="Name"
            >
              <Input placeholder="Name of Business Vertical" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              required
              rules={[{ required: true, message: 'Please enter Code' }]}
              name="code"
              label="Code"
            >
              <Input disabled={!!businessVerticalId} placeholder="Code of Business Vertical" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <ServiceRuleTable form={form} />
        </Row>
      </Form>
    </Drawer>
  );
}
