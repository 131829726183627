import { Button, Dropdown } from '@shipmnts/pixel-hub';
import { BaseTable, StaticHeaderComponent } from '@shipmnts/pixel-hub';
// import { editViewSubscriptionTemplateERP } from 'operations/modules/actionHelper/TemplateSubscriptionActions/templateSubscriptionActionHelper';
import React, { useEffect, useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import DisableFinanceAutoEmailTemplate from './DisableFinanceAutoEmailTemplate';
import { erpNextAxiosCall } from 'src/setup/erpnextApiHelper';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
const fetchAutoEmail = async (payload: any) => {
  try {
    const url =
      'api/method/shipmnts.custom_script.custom_auto_email_report.custom_auto_email_report.get_templates';
    const response = await erpNextAxiosCall({
      action: 'get',
      url,
      payload: payload,
    });
    return response;
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export default function AutoEmailFinanceTemplateList() {
  const [subscriptionData, setSubscriptionData] = useState<any>([]);
  const session: SessionDataType = sessionStore.getSessionData();

  const fetchData = async () => {
    const response: any = await fetchAutoEmail({});
    setSubscriptionData(
      (response?.response?.data?.message || []).map((r: any) => ({
        ...r,
        enabled: r.enabled ? 'Enable' : 'Disable',
      }))
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columnDefs: any[] = [
    {
      headerName: 'Template Name',
      field: 'name',
      colId: 'name',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: (params: any) => {
        return (
          <a
            href={`${session.company_account.subdomain}/desk#Form/Auto%20Email%20Report/${params.value}`}
            target="_blank"
            rel="noreferrer"
          >
            {params.value}
          </a>
        );
      },
      minWidth: 180,
    },
    {
      headerName: 'Subject',
      field: 'subject',
      colId: 'subject',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Status',
      field: 'enabled',
      colId: 'enabled',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Email To',
      field: 'email_to',
      colId: 'email_to',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Schedule on',
      field: 'frequency',
      colId: 'frequency',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Day To Send',
      field: 'day_to_send',
      colId: 'day_to_send',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: (params: any) => {
        return (
          <>
            {params.data.frequency !== 'Weekly'
              ? params.value
              : params.data.week_days_selection
                  .filter((w: any) => w.is_selected)
                  .map((w: any) => w.option_value)
                  .join(', ')}
          </>
        );
      },
      minWidth: 180,
    },
    {
      headerName: 'Time To Send',
      field: 'time_to_send',
      colId: 'time_to_send',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
      cellRenderer: (params: any) => {
        return <>{`${params.value}:00 Hrs`}</>;
      },
    },
    {
      headerName: 'Created By',
      field: 'owner',
      colId: 'owner',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Created At',
      field: 'creation',
      colId: 'creation',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
      cellRenderer: (params: any) => {
        return (
          <>
            {new Date(params.value).toLocaleTimeString('en-US', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </>
        );
      },
    },
    {
      headerName: 'Actions',
      lockPosition: 'right',
      suppressMovable: true,
      colId: 'actions',
      field: 'actions',
      valueGetter: 'data',
      width: 100,
      pinned: 'right',
      headerComponent: 'static_header_component',
      suppressNavigable: true,
      cellRenderer: (params: any) => {
        return (
          <Dropdown
            menu={{
              style: { minWidth: '200px' },
              className: 'actions-menu',
              items: [
                {
                  key: '1',
                  label: (
                    <a
                      href={`${session.company_account.subdomain}/desk#Form/Auto%20Email%20Report/${params.data.name}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Edit Email Template
                    </a>
                  ),
                },
                {
                  key: '2',
                  label: (
                    <DisableFinanceAutoEmailTemplate
                      onClose={fetchData}
                      template_name={params.data.name}
                      enabled={params.data.enabled}
                    />
                  ),
                },
              ],
            }}
            trigger={['click']}
          >
            <Button size="small">
              <MoreOutlined rotate={90} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const components = {
    static_header_component: StaticHeaderComponent,
  };

  return (
    <div>
      <BaseTable
        reportName="auto_email_subcription_list_3"
        columns={columnDefs}
        rowData={subscriptionData || []}
        reportConfig={{
          components: components,
          rowHeight: 40,
        }}
        height={'70vh'}
      />
    </div>
  );
}
