import React, { useRef, useState } from 'react';
import { Link } from 'wouter';
import { AppHelmet, Button, Flex, GlobalViewLayout, Layout } from '@shipmnts/pixel-hub';
import { getActionColDef } from 'src/components/common/ActionRenderer';

const getGlobalView = () => {
  const globalView: any = {
    id: '1',
    name: 'Role',
    color: '#eeeeee',
    type: 'grid',
    doc_type_id: 'Network::Role',
    definition: {
      sort: [
        {
          field_id: 'roles_created_at',
          direction: 'desc',
        },
      ],
      fields: [
        {
          id: 'roles_role_name',
          label: 'Role',
          width: 300,
          field_type: 'Link',
          section: 'Basic',
          sortable: true,
          searchable: true,
          filterable: true,
          cell_renderer: {
            args: {
              id_field: 'id',
              doc_type_id: 'Network::Role',
            },
            function: 'link_render',
          },
        },
        {
          id: 'roles_description',
          label: 'Description',
          width: 250,
          field_type: 'String',
          section: 'Basic',
        },
        {
          id: 'roles_created_at',
          label: 'Created At',
          width: 200,
          field_type: 'DateTime',
          section: 'Basic',
          sortable: true,
          filterable: true,
        },
        {
          id: 'roles_created_by_id',
          label: 'Created By',
          width: 200,
          field_type: 'Search',
          section: 'Basic',
          sortable: true,
          filterable: true,
          groupable: true,
          cell_renderer: { args: {}, function: 'record_loader_render' },
          record_loader_config: { doc_type: 'Network::UserAccount', attribute: 'name' },
          searchable: true,
          filter_options: JSON.stringify({
            doc_type: 'Network::UserAccount',
            attribute: 'name',
          }),
        },
        {
          id: 'roles_updated_at',
          label: 'Updated At',
          width: 200,
          field_type: 'DateTime',
          section: 'Basic',
          sortable: true,
          filterable: true,
        },
        {
          id: 'roles_updated_by_id',
          label: 'Updated By',
          width: 200,
          field_type: 'Search',
          section: 'Basic',
          sortable: true,
          filterable: true,
          groupable: true,
          cell_renderer: { args: {}, function: 'record_loader_render' },
          record_loader_config: { doc_type: 'Network::UserAccount', attribute: 'name' },
          searchable: true,
          filter_options: JSON.stringify({
            doc_type: 'Network::UserAccount',
            attribute: 'name',
          }),
        },
      ],
      filters: [],
    },
    standard_report: true,
    is_disabled: false,
    global: true,
  };
  return globalView;
};

const { Content } = Layout;

const Roles = () => {
  const globalView = useRef(getGlobalView());
  const title = 'Roles';

  const [refetch, setRefetch] = useState(0);
  const handleRefetch = () => {
    setRefetch((prev) => prev + 1);
  };
  return (
    <Layout>
      <AppHelmet>
        <title>{title}</title>
      </AppHelmet>
      <Flex justify="end" style={{ marginBottom: '16px', marginTop: '16px' }}>
        <Link to={'~/form/role/new'}>
          <Button type="primary">Create Role</Button>
        </Link>
      </Flex>
      <Content>
        <GlobalViewLayout
          key={refetch}
          defaultFilters={[]}
          doc_type_id={'Network::Role'}
          globalView={globalView.current}
          height="70vh"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore //todo: fix type for extraColDefs
          extraColDefs={[getActionColDef('Network::Role', handleRefetch)]}
          label="Role"
        />
      </Content>
    </Layout>
  );
};

export default Roles;
