import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'wouter';
import { useLazyQuery, useMutation } from '@apollo/client';
import { isEqual, pick } from 'lodash';
import {
  AppHelmet,
  Button,
  Col,
  CopyOutlined,
  Drawer,
  EditOutlined,
  Flex,
  Form,
  Input,
  Modal,
  PageHeader,
  Row,
  Typography,
  WarningOutlined,
  message,
} from '@shipmnts/pixel-hub';
import { GET_ROLE, GET_ROLE_WITH_ASSIGNED_USERS, UPSERT_ROLE } from '../../graphQL/role';
import {
  getAllCombinedPermissionsFromRoleObject,
  hasAtLeastOneTrueValue,
} from 'src/helpers/helpers';
import PermissionMatrix from './TransactionPermissionMatrix';
import LoadingSpinner from '../common/LoadingSpinner';
import UserList from '../User/UserList';

const RoleForm = () => {
  const { 1: navigate } = useLocation();
  const { role_id } = useParams();

  const [form] = Form.useForm();
  const [role, setRole] = useState<any>(window.history?.state?.role);
  const [fetchRoleLoading, setFetchRoleLoading] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [userDrawerOpen, openUserDrawer] = useState(false);
  const inEditMode = role_id !== 'new';

  const [getRole, { data: getRoleData, error: getRoleError }] = useLazyQuery(
    inEditMode ? GET_ROLE_WITH_ASSIGNED_USERS : GET_ROLE
  );

  const [
    upsertRolePermission,
    { data: upsertRoleData, loading: upsertRoleLoading, error: upsertRoleError },
  ] = useMutation(UPSERT_ROLE);

  useEffect(() => {
    if (inEditMode || window.history?.state?.role_id) {
      setFetchRoleLoading(true);
      getRole({
        variables: {
          id: role_id === 'new' ? window.history?.state?.role_id : role_id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role_id]);

  useEffect(() => {
    const getErpData = async () => {
      if (getRoleError) {
        message.error('Error while fetching role!');
      } else if (getRoleData) {
        setRole(getRoleData.role);
        setFetchRoleLoading(false);
      }
    };
    if (role_id) getErpData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoleData, getRoleError]);

  useEffect(() => {
    if (upsertRoleError) {
      message.error('Error while updating role');
    } else if (upsertRoleData?.upsert_role?.id) {
      const msg = `Role ${inEditMode ? 'Updated' : 'Created'} Successfully`;
      message.success(msg);
      if (role_id) {
        window.history.back();
      } else {
        navigate(`~/view/roles`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, upsertRoleData, upsertRoleError]);

  const getInitialValuesOfRole = useCallback(() => {
    if (role) {
      const permissions = getAllCombinedPermissionsFromRoleObject(role);
      form.setFieldsValue({
        role_name: role?.role_name || '',
        description: role?.description || '',
        permissions: permissions,
      });
    }
    return {};
  }, [form, role]);

  useEffect(() => {
    getInitialValuesOfRole();
  }, [getInitialValuesOfRole, role]);

  const getUpdatedPermissionsOnly = useCallback(
    (permissions: any) => {
      if (inEditMode) {
        const prevPermissions = getAllCombinedPermissionsFromRoleObject(role);
        return permissions.filter((permission: any) => {
          const prevPermission = prevPermissions.find(
            (perm: any) => perm.doc_type === permission.doc_type
          );
          if (prevPermission) {
            return !isEqual(
              pick(prevPermission, ['is_collaborator', 'types']),
              pick(permission, ['is_collaborator', 'types'])
            );
          } else {
            return hasAtLeastOneTrueValue(permission.types) || permission.is_collaborator;
          }
        });
      } else {
        return permissions
          .filter(
            (permission: any) =>
              permission.is_collaborator || hasAtLeastOneTrueValue(permission.types)
          )
          .map((permission: any) => {
            return {
              ...permission,
              id: undefined,
            };
          });
      }
    },
    [inEditMode, role]
  );

  const submitForm = () => {
    const values = form.getFieldsValue();
    let permissions = getUpdatedPermissionsOnly(values.permissions || []);
    permissions = permissions.reduce((acc: any, item: any) => {
      if (item.doc_type) {
        if (item.is_erpnext_permission && item.id) {
          /* Using _destroy to avoid duplicate entry 
          because same permission will get passed 
          from ops (previously stored with ops id) and fin (new entry without id). */
          acc.push({ ...item, _destroy: true });
        } else {
          acc.push(item);
        }
      }
      return acc;
    }, []);
    if (!inEditMode && permissions?.length === 0) {
      message.error('Please add some permissions');
      return;
    }
    const payload = {
      id: inEditMode ? role_id : undefined,
      role_name: values.role_name,
      description: values.description,
      permissions: permissions,
    };
    upsertRolePermission({ variables: { role: payload } });
  };

  if (fetchRoleLoading) {
    return <LoadingSpinner loadingMessage="Loading Role Data..." />;
  }

  if (upsertRoleLoading) {
    return <LoadingSpinner loadingMessage={inEditMode ? 'Updating Role' : 'Creating new Role'} />;
  }

  const title = !inEditMode
    ? window.history?.state?.role_id
      ? `Duplicate ${role?.role_name}`
      : 'Create new role'
    : `Edit ${role?.role_name}`;

  return (
    <>
      <Modal
        open={isConfirmationModalOpen}
        title={
          <div
            style={{
              color: 'var(--primary-grey)',
              fontWeight: 500,
            }}
          >
            <WarningOutlined style={{ color: 'orange' }} />
            <span style={{ marginLeft: '5px' }}>
              <b>Permission for below users will be changed!!</b>
            </span>
          </div>
        }
        onCancel={() => setIsConfirmationModalOpen(false)}
        okText={'Submit'}
        okButtonProps={{
          onClick: () => submitForm(),
        }}
      >
        <UserList users={getRoleData?.role?.assigned_users} />
      </Modal>
      <Drawer
        open={userDrawerOpen}
        title={
          <div
            style={{
              color: 'var(--primary-grey)',
              fontWeight: 500,
            }}
          >
            <WarningOutlined style={{ color: 'orange' }} />
            <span style={{ marginLeft: '5px' }}>
              <b>Users assigned this role</b>
            </span>
          </div>
        }
        onClose={() => openUserDrawer(false)}
      >
        <UserList users={getRoleData?.role?.assigned_users} />
      </Drawer>
      <Flex vertical gap={15} style={{ padding: '15px' }}>
        <AppHelmet>
          <title>{title}</title>
        </AppHelmet>
        <PageHeader
          className="fixed-header"
          onBack={() => {
            window.history.back();
          }}
          title={
            <Typography.Title level={5} style={{ marginBottom: 0, marginRight: '10px' }}>
              {!inEditMode ? (
                window.history.state?.role_id ? (
                  <CopyOutlined />
                ) : (
                  <EditOutlined />
                )
              ) : (
                <EditOutlined />
              )}
              <span style={{ marginLeft: '10px' }}>{title}</span>
            </Typography.Title>
          }
          extra={[
            inEditMode && (
              <Button
                style={{ color: 'var(--ant-color-primary)', marginLeft: '40rem' }}
                onClick={() => openUserDrawer(true)}
              >
                Users assigned this role
              </Button>
            ),
          ]}
        />
        <Form
          form={form}
          onFinish={(values) => {
            if (inEditMode && getRoleData?.role?.assigned_users?.length) {
              setIsConfirmationModalOpen(true);
            } else {
              submitForm();
            }
          }}
          labelAlign="left"
          colon={false}
          scrollToFirstError={true}
          layout="vertical"
        >
          <Flex gap={30} vertical>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label={'Role Name'}
                  name={'role_name'}
                  rules={[{ required: true, message: 'Please enter role name' }]}
                  style={{ marginBottom: 0 }}
                >
                  <Input placeholder="Name role as per your department and designation" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={'Role Description'}
                  name={'description'}
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true, message: 'Please enter role description' }]}
                >
                  <Input.TextArea
                    placeholder="Enter a meaningful description for this role"
                    autoSize
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name={'permissions'}>
              <PermissionMatrix />
            </Form.Item>
          </Flex>

          <Flex
            justify="flex-end"
            gap={10}
            style={{
              position: 'absolute',
              bottom: '0',
              right: '0',
              width: '100%',
              background: '#FFFFFF',
              padding: '10px 24px',
              borderTop: '1px solid #E5E5E5',
              paddingTop: '12px',
            }}
          >
            <Button
              onClick={() => {
                navigate('~/view/roles');
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {inEditMode ? 'Update Role' : 'Create Role'}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </>
  );
};

export default RoleForm;
