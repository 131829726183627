import { cloneDeep as _cloneDeep, omit as _omit, pick as _pick } from 'lodash';
import { axiosInstance } from 'src/setup/axiosDefaults';

export const permissionJsonToArrayMapper = (
  permissions: any,
  defaultPermissions: any,
  allocatedOnly = false,
  transaction_permission_keys = [
    'create',
    'view',
    'edit',
    'delete',
    'list',
    'read',
    'write',
    'submit',
    'cancel',
    'amend',
    'report',
    'print',
    'import',
  ]
) => {
  return _cloneDeep(defaultPermissions)
    .map((per: any) => {
      const updatedPermission = permissions.find((p: any) => p.doc_type === per.doc_type);

      if (updatedPermission) {
        per.types = { ...per.types, ..._pick(updatedPermission.types, Object.keys(per.types)) };
        per.is_collaborator = updatedPermission.is_collaborator;
      }

      return {
        id: per.id,
        is_collaborator: per.is_collaborator,
        doc_type: per.doc_type || '',
        label: per.label || '',
        is_erpnext_permission: per.is_erpnext_permission || false,
        included_reports: per.included_reports || [],
        transaction_permission: _pick(per.types, transaction_permission_keys),
        action_permission: _omit(per.types, transaction_permission_keys),
      };
    })
    .filter(
      (per: any) =>
        !allocatedOnly ||
        hasAtLeastOneTrueValue(per.transaction_permission) ||
        hasAtLeastOneTrueValue(per.action_permission)
    )
    .sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
};

export const hasAtLeastOneTrueValue = (types: any) => {
  for (const key in types) {
    if (types[key]) return true;
  }
  return false;
};

export const getAllCombinedPermissionsFromRoleObject = (role: any) => {
  return [...(role?.permissions || []), ...JSON.parse(role?.erp_permissions || '[]')].map(
    (perm: any) => {
      return {
        id: perm.id,
        doc_type: perm.doc_type,
        is_collaborator: perm.is_collaborator,
        is_erpnext_permission: perm.is_erpnext_permission,
        types: typeof perm.types === 'string' ? JSON.parse(perm.types) : perm.types,
      };
    }
  );
};

export const getGeneratedPassword = async () => {
  return await axiosInstance.get(process.env.REACT_APP_GLOBAL_API_URL + '/generate_password');
};

export const amountRendererWithCurrency = (props: {
  value: number;
  currency: string;
  precision?: number;
}) => {
  const number = Number(props.value || 0);
  const numberFormat = props.currency === 'INR' ? 'en-IN' : undefined;
  const amountStr = number.toLocaleString(numberFormat, {
    minimumFractionDigits: props.precision || 3,
    style: 'currency',
    currency: props.currency,
    currencyDisplay: 'code',
  });
  return amountStr;
};

export const getFilteredBusinessVerticals = (bvs: any[]) => {
  return (bvs || []).filter((bv) => !bv.is_disabled);
};
