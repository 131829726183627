import {
  Button,
  Col,
  Modal,
  EditOutlined,
  DeleteOutlined,
  Form,
  Input,
  Row,
  message,
  LoadingOutlined,
  Space,
} from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import {
  UPSERT_COMPANY_GROUP,
  FETCH_COMPANY_GROUP,
  DELETE_COMPANY_GROUP,
  DISABLE_COMPANY_GROUP,
} from 'src/graphQL/company_group';
import { useLazyQuery, useMutation } from '@apollo/client';

const CompanyGroupForm = (props: any) => {
  const { visible, setVisible, action } = props;
  const updateMode = action === 'update';
  const deleteMode = action === 'delete';
  const disableEnableMode = ['disable', 'enable'].includes(action);
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const company_group_id = props.id;
  const [createCompanyGroup, { data: dataCreate, loading: loadingCreate, error: errorCreate }] =
    useMutation(UPSERT_COMPANY_GROUP);
  const [disableCompanyGroup, { data: dataDisable, loading: loadingDisable, error: errorDisable }] =
    useMutation(DISABLE_COMPANY_GROUP);
  const [deleteCompanyGroup, { data: dataDelete, loading: loadingDelete, error: errorDelete }] =
    useMutation(DELETE_COMPANY_GROUP);
  const [fetchCompanyGroup, { data: dataFetch, loading: loadingFetch, error: errorFetch }] =
    useLazyQuery(FETCH_COMPANY_GROUP);

  useEffect(() => {
    if (company_group_id) {
      fetchCompanyGroup({
        variables: {
          id: company_group_id,
        },
      });
      if (dataFetch) {
        form.setFieldsValue(dataFetch.company_groups);
      }
      if (errorFetch) {
        message.error('Failed to fetch company group');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_group_id, dataFetch]);
  useEffect(() => {
    if (errorCreate) {
      message.error('Failed to create the company group');
    } else if (dataCreate) {
      message.success(
        `Company Group '${dataCreate.upsert_company_group.name}' ${updateMode ? 'updated' : 'created'} successfully.`
      );
      setVisible(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreate, loadingCreate, errorCreate]);

  useEffect(() => {
    const data = deleteMode ? dataDelete?.delete_company_group : dataDisable?.disable_company_group;
    if (errorDelete || errorDisable) {
      message.error(`Failed to ${action} the company group`);
    } else if (dataDelete || dataDisable) {
      message.success(
        `Company group '${data.name}' ${deleteMode ? 'deleted' : 'updated'} successfully.`
      );
      setVisible(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDelete, dataDisable, loadingDelete, loadingDisable, errorDelete, errorDisable]);

  const onClose = () => {
    if (setVisible) setVisible(false);
  };

  const label = (label: string) => {
    return (
      <label
        style={{
          fontWeight: 'bold',
          color: 'inherit',
        }}
      >
        {label}
      </label>
    );
  };
  const upsertCompanyGroup = async (name: string) => {
    try {
      if (updateMode) await tableForm.validateFields();
      createCompanyGroup({
        variables: {
          company_group: {
            name,
            company_group_id,
          },
        },
      });
    } catch (err) {
      message.error('Failed to save the company group');
    }
  };
  const removeCompanyGroup = (name: string) => {
    try {
      deleteCompanyGroup({
        variables: {
          company_group: {
            name,
            company_group_id,
          },
        },
      });
    } catch (err) {
      message.error('Failed to delete the company group');
    }
  };

  const disableEnableCompanyGroup = (name: string, is_disabled: boolean) => {
    try {
      disableCompanyGroup({
        variables: {
          company_group: {
            name,
            company_group_id,
            is_disabled,
          },
        },
      });
    } catch (err) {
      message.error('Failed to disable/enable the company group');
    }
  };

  const FormItems = () => {
    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={async (data) => {
          upsertCompanyGroup(data.name);
        }}
      >
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label={label('Company Group Name')}
              name={'name'}
              rules={[{ required: true, message: 'Please enter Company Group Name' }]}
            >
              <Input
                value={updateMode && dataFetch ? dataFetch?.company_groups.name : ''}
                placeholder="Name of the Company Group"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const getTitle = () => {
    if (updateMode || disableEnableMode)
      return (
        <>
          <EditOutlined /> Update
        </>
      );
    if (deleteMode)
      return (
        <>
          <DeleteOutlined /> Delete
        </>
      );
    return 'Create';
  };
  const ModalContent = () => {
    const loading = loadingFetch || loadingCreate || loadingDelete || loadingDisable;
    return (
      <>
        {loading ? (
          <LoadingOutlined />
        ) : deleteMode || disableEnableMode ? (
          <>
            Are you sure you want to {action} <b>{props?.name}</b> ?
          </>
        ) : (
          <FormItems />
        )}
      </>
    );
  };
  return (
    <>
      <div style={{ padding: 'var(--ant-padding-lg)' }}>
        <Modal
          title={getTitle()}
          onCancel={onClose}
          open={visible}
          width={'725px'}
          footer={
            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
              <Button key="cancel" onClick={onClose}>
                Cancel
              </Button>

              {deleteMode || disableEnableMode ? (
                <Button
                  style={{ marginLeft: '3px' }}
                  type="primary"
                  key="delete_company_group"
                  onClick={() => {
                    deleteMode
                      ? removeCompanyGroup(props.name)
                      : disableEnableCompanyGroup(props.name, !props.is_disabled);
                  }}
                  disabled={loadingDelete}
                >
                  {getTitle()}
                </Button>
              ) : (
                <Button
                  style={{ marginLeft: '3px' }}
                  type="primary"
                  key="create_company_group"
                  onClick={() => {
                    form.submit();
                  }}
                  disabled={loadingCreate}
                >
                  {getTitle()}
                </Button>
              )}
            </Space>
          }
        >
          <ModalContent />
        </Modal>
      </div>
    </>
  );
};

function getActionsLabel(action: string) {
  switch (action) {
    case 'delete':
      return (
        <>
          <DeleteOutlined /> Delete
        </>
      );
    case 'disable':
      return (
        <>
          <EditOutlined /> Disable
        </>
      );
    case 'enable':
      return (
        <>
          <EditOutlined /> Enable
        </>
      );
  }
  return '';
}

export const CompanyGroupActionModal = (props: any) => {
  const [visible, setVisible] = useState(false);
  return !visible ? (
    <span onClick={() => setVisible(true)}>{getActionsLabel(props.action)}</span>
  ) : (
    <CompanyGroupForm
      {...props}
      visible={visible}
      setVisible={setVisible}
      onClose={() => setVisible(false)}
    />
  );
};

export default CompanyGroupForm;
