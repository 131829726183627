import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  BaseTable,
  AutoEmailTemplateForm,
  StaticHeaderComponent,
  Dropdown,
  MoreOutlined,
  Button,
} from '@shipmnts/pixel-hub';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import StatusUpdateViewSubscriptionTemplate from './StatusUpdateViewSubscriptionTemplate';
import EditAutoEmailSubscriptionTemplate from './EditAutoEmailSubscriptionTemplate';
import { startCase } from 'lodash';

const FETCH_SUBSCRIPTION_TEMPLATE_BY_USER = gql`
  query view_subscription_template_by_user {
    view_subscription_template_by_user {
      id
      name
      frequency
      schedule_on
      day_frequency
      time_frequency
      email_definition
      last_activity_status
      default_aggregation_field_id
      status
      linked_to_resource_id
      created_at
      linked_to_resource_type
      default_filter_field_id
      recipients {
        first_name
        last_name
        email
      }
      cc_emails {
        first_name
        last_name
        email
      }
      created_by {
        id
        username
        first_name
        last_name
      }
      extra_view_definition
      view {
        id
        definition
        name
        workspace {
          id
        }
      }
    }
  }
`;

const EditSubscriptionTemplateRender = (props: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const viewSubscriptionTemplateID = props?.node?.data?.id;
  const session: SessionDataType = sessionStore.getSessionData();

  const sameUser = session?.id === props?.node?.data?.created_by?.id;
  return (
    <>
      {sameUser ? (
        <div
          onClick={() => {
            setIsVisible(true);
          }}
          style={{ color: 'var(--brand-burnt-red)', cursor: 'pointer' }}
        >
          {props.value}
        </div>
      ) : (
        <>{props.value}</>
      )}
      {isVisible && (
        <AutoEmailTemplateForm
          key={viewSubscriptionTemplateID}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          onSuccess={() => {
            props?.refetchData();
          }}
          viewSubscriptionTemplateId={viewSubscriptionTemplateID}
        />
      )}
    </>
  );
};

export default function AutoEmailSubscriptionTemplateList() {
  const { data, refetch } = useQuery(FETCH_SUBSCRIPTION_TEMPLATE_BY_USER);

  const viewData = (data?.view_subscription_template_by_user || []).map((t: any) => {
    return {
      ...t,
      created_by_name: t.created_by.first_name + ' ' + t.created_by.last_name,
      view: t?.view,
      subject: JSON.parse(t.email_definition).subject,
      created_by: t.created_by,
      schedule_on: t.schedule_on,
      time_frequency: t.time_frequency.map((tf: number) => tf + ':00 Hrs'),
      created_at: t.created_at,
      status: t.status,
      template: t,
    };
  });
  const columnDefs: any[] = [
    {
      headerName: 'Template Name',
      field: 'name',
      colId: 'name',
      cellRenderer: 'name_render',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
      cellRendererParams: {
        refetchData: refetch,
      },
    },
    {
      headerName: 'Report View',
      field: 'view',
      colId: 'view',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
      cellRenderer: (params: any) => {
        return (
          <a
            href={`${process.env.REACT_APP_OPERATIONS_URL}/workspace/${params?.value?.workspace?.id}/view/${params?.value?.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {params.value?.name}
          </a>
        );
      },
    },
    {
      headerName: 'Subject',
      field: 'subject',
      colId: 'subject',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Status',
      field: 'status',
      colId: 'status',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
      cellRenderer: (params: any) => {
        return startCase(params.value);
      },
    },
    {
      headerName: 'Schedule On',
      field: 'schedule_on',
      colId: 'schedule_on',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Time Frequency',
      field: 'time_frequency',
      colId: 'time_frequency',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Created By',
      field: 'created_by_name',
      colId: 'created_by_name',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      colId: 'created_at',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
      cellRenderer: (params: any) => {
        return (
          <>
            {new Date(params.value * 1000).toLocaleTimeString('en-US', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </>
        );
      },
    },
    {
      headerName: 'Actions',
      lockPosition: 'right',
      suppressMovable: true,
      colId: 'actions',
      field: 'actions',
      valueGetter: 'data',
      width: 100,
      pinned: 'right',
      headerComponent: 'static_header_component',
      suppressNavigable: true,
      cellRenderer: (params: any) => {
        return (
          <Dropdown
            menu={{
              style: { minWidth: '200px' },
              className: 'actions-menu',
              items: [
                {
                  key: '1',
                  label: (
                    <EditAutoEmailSubscriptionTemplate
                      onClose={() => refetch()}
                      viewSubscriptionTemplate={params.data.template}
                    />
                  ),
                },
                {
                  key: '2',
                  label: (
                    <StatusUpdateViewSubscriptionTemplate
                      onClose={() => refetch()}
                      viewSubscriptionTemplate={params.data.template}
                    />
                  ),
                },
              ],
            }}
            trigger={['click']}
          >
            <Button size="small">
              <MoreOutlined rotate={90} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const components = {
    static_header_component: StaticHeaderComponent,
    name_render: EditSubscriptionTemplateRender,
  };

  return (
    <div>
      <BaseTable
        reportName="auto_email_subcription_list_3"
        columns={columnDefs}
        rowData={viewData || []}
        reportConfig={{
          components: components,
          rowHeight: 40,
        }}
        height={'70vh'}
      />
    </div>
  );
}
