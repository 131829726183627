import { useMutation } from '@apollo/client';
import {
  Button,
  Descriptions,
  Divider,
  Flex,
  Form,
  InputNumber,
  Layout,
  MinusOutlined,
  PlusOutlined,
  Typography,
  message,
} from '@shipmnts/pixel-hub';
import React, { useEffect, useMemo } from 'react';
import { BUY_SEAT } from 'src/graphQL/subscription';

const rightAlignFullWidth: React.CSSProperties = {
  textAlign: 'right',
  width: '100%',
};

type BuySeatsFormProps = {
  seat: any;
  onFinish: () => void;
};

const SeatInput = (props: any) => {
  const { form, value, onChange } = props;
  return (
    <>
      <Flex gap={5} justify="center">
        <Button
          onClick={() => {
            const value = form.getFieldValue('seat_to_add');
            if (value === 1) return;
            form.setFieldsValue({ seat_to_add: value - 1 });
          }}
        >
          <MinusOutlined />
        </Button>
        <InputNumber
          onChange={onChange}
          type="number"
          value={value}
          className="seat_input"
          style={{ width: '30%' }}
          precision={0}
        />
        <Button
          onClick={() => {
            const value = form.getFieldValue('seat_to_add') || 1;
            form.setFieldsValue({ seat_to_add: value + 1 });
          }}
        >
          <PlusOutlined />
        </Button>
      </Flex>
      <Flex gap={5} justify="center" style={{ marginTop: '1rem' }}>
        <Button
          onClick={() => {
            const value = form.getFieldValue('seat_to_add') || 1;
            form.setFieldsValue({ seat_to_add: value + 5 });
          }}
          size="small"
        >
          <PlusOutlined /> 5
        </Button>
        <Button
          onClick={() => {
            const value = form.getFieldValue('seat_to_add') || 1;
            form.setFieldsValue({ seat_to_add: value + 10 });
          }}
          size="small"
        >
          <PlusOutlined /> 10
        </Button>
      </Flex>
    </>
  );
};

const BuySeatsForm = (props: BuySeatsFormProps) => {
  const { onFinish, seat } = props;
  const [addSeat, { loading, data, error }] = useMutation(BUY_SEAT);
  const [form] = Form.useForm();
  const seatToAdd = Form.useWatch('seat_to_add', form) || 0;

  const seat_description = useMemo(
    () => [
      {
        key: 1,
        label: 'Price/Seat/Month',
        children: <span style={rightAlignFullWidth}>{seat.price}</span>,
      },
      {
        key: 3,
        label: 'No. of Seats',
        children: <span style={rightAlignFullWidth}>{seatToAdd}</span>,
      },
      {
        key: 4,
        label: <span style={{ fontWeight: 500, fontSize: '14px', color: '#141415' }}>Total</span>,
        children: (
          <span
            style={{
              ...rightAlignFullWidth,
              color: '#141415',
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '18px',
            }}
          >
            {seatToAdd * seat.price}
          </span>
        ),
      },
    ],
    [seatToAdd, seat.price]
  );

  useEffect(() => {
    if (error) {
      message.error('Error while updating seat');
    } else if (!error && data) {
      message.success('Seats Updated Successfully');
      onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const validatePositiveNumber = (_: any, value: any) => {
    if (value >= 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Number of seats cannot be negative'));
  };

  return (
    <Layout>
      <Typography.Title
        level={5}
        style={{ color: 'var(--secondary-black-color)', textAlign: 'center', marginBottom: '1rem' }}
      >
        Buy Seats
      </Typography.Title>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          seat_to_add: 1,
        }}
        onFinish={(values) => {
          addSeat({
            variables: {
              id: seat.ops_seat_id,
              seat_to_add: values.seat_to_add,
            },
          });
        }}
      >
        <Form.Item
          name={'seat_to_add'}
          rules={[
            { required: true, message: 'Please Enter Number of Seats' },
            { validator: validatePositiveNumber },
          ]}
        >
          <SeatInput form={form} />
        </Form.Item>
        <Divider style={{ marginBottom: 8 }} />
        <Form.Item>
          <Descriptions column={1} colon={false}>
            {seat_description.map((item) => (
              <Descriptions.Item key={item.key} label={item.label}>
                {item.children}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Form.Item>
        <Button
          key={1}
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
          loading={loading}
        >
          Send Request
        </Button>
      </Form>
    </Layout>
  );
};

export default BuySeatsForm;
