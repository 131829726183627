export const sessionKey = `${process.env.REACT_APP_WORKOS_CLIENT_ID}::session`;

export const PRIMARY_TYPE = 'primary';
export const SECONDARY_TYPE = 'secondary';
export const TERTIARY_TYPE = 'tertiary';
export const NEGATIVE_TYPE = 'negative';
export const ROW_GUTTER = 16;
export const FREIGHT_TYPE_AIR = 'air';
export const FREIGHT_TYPE_OCEAN = 'ocean';
export const FREIGHT_TYPE_ROAD = 'road';
export const TRADE_TYPE_EXPORT = 'export';
export const TRADE_TYPE_IMPORT = 'import';
export const TRADE_TYPE_DOMESTIC = 'domestic';
export const TRADE_TYPE_CROSS_TRADE = 'cross_trade';
export const FREIGHT_TYPES = [
  {
    key: FREIGHT_TYPE_AIR,
    name: 'Air',
  },
  { key: FREIGHT_TYPE_OCEAN, name: 'Ocean' },
  { key: FREIGHT_TYPE_ROAD, name: 'Road' },
];

export const TRADE_TYPES = [
  {
    key: TRADE_TYPE_IMPORT,
    name: 'Import',
  },
  {
    key: TRADE_TYPE_EXPORT,
    name: 'Export',
  },
  {
    key: TRADE_TYPE_DOMESTIC,
    name: 'Domestic',
  },
  {
    key: TRADE_TYPE_CROSS_TRADE,
    name: 'Cross Trade',
  },
];

export const LOAD_TYPE_OPTIONS: any = {
  [FREIGHT_TYPE_AIR]: [
    {
      label: 'Loose',
      value: 'loose',
    },
    {
      label: 'ULD',
      value: 'uld',
    },
  ],
  [FREIGHT_TYPE_OCEAN]: [
    {
      label: 'FCL',
      value: 'fcl',
    },
    {
      label: 'LCL',
      value: 'lcl',
    },
  ],
  [FREIGHT_TYPE_ROAD]: [
    {
      label: 'FCL',
      value: 'fcl',
    },
    {
      label: 'FTL - Break Bulk',
      value: 'breakbulk',
    },
    {
      label: 'FTL - Bulk',
      value: 'bulk',
    },
    {
      label: 'FTL - Liquid',
      value: 'liquid',
    },
    {
      label: 'LTL',
      value: 'ltl',
    },
  ],
};

export const SERVICES = [
  {
    key: 'freight_forwarding',
    name: 'Freight Forwarding',
    display_name: 'Freight Forwarding',
  },
  {
    key: 'origin_custom_clearance',
    name: 'Origin Custom Clearance',
    display_name: 'Origin Custom Clearance',
  },
  {
    key: 'origin_transport',
    name: 'Origin Transport',
    display_name: 'Origin Transport',
  },
  {
    key: 'destination_transport',
    name: 'Destination Transport',
    display_name: 'Destination Transport',
  },
  {
    key: 'live_tracking',
    name: 'Live Tracking',
    display_name: 'Live Tracking',
  },
  {
    key: 'cargo_insurance',
    name: 'Cargo Insurance',
    display_name: 'Cargo Insurance',
  },
  {
    key: 'palletization',
    name: 'Palletization',
    display_name: 'Palletization',
  },
  {
    key: 'fumigation',
    name: 'Fumigation',
    display_name: 'Fumigation',
  },
  {
    key: 'warehouse',
    name: 'Warehouse',
    display_name: 'Warehouse',
  },
  {
    key: 'empty_container_insurance',
    name: 'Empty Container Insurance',
    display_name: 'Empty Container Insurance',
  },
  {
    key: 'destination_custom_clearance',
    name: 'Destination Custom Clearance',
    display_name: 'Destination Custom Clearance',
  },
];

export const PERMISSION_ESTIMATES_TOTAL = 'margin';
export const PERMISSION_SYSTEM_ADMINISTRATION_EDIT = 'edit';
export const PERMISSION_SYSTEM_ADMINISTRATION_CREATE = 'create';
export const PERMISSION_SYSTEM_ADMINISTRATION_DELETE = 'delete';
export const PERMISSION_SYSTEM_ADMINISTRATION_LIST = 'list';

export const DEPARTMENTS = [
  'Air Documentation',
  'Pricing',
  'Ocean Documentation',
  'Customer Service',
  'Sales',
  'Billing',
  'Purchase',
  'CSR',
  'Accounting',
  'Human Resources',
  'Branch Management',
  'Overall Management',
  'Project Management',
];
