import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { AppHelmet } from '@shipmnts/pixel-hub';
import { useLocation } from 'wouter';

const Home = (): JSX.Element => {
  const { 1: navigate } = useLocation();
  useEffect(() => {
    navigate('~/my-profile');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AppHelmet>
        <title>Home</title>
      </AppHelmet>
    </>
  );
};

export default observer(Home);
