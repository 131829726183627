import { Button, Card, Tabs, TabsProps, Typography } from '@shipmnts/pixel-hub';
import React, { useState } from 'react';
import { AppHelmet, AutoEmailTemplateForm } from '@shipmnts/pixel-hub';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import AutoEmailSubscriptionTemplateList from './AutoEmailSubscriptionTemplateList';
import AutoEmailFinanceTemplateList from './AutoEmailFinanceTemplateList';

export default function NotificationPreferenceSetting() {
  const [isVisible, setIsVisible] = useState(false);
  const [refetchList, setRefetchList] = useState(false);
  const session: SessionDataType = sessionStore.getSessionData();
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'DSR Setting',
      children: (
        <Card
          // title="Auto Email"
          key="auto_email"
          size="small"
          extra={[
            <Button
              // loading={}
              key="save"
              onClick={() => setIsVisible(true)}
              type="primary"
              style={{ marginLeft: '12px' }}
            >
              Create Auto Email Template
            </Button>,
          ]}
        >
          <div>
            <AutoEmailSubscriptionTemplateList key={refetchList ? 1 : 0} />
          </div>
        </Card>
      ),
    },
    {
      key: '2',
      label: 'Finance Setting',
      children: (
        <Card
          // title="Auto Email"
          key="auto_email"
          size="small"
          extra={[
            <Button
              // loading={}
              key="save"
              onClick={() => {
                window.open(
                  `${session.company_account.subdomain}/desk#Form/Auto%20Email%20Report/New%20Auto%20Email%20Report%201?is_template=1`,
                  '_blank'
                );
              }}
              type="primary"
              style={{ marginLeft: '12px' }}
            >
              Create Auto Email Template
            </Button>,
          ]}
        >
          <div>
            <AutoEmailFinanceTemplateList />
          </div>
        </Card>
      ),
    },
  ];
  const title = 'Notification Preference Settings';
  return (
    <div style={{ padding: '0 0 15px 15px' }}>
      <AppHelmet>
        <title>{title}</title>
      </AppHelmet>
      <div>
        <Typography.Text style={{ fontSize: '18px', color: 'var(--ant-color-primary)' }}>
          Notification Preference Settings
        </Typography.Text>
      </div>
      <div style={{ marginTop: '5px', width: '100%' }}>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
      {isVisible && (
        <AutoEmailTemplateForm
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          onClose={() => setIsVisible(false)}
          onSuccess={() => {
            setIsVisible(false);
            setRefetchList(!refetchList);
          }}
        />
      )}
    </div>
  );
}
