import { gql } from '@apollo/client';

export const UPSERT_USER_ACCOUNT = gql`
  mutation upsert_user_account($user: UserAccountInputType!) {
    upsert_user_account(user: $user) {
      id
      first_name
    }
  }
`;

export const CREATE_USER_ACCOUNT = gql`
  mutation create_user_account($user: UserAccountInputType!) {
    create_user_account(user: $user) {
      id
      first_name
    }
  }
`;

export const UPDATE_USER_ACCOUNT = gql`
  mutation update_user_account($id: ID!, $user: UserAccountInputType!) {
    update_user_account(id: $id, user: $user) {
      id
      first_name
    }
  }
`;

export const DISABLE_USER_ACCOUNT = gql`
  mutation disable_user_account($id: ID!, $disabled: Boolean!) {
    disable_user_account(id: $id, disabled: $disabled) {
      id
      first_name
    }
  }
`;

export const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      mobile_number
      first_name
      last_name
      department
      user_level
      title
      personal_email
      created_at
      disabled
      branch_accounts {
        id
        name
      }
      business_verticals {
        id
        name
        fields
        is_disabled
      }
      created_by {
        id
        first_name
        last_name
      }
      role {
        id
        role_name
      }
      permission_tags
    }
  }
`;

export const GET_USER_FOR_EDIT_OR_DUPLICATE = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      mobile_number
      first_name
      last_name
      department
      user_level
      title
      personal_email
      disabled
      branch_accounts {
        id
        name
      }
      business_verticals {
        id
        name
        fields
        is_disabled
      }
      role {
        id
        role_name
      }
      permission_tags
    }
  }
`;

export const GET_USER_ACCOUNTS = gql`
  query user_account_search($organization_id: ID!, $query: String!) {
    user_account_search(organization_id: $organization_id, query: $query) {
      department
      email
      first_name
      id
      name
      last_name
      mobile_number
    }
  }
`;

export const GET_ACTIVE_USER_SUMMARY = gql`
  query get_active_user_summary {
    get_active_user_summary
  }
`;

export const GENERATE_RANDOM_PASSWORD = gql`
  query generate_random_password {
    generate_random_password
  }
`;

export const RESET_PASSWORD = gql`
  mutation reset_password($id: ID!, $new_password: String!) {
    reset_password(id: $id, new_password: $new_password) {
      id
      first_name
    }
  }
`;
