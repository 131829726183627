import React, { Suspense } from 'react';
import { FallbackSkeleton } from '@shipmnts/pixel-hub';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import { Route } from 'wouter';
import WorkspaceRoute from 'src/WorkspaceRoute';
import CustomFields from '../CustomFields/CustomFields';
import SubscriptionAndBilling from 'src/pages/Subscription/SubscriptionAndBilling';
import Roles from 'src/pages/Role/Roles';
import RoleDetails from '../Role/RoleDetails';
import Users from 'src/pages/User/Users';
import UserDetails from '../User/UserDetails';
import RoleForm from '../Role/RoleForm';
import UserForm from 'src/pages/User/UserForm';
import CompanyGroupList from '../CompanyGroup/CompanyGroupList';
import CompanyGroupForm from '../CompanyGroup/CompanyGroupForm';
import ActionRendererReportNew from './ActionRendererNew';
import BusinessVerticalView from 'src/pages/BusinessVertical/BusinessVerticalView';

const AdminRoutes = () => {
  const sessionData: SessionDataType = sessionStore.getSessionData();

  return (
    <>
      {sessionData.user_level === 'admin' && (
        <>
          <Route path={'/form/user/:user_id'}>
            <Suspense fallback={<FallbackSkeleton />}>
              <UserForm />
            </Suspense>
          </Route>
          <Route path={'/view/user/:user_id'}>
            <Suspense fallback={<FallbackSkeleton />}>
              <UserDetails />
            </Suspense>
          </Route>
          <Route path={'/form/role/:role_id'}>
            <Suspense fallback={<FallbackSkeleton />}>
              <RoleForm />
            </Suspense>
          </Route>
          <Route path={'/view/role/:role_id'}>
            <Suspense fallback={<FallbackSkeleton />}>
              <RoleDetails />
            </Suspense>
          </Route>

          <Route path={'/view/subscription'}>
            <Suspense fallback={<FallbackSkeleton />}>
              <SubscriptionAndBilling />
            </Suspense>
          </Route>

          <Route path={'/view/business_verticals'}>
            <Suspense fallback={<FallbackSkeleton />}>
              <BusinessVerticalView />
            </Suspense>
          </Route>

          <WorkspaceRoute
            path={'/view/users'}
            doctype={'Network::UserAccount'}
            LayoutComponent={UserDetails}
            LINK_MAP={(doc_type_id: string, id: string, extrafield: any) => {
              if (!id) return;
              if (doc_type_id === 'Network::UserAccount') {
                return `/view/user/${id}`;
              }
              if (doc_type_id === 'Network::Role') {
                return `/view/role/${id}`;
              }
              return;
            }}
            extraProps={{
              extraDocTypesForMethods: ['Network::Role'],
              extraDependentComponents: {
                ActionRendererReport: ActionRendererReportNew as any,
              },
              extraLayoutComponents: {
                'Network::Role': RoleDetails as any,
              },
            }}
          >
            <Users />
          </WorkspaceRoute>
          <WorkspaceRoute
            path={'/view/roles'}
            doctype={'Network::Role'}
            LayoutComponent={RoleDetails}
            LINK_MAP={(doc_type_id: string, id: string, extrafield: any) => {
              if (!id) return;
              if (doc_type_id === 'Network::Role') {
                return `/view/role/${id}`;
              }
              return;
            }}
            extraProps={{
              extraDependentComponents: {
                ActionRendererReport: ActionRendererReportNew as any,
              },
            }}
          >
            <Roles />
          </WorkspaceRoute>

          <WorkspaceRoute
            path={'/view/custom_fields'}
            doctype={'Blueprint::CustomField'}
            LayoutComponent={CustomFields}
          >
            <CustomFields />
          </WorkspaceRoute>

          <WorkspaceRoute
            path={'/view/company_groups'}
            doctype={'Network::CompanyGroup'}
            LayoutComponent={CompanyGroupForm}
          >
            <CompanyGroupList />
          </WorkspaceRoute>
        </>
      )}
    </>
  );
};

export default AdminRoutes;
