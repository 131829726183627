import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Layout, ActivityContextProvider } from '@shipmnts/pixel-hub';
import { axiosInstance } from './axiosDefaults';

const { Content } = Layout;

export interface ApplicationContentContextType {
  config_data: any;
  configDataError: any;
  fetchingConfigData: boolean;
  fetchConfigData?: () => void;
}

export const ApplicationContentContext = React.createContext<ApplicationContentContextType>({
  config_data: undefined,
  configDataError: undefined,
  fetchConfigData: undefined,
  fetchingConfigData: false,
});
export const useApplicationContentContext = () => useContext(ApplicationContentContext);

export interface ApplicationLayoutContentProps {
  children: React.ReactNode;
}
const ApplicationLayoutContent = React.memo(function ApplicationLayoutContent(
  props: ApplicationLayoutContentProps
): JSX.Element {
  const { children } = props;
  const [configData, setConfigData] = useState<any>(undefined);
  const [fetchingConfigData, setFetchingConfigData] = useState<boolean>(false);
  const [configDataError, setConfigDataError] = useState<string | undefined>();

  const fetchConfigData = useCallback(async () => {
    setFetchingConfigData(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_GLOBAL_API_URL}/config_data`
      );
      if (response?.data?.config_data) {
        setConfigData(response.data.config_data);
      }
    } catch (e) {
      console.error(e);
      const error: any = e;
      setConfigDataError(error?.message);
      setFetchingConfigData(false);
    }
    setFetchingConfigData(false);
  }, []);

  useEffect(() => {
    fetchConfigData();
  }, [fetchConfigData]);

  return (
    <Layout hasSider style={{ height: '100%' }}>
      <Content>
        <ActivityContextProvider>
          <ApplicationContentContext.Provider
            value={{
              config_data: configData,
              configDataError,
              fetchingConfigData,
              fetchConfigData,
            }}
          >
            {children}
          </ApplicationContentContext.Provider>
        </ActivityContextProvider>
      </Content>
    </Layout>
  );
});

export default ApplicationLayoutContent;
