import { gql } from '@apollo/client';

export const GET_USER_PROFILE = gql`
  query user_profile {
    user_profile {
      id
      first_name
      last_name
      email
      mobile_number
      department
      user_level
      branch_accounts {
        id
        name
      }
      business_verticals {
        id
        name
        fields
        is_disabled
      }
      company_account {
        id
        subdomain
        registered_name
        seats {
          billing_seats
          tenant_id
        }
        total_active_users_count
        country_of_incorporation
        default_company {
          id
          registered_name
        }
      }
      tenant_id
      role {
        id
        role_name
        permissions {
          id
          permission_name
          types
          doc_type
        }
      }
    }
  }
`;
