import { gql } from '@apollo/client';

export const roleMinimumFields = gql`
  fragment roleMinimumFields on RoleObjectType {
    id
    role_name
    description
    permissions {
      id
      doc_type
      types
      is_collaborator
    }
    erp_permissions
    created_by {
      id
      first_name
      last_name
    }
  }
`;

export const GET_ALL_DOCTYPES_PERMISSIONS = gql`
  query get_all_doctype_permissions {
    get_all_doctype_permissions
  }
`;

export const GET_ROLE = gql`
  query role($id: ID!) {
    role(id: $id) {
      ...roleMinimumFields
    }
  }
  ${roleMinimumFields}
`;

export const GET_ROLE_WITH_ASSIGNED_USERS = gql`
  query role($id: ID!) {
    role(id: $id) {
      ...roleMinimumFields
      assigned_users {
        id
        first_name
        last_name
        email
      }
    }
  }
  ${roleMinimumFields}
`;

export const UPSERT_ROLE = gql`
  mutation upsert_role($role: RoleInputType!) {
    upsert_role(role: $role) {
      id
      role_name
    }
  }
`;

export const GET_ROLES = gql`
  query roles {
    roles {
      id
      role_name
    }
  }
`;
