import React, { useEffect, useState } from 'react';
import { useParams } from 'wouter';
import { useQuery } from '@apollo/client';
import {
  Flex,
  Layout,
  Typography,
  message,
  PageHeader,
  AppHelmet,
  Button,
  DownOutlined,
  Col,
  Row,
} from '@shipmnts/pixel-hub';
import { GET_ROLE_WITH_ASSIGNED_USERS } from 'src/graphQL/role';
import LoadingSpinner from '../common/LoadingSpinner';
import PermissionMatrix from './TransactionPermissionMatrix';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import { getAllCombinedPermissionsFromRoleObject } from 'src/helpers/helpers';
import { ActionRenderer } from '../common/ActionRendererNew';
import { AssignedUserSection } from './AssignedUserSection';
import ErrorPage from '../common/ErrorPage';

const RoleDetails = (props: { id?: string }) => {
  let { role_id } = useParams();
  role_id = props.id || role_id;
  const [permissions, setPermissions] = useState<any>([]);
  const { loading, error, data } = useQuery(GET_ROLE_WITH_ASSIGNED_USERS, {
    variables: {
      id: role_id,
    },
  });
  const SessionData: SessionDataType = sessionStore.getSessionData();

  useEffect(() => {
    setPermissions(getAllCombinedPermissionsFromRoleObject(data?.role));
  }, [data]);

  if (loading) {
    return <LoadingSpinner loadingMessage="Loading Role" />;
  }

  if (error) {
    message.error('Error while loading role data!');
    return <ErrorPage title="Something went wrong!" />;
  }

  return (
    <Layout style={{ padding: '1rem' }}>
      <AppHelmet>
        <title>{`Role | ${data.role.role_name}`}</title>
      </AppHelmet>
      <Flex vertical gap={12}>
        <PageHeader
          ghost={false}
          title={data.role.role_name}
          extra={
            SessionData.user_level === 'admin' && (
              <ActionRenderer id={data?.role?.id} doc_type_id="Network::Role" data={data?.role}>
                <Button className="default-bordered-button">
                  Actions <DownOutlined />
                </Button>
              </ActionRenderer>
            )
          }
        >
          <Typography.Paragraph>{data.role?.description || ''}</Typography.Paragraph>
        </PageHeader>

        <Row gutter={5}>
          <Col span={18}>
            <PermissionMatrix role_id={data?.role?.id} value={permissions} readonly={true} />
          </Col>
          <Col span={6}>
            <AssignedUserSection
              users={data?.role?.assigned_users}
              role_name={data?.role?.role_name}
            />
          </Col>
        </Row>
      </Flex>
    </Layout>
  );
};

export default RoleDetails;
