import { CopyOutlined, CustomIcon, DeleteOutlined, CustomFieldForm } from '@shipmnts/pixel-hub';
import CustomFieldDelete from './CustomFieldDelete';
import React, { useState } from 'react';

export const EditCustomField = (props: any): any => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <div onClick={() => setVisible(true)}>
        <CustomIcon height="14" icon="Edit" width="15" style={{ marginBottom: '-2px' }} /> Edit
      </div>
      {visible && (
        <CustomFieldForm
          onClose={() => setVisible(false)}
          visible={visible}
          // setVisible={setVisible}
          id={props?.data?.id}
          onSuccess={props?.refetchReport}
        />
      )}
    </>
  );
};

export const DuplicateCustomField = (props: any): any => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <div onClick={() => setVisible(true)}>
        <CopyOutlined /> Duplicate
      </div>
      {visible && (
        <CustomFieldForm
          onClose={() => setVisible(false)}
          visible={visible}
          // setVisible={setVisible}
          id={props?.data?.id}
          isDuplicate={true}
          onSuccess={props?.refetchReport}
        />
      )}
    </>
  );
};

export const DeleteCustomField = (props: any): any => {
  const [visible, setVisible] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean | undefined>(true);

  const handleDelete = () => {
    setVisible(true);
    setOpen(true);
  };

  return (
    <>
      <div onClick={handleDelete} style={{ color: '#e94f08' }}>
        <DeleteOutlined /> Delete
      </div>
      <CustomFieldDelete
        visible={visible}
        open={open}
        setOpen={setOpen}
        id={props?.data?.id}
        refetchReport={props?.refetchReport}
      />
    </>
  );
};
