/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from 'react';
import { Navbar, NAVBAR_HEIGHT } from 'shipmnts_navigation';
import sessionStore from 'src/stores/SessionStore';
import { axiosInstance } from 'src/setup/axiosDefaults';

function ApplicationHeader(props) {
  const { setNavJson } = props;
  const session = sessionStore.getSessionData();

  useEffect(() => {
    const user = {
      id: session?.id,
      firstName: session?.first_name,
      lastName: session?.last_name,
      email: session?.email,
      company_account: session?.company_account,
    };
    new Navbar({
      rootNodeId: 'header',
      user: user,
      appName: 'manage',
      authClientId: process.env.REACT_APP_WORKOS_CLIENT_ID || '',
      devMode: process.env.REACT_APP_WORKOS_DEVMODE === 'true' || false,
      logout: () => {
        sessionStore.setSessionData({});
      },
      links: {},
      axiosInstance: axiosInstance,
      changeRoute: (route) => {
        window.open(` ${process.env.REACT_APP_OPERATIONS_URL}${route}`, '_self');
      },
      getHubspotToken: async () => {
        try {
          const response = await axiosInstance.get(
            `${process.env.NETWORK_API_URL}/user_accounts/get_hubspot_token`
          );
          return response.data.token;
        } catch (e) {
          console.error(e);
        }
        return;
      },
      callback: (navjson) => {
        setNavJson(navjson);
      },
      configVars: {
        shipmntsWebUrl: process.env.REACT_APP_SHIPMNTS_WEB_URL,
        operationsUrl: process.env.REACT_APP_OPERATIONS_URL,
        firebaseApplicationConfig: JSON.parse(
          process.env.REACT_APP_FIREBASE_APPLICATION_CONFIG || '{}'
        ),
        alexUrl: process.env.REACT_APP_ALEX_URL,
        workspace_product_fruit_code: process.env.REACT_APP_WORKSPACE_PRODUCT_FRUIT_CODE,
        firebaseVapidKey:
          process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_FIREBASE_VAPID_KEY : '',
      },
    });
    // todo: need to develop a user search component for universal search
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return (
    <>
      <div style={{ marginBottom: NAVBAR_HEIGHT }} id="header" />
    </>
  );
}

export default ApplicationHeader;
