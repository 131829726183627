import { useMutation } from '@apollo/client';
import {
  Button,
  Descriptions,
  Divider,
  Flex,
  Form,
  InputNumber,
  Layout,
  MinusOutlined,
  PlusOutlined,
  Typography,
  message,
} from '@shipmnts/pixel-hub';
import React, { useEffect, useMemo } from 'react';
import { REDUCE_SEAT } from 'src/graphQL/subscription';

const rightAlignFullWidth: React.CSSProperties = {
  textAlign: 'right',
  width: '100%',
};

type ReduceSeatsFormProps = {
  seat: any;
  onFinish: () => void;
};

const SeatInput = (props: any) => {
  const { form, value, onChange, maxAllowed } = props;
  return (
    <>
      <Flex gap={5} justify="center">
        <Button
          onClick={() => {
            const value = form.getFieldValue('seat_to_reduce');
            if (value === 1) return;
            form.setFieldsValue({ seat_to_reduce: value - 1 });
          }}
        >
          <MinusOutlined />
        </Button>
        <InputNumber
          onChange={onChange}
          type="number"
          value={value}
          className="seat_input"
          style={{ width: '30%' }}
          precision={0}
        />
        <Button
          onClick={() => {
            const value = form.getFieldValue('seat_to_reduce') || 1;
            if (value === maxAllowed) return;
            form.setFieldsValue({ seat_to_reduce: value + 1 });
          }}
        >
          <PlusOutlined />
        </Button>
      </Flex>
      <Flex gap={5} justify="center" style={{ marginTop: '1rem' }}>
        <Button
          onClick={() => {
            form.setFieldsValue({ seat_to_reduce: maxAllowed });
          }}
          size="small"
          style={{ fontSize: '13px' }}
        >
          All Unused Seats
        </Button>
      </Flex>
    </>
  );
};

const ReduceSeatsForm = (props: ReduceSeatsFormProps) => {
  const { onFinish, seat } = props;
  const [reduceSeat, { loading, data, error }] = useMutation(REDUCE_SEAT);
  const [form] = Form.useForm();

  const seat_description = useMemo(
    () => [
      {
        key: 1,
        label: 'Total Seats',
        children: <span style={rightAlignFullWidth}>{seat.quantity}</span>,
      },
      {
        key: 2,
        label: 'Unutilised Seats',
        children: <span style={rightAlignFullWidth}>{seat.quantity - seat.current_seats}</span>,
      },
    ],
    [seat]
  );

  useEffect(() => {
    if (!error && data) {
      message.success('Seats Updated Successfully');
      onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const validatePositiveNumber = (_: any, value: any) => {
    if (value >= 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Number of seats cannot be negative'));
  };

  return (
    <Layout>
      <Typography.Title
        level={5}
        style={{ color: 'var(--secondary-black-color)', textAlign: 'center', marginBottom: '1rem' }}
      >
        Reduce Seats
      </Typography.Title>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          seat_to_reduce: 1,
        }}
        onFinish={(values) => {
          reduceSeat({
            variables: {
              id: seat.ops_seat_id,
              seat_to_reduce: values.seat_to_reduce,
            },
          });
        }}
      >
        <Form.Item
          name={'seat_to_reduce'}
          rules={[
            { required: true, message: 'Please Enter Number of Seats to reduce' },
            { validator: validatePositiveNumber },
          ]}
        >
          <SeatInput form={form} maxAllowed={seat.quantity - seat.current_seats} />
        </Form.Item>
        <Divider style={{ marginBottom: 8 }} />
        <Form.Item>
          <Descriptions column={1} colon={false}>
            {seat_description.map((item) => (
              <Descriptions.Item key={item.key} label={item.label}>
                {item.children}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Form.Item>
        <Button
          key={1}
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
          loading={loading}
        >
          Send Request
        </Button>
      </Form>
    </Layout>
  );
};

export default ReduceSeatsForm;
