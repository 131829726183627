import { useMutation, useQuery } from '@apollo/client';
import { message, Popconfirm } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { FETCH_BUSINESS_VERTICAL, UPDATE_BUSINESS_VERTICAL } from 'src/graphQL/business_vertical';

export default function BusinessVerticalDisable(props: {
  businessVerticalId?: string;
  onClose: () => void;
}) {
  const { businessVerticalId, onClose } = props;
  const [visible, setVisible] = useState(false);
  const { data, loading } = useQuery(FETCH_BUSINESS_VERTICAL, {
    variables: { id: businessVerticalId },
  });
  const [
    updateBusinessVertical,
    {
      data: updateBusinessVerticalData,
      loading: updateBusinessVerticalLoading,
      error: updateBusinessVerticalError,
    },
  ] = useMutation(UPDATE_BUSINESS_VERTICAL);

  useEffect(() => {
    if (updateBusinessVerticalData?.update_business_vertical) {
      message.success('Business vertical updated successfully');
      onClose();
    } else if (updateBusinessVerticalError) {
      message.error(updateBusinessVerticalError.message);
      onClose();
    }
  }, [onClose, updateBusinessVerticalData, updateBusinessVerticalError]);

  return (
    <>
      <Popconfirm
        key="business_vertical_disable"
        title="Are you sure you want to disable business verticals"
        onConfirm={() => {
          updateBusinessVertical({
            variables: {
              id: businessVerticalId,
              business_vertical: {
                is_disabled: !data?.fetch_business_vertical?.is_disabled,
              },
            },
          });
        }}
        onCancel={onClose}
        okText="Yes"
        cancelText="No"
        open={visible}
        destroyTooltipOnHide
        placement="topRight"
        okButtonProps={{
          loading: loading || updateBusinessVerticalLoading,
          disabled: loading || updateBusinessVerticalLoading || !data?.fetch_business_vertical,
        }}
      />
      <div
        onClick={() => {
          setVisible(true);
        }}
      >
        {data?.fetch_business_vertical?.is_disabled ? 'Enable' : 'Disable'} Business Vertical
      </div>
    </>
  );
}
