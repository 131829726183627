import React, { useRef, useState } from 'react';
import { AppHelmet, Button, Flex, GlobalViewLayout } from '@shipmnts/pixel-hub';
import PipelineForm from '../../components/Pipeline/PipelineForm';
import { getActionColDef } from 'src/components/common/ActionRenderer';

const getGlobalView = () => {
  const globalView: any = {
    id: '1',
    name: 'All Pipelines',
    color: '#F6F7F7',
    type: 'grid',
    doc_type_id: 'Blueprint::Pipeline::Pipeline',
    definition: {
      sort: [],
      fields: [
        {
          id: 'blueprint_pipelines_name',
          label: 'Pipeline Name',
          width: 250,
          field_type: 'String',
          section: 'Basic',
          seachable: true,
          filterable: true,
          cell_renderer: {
            args: {
              id_field: 'id',
              doc_type_id: 'Blueprint::Pipeline::Pipeline',
            },
            function: 'link_render',
          },
        },
        {
          id: 'blueprint_pipelines_doctype',
          label: 'Object',
          field_type: 'Dropdown',
          filter_options: JSON.stringify({
            values: ['Network::Company', 'ServiceManagement::Ticket::Ticket'],
            display_hash: {
              'Network::Company': 'Company',
              'ServiceManagement::Ticket::Ticket': 'Ticket',
            },
          }),
          section: 'Basic',
          filterable: true,
          searchable: true,
        },
        {
          id: 'blueprint_pipelines_description',
          label: 'Description',
          field_type: 'String',
          section: 'Basic',
          filterable: true,
          searchable: true,
        },
        {
          id: 'blueprint_pipelines_created_by_id',
          label: 'Created By',
          field_type: 'Search',
          use_record_loader: true,
          record_loader_config: { doc_type: 'Network::UserAccount', attribute: 'name' },
          cell_renderer: { args: {}, function: 'record_loader_render' },
          section: 'Basic',
          groupable: true,
          searchable: true,
          filterable: true,
          filter_options: JSON.stringify({
            doc_type: 'Network::UserAccount',
            attribute: 'name',
          }),
          sortable: true,
        },
        {
          id: 'blueprint_pipelines_is_active',
          label: 'Is Active',
          field_type: 'Boolean',
          use_record_loader: false,
          record_loader_config: {},
          cell_renderer: {},
          section: 'Basic',
          groupable: true,
          searchable: false,
          filterable: true,
          filter_options: {},
          sortable: true,
        },
      ],
      filters: [],
    },
    standard_report: true,
    is_disabled: false,
    global: true,
  };
  return globalView;
};

const PipelineList = () => {
  const [visible, setVisible] = useState(false);
  const [refetch, setRefetch] = useState<number>(0);
  const globalView = useRef(getGlobalView());
  const title = 'Pipelines';
  const refetchRows = () => setRefetch((prev) => prev + 1);
  return (
    <>
      <AppHelmet>
        <title>{title}</title>
      </AppHelmet>
      <Flex justify="end" style={{ marginBottom: '5px' }}>
        <Button type="primary" onClick={() => setVisible(true)}>
          Create Pipeline
        </Button>
      </Flex>
      <GlobalViewLayout
        key={`${refetch}`}
        defaultFilters={[]}
        globalView={globalView.current}
        doc_type_id={'Blueprint::Pipeline::Pipeline'}
        height="80vh"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore //todo: fix type for extraColDefs
        extraColDefs={[getActionColDef('Blueprint::Pipeline::Pipeline', refetchRows)]}
      />
      {visible && (
        <PipelineForm
          visible={visible}
          setVisible={(l: boolean) => {
            setRefetch((prev) => prev + 1);
            setVisible(l);
          }}
        />
      )}
    </>
  );
};

export default PipelineList;
