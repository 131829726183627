import React, { useMemo } from 'react';
import { Button, DownOutlined, Drawer, Tag, Typography } from '@shipmnts/pixel-hub';
import UserRoleDetails from './UserRoleDetails';
import Description from '../common/Description';
import sessionStore, { SessionDataType } from 'src/stores/SessionStore';
import { ActionRenderer } from '../common/ActionRendererNew';
// import dayjs from 'dayjs';

const Overview = ({ userData }: any) => {
  const [visible, setVisible] = React.useState(false);
  const { user } = userData;

  const SessionData: SessionDataType = sessionStore.getSessionData();

  const user_overview_details = useMemo(
    () =>
      [
        {
          key: 1,
          label: 'Branches',
          children: (
            <div>
              {user?.branch_accounts?.map((branch: any) => (
                <Tag style={{ margin: '0 3px 3px 0', fontSize: '13px' }} key={branch.id}>
                  {branch.name}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          key: 3,
          label: 'Current Role',
          children: (
            <Typography.Link
              style={{ color: 'var(--ant-color-primary)' }}
              onClick={() => setVisible(true)}
            >
              {user?.role?.role_name}
            </Typography.Link>
          ),
        },
        {
          key: 4,
          label: 'Business Verticals',
          children: (
            <div>
              {user?.business_verticals?.map((business_vertical: any) => (
                <Tag style={{ margin: '0 3px 3px 0', fontSize: '13px' }} key={business_vertical.id}>
                  {business_vertical.name}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          key: 2,
          label: 'Authority Tags',
          children: (
            <div>
              {user?.permission_tags?.map((tag: any) => (
                <Tag style={{ margin: '0 3px 3px 0', fontSize: '13px' }} key={tag}>
                  {tag}
                </Tag>
              ))}
            </div>
          ),
          hidden: user?.permission_tags?.length === 0,
        },
        // {
        //   key: 4,
        //   label: 'Last Login At',
        //   children: (
        //     <span>
        //       {user?.auth0_user_data?.last_login
        //         ? dayjs(user?.auth0_user_data?.last_login).format('DD MMM YYYY, h:mm a')
        //         : 'No login yet'}
        //     </span>
        //   ),
        // },
        // {
        //   key: 5,
        //   label: 'Last Login Network',
        //   children: <span>{user?.auth0_user_data?.last_ip ?? 'No login yet'}</span>,
        // },
        // {
        //   key: 6,
        //   label: 'Login Counts',
        //   children: <span>{user?.auth0_user_data?.logins_count ?? 0}</span>,
        // },
      ].filter((item) => !item.hidden),
    [user]
  );

  return (
    <>
      <Drawer
        title={`Permissions for ${user?.role?.role_name} Role`}
        placement="right"
        width={1200}
        open={visible}
        onClose={() => setVisible(false)}
        extra={
          SessionData.user_level === 'admin' && (
            <ActionRenderer id={user?.role?.id} doc_type_id="Network::Role" data={user?.role}>
              <Button className="default-bordered-button">
                Actions <DownOutlined />
              </Button>
            </ActionRenderer>
          )
        }
      >
        <UserRoleDetails role_id={user?.role?.id} />
      </Drawer>
      <Description items={user_overview_details} style={{ padding: '10px 0', width: '700px' }} />
    </>
  );
};

export default Overview;
