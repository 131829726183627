import React, { useEffect, useState } from 'react';
import {
  Col,
  Flex,
  Form,
  FormInstance,
  GlobalSearch,
  message,
  Row,
  Select,
  Skeleton,
} from '@shipmnts/pixel-hub';
import RoleSearch from '../common/RoleSearch';
import PermissionTagTable from './PermissionTagTable';
import { useQuery } from '@apollo/client';
import { FETCH_ALL_BUSINESS_VERTICALS } from 'src/graphQL/business_vertical';
import { getFilteredBusinessVerticals } from 'src/helpers/helpers';

const PermissionDetailsForm = ({
  forceUpdateKey,
  form,
}: {
  forceUpdateKey: number;
  form: FormInstance;
}) => {
  const [verticalOptions, setVerticalOptions] = useState<any>([]);
  const formVerticals = Form.useWatch('business_vertical_ids', form);

  const colProps = {
    span: 12,
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
  };
  const { data, loading, error } = useQuery(FETCH_ALL_BUSINESS_VERTICALS);

  useEffect(() => {
    if (data?.fetch_business_verticals) {
      const enabledVerticals = getFilteredBusinessVerticals(data?.fetch_business_verticals) || [];
      const options = [
        {
          value: 'select_all',
          label: 'Select All',
        },
      ];
      enabledVerticals.forEach((bv) => {
        options.push({
          value: bv.id,
          label: bv.name,
        });
      });

      setVerticalOptions(options);
    }
    if (error) {
      message.error(error.message);
    }
  }, [data, error]);

  useEffect(() => {
    setVerticalOptions((prevOptions: any) => {
      const formValue = formVerticals || [];
      const isAllSelected = formValue.length === (prevOptions?.length || 0) - 1;

      return prevOptions?.map((option: any) =>
        option.value === 'select_all'
          ? { ...option, label: isAllSelected ? 'Deselect All' : 'Select All' }
          : option
      );
    });
  }, [formVerticals]);

  const onSelectValueChange = (value: string, option: any) => {
    const isSelectAll = value === 'select_all';
    const options = verticalOptions || [];
    const formValue = formVerticals || [];

    const updatedFormValue = isSelectAll
      ? formValue.length === options.length - 1
        ? []
        : options.filter((bv: any) => bv.value !== 'select_all').map((bv: any) => bv.value)
      : [...formValue, value];

    form.setFieldValue('business_vertical_ids', updatedFormValue);
  };

  if (loading) return <Skeleton />;
  return (
    <Flex vertical gap={10}>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item
            label={'Branch Permissions'}
            name={'branch_account_ids'}
            rules={[{ required: true, message: 'Please select one branch at least' }]}
          >
            <GlobalSearch doc_type="Network::BranchAccount" selectMode="multiple" />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please select one Business Vertical at least',
              },
            ]}
            label={'Business Vertical Permissions'}
            name={'business_vertical_ids'}
          >
            <Select
              mode={'multiple'}
              showSearch
              options={verticalOptions}
              loading={loading}
              onSelect={onSelectValueChange}
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label={'Workflow and Authority'} name={'permission_tags'}>
        <PermissionTagTable key={forceUpdateKey} />
      </Form.Item>

      <Form.Item
        label={'Role'}
        name={'role_id'}
        rules={[{ required: true, message: 'Please select one role at least' }]}
      >
        <RoleSearch />
      </Form.Item>
    </Flex>
  );
};

export default PermissionDetailsForm;
