import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Table,
  TableProps,
  message,
} from '@shipmnts/pixel-hub';
import React, { useEffect, useMemo, useState } from 'react';
import { createNewPermissionTag } from 'src/helpers/erpnextApis';
import { erpNextAxiosCall } from 'src/setup/erpnextApiHelper';

interface PermissionRowType {
  key: string;
  tag: string;
  description: string;
}

const PermissionTagTable = (props: any) => {
  const [tag_form] = Form.useForm();
  const { value = [], onChange } = props;
  const [permissionTagOptions, setPermissionTagOptions] = useState<any[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const updatePermissionTags = (data: any) => {
    const tags = data
      .filter((tag: { tag: string; description: string }) => tag.tag !== 'Frozen Accounts Modifier')
      .map((tag: { tag: string; description: string }) => ({
        tag: tag.tag,
        description: tag.description || tag.tag,
      }));
    setPermissionTagOptions(tags);
  };

  const fetchErpNextPermissionTags = async () => {
    const url = `/api/resource/Permission Tag`;

    try {
      const result = await erpNextAxiosCall({
        action: 'get',
        url,
        params: { fields: JSON.stringify(['tag', 'description']) },
      });
      const { response, error }: any = result;
      if (response?.data?.data.length > 0) {
        updatePermissionTags(response?.data?.data);
      }

      if (error) {
        message.error(error);
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  const handleChange = (e: any, record: any) => {
    let tags = value || [];
    if (e.target.checked) {
      tags.push(record.tag);
    } else {
      tags = tags.filter((tag: string) => tag !== record.tag);
    }
    onChange(tags);
  };

  const columns: TableProps<PermissionRowType>['columns'] = useMemo(
    () => [
      {
        title: 'Tag',
        dataIndex: 'tag',
        key: 'tag',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (record) => {
          const exists = value.find((tag: any) => tag === record.tag);
          return (
            <>
              <Checkbox
                key={record.tag}
                checked={exists}
                onChange={(e) => handleChange(e, record)}
              />{' '}
              <span>Access</span>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  useEffect(() => {
    fetchErpNextPermissionTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTag = async () => {
    tag_form
      .validateFields()
      .then(async () => {
        const { response } = await createNewPermissionTag(tag_form.getFieldsValue());
        if (response?.data?.message.success) {
          tag_form.resetFields();
          setShowCreateModal(false);
          const newTag = response?.data?.message.tag;
          const updatedTags: any = [
            ...permissionTagOptions,
            { tag: newTag.tag, description: newTag.description },
          ];
          setPermissionTagOptions(updatedTags);
        } else {
          message.error('Error while creating new tag');
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Modal
        open={showCreateModal}
        title="Create New Authority Tags"
        onCancel={() => setShowCreateModal(false)}
        onOk={createTag}
      >
        <Form form={tag_form} labelAlign="left" colon={false} layout="vertical">
          <Form.Item
            name={'tag'}
            label={'Authority Tag'}
            required
            rules={[{ required: true, message: 'Please enter a tag' }]}
          >
            <Input placeholder="Tag" />
          </Form.Item>
          <Form.Item
            name={'description'}
            label={'Tag Description'}
            required
            rules={[{ required: true, message: 'Please enter some description about tag' }]}
          >
            <Input placeholder="Tag Description" />
          </Form.Item>
        </Form>
      </Modal>
      <Table pagination={false} columns={columns} dataSource={permissionTagOptions} />
      <Button
        type="primary"
        size="small"
        style={{ marginTop: '8px' }}
        onClick={() => setShowCreateModal(true)}
      >
        Add New Tag
      </Button>
    </>
  );
};

export default PermissionTagTable;
