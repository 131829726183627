import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { message, Popconfirm } from '@shipmnts/pixel-hub';
import { DELETE_CUSTOM_FIELD } from 'src/graphQL/custom_field';

interface CustomFieldDeleteProps {
  id: string;
  isVisible?: boolean;
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  open?: boolean;
  setOpen?: any;
  refetchReport: () => void;
}

function CustomFieldDelete(props: CustomFieldDeleteProps) {
  const { id, onSuccess, visible, onClose, open, setOpen, refetchReport } = props;
  const [deleteCustomField, { data, error }] = useMutation(DELETE_CUSTOM_FIELD);

  useEffect(() => {
    if (error) {
      setOpen(false);
      if (onClose) onClose();
    }

    if (data && !error) {
      message.success('Custom Field Deleted');
      if (onSuccess) onSuccess();
      if (refetchReport) {
        refetchReport();
      }
      if (onClose) onClose();
    }
  }, [data, error, onSuccess, refetchReport, setOpen, onClose]);

  if (!id) return <></>;

  return (
    <>
      {visible && (
        <Popconfirm
          placement="topLeft"
          title={'Do you want to delete custom field?'}
          okText="Yes"
          open={open}
          onConfirm={() => {
            deleteCustomField({
              variables: {
                id: id,
              },
            });
            setOpen(false);
          }}
          onCancel={() => {
            if (onClose) onClose();
            setOpen(false);
          }}
        ></Popconfirm>
      )}
    </>
  );
}

export default CustomFieldDelete;
