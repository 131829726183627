import { Col, Row, Typography } from '@shipmnts/pixel-hub';
import React from 'react';

interface DescriptionItem {
  key: number;
  label: string;
  children: JSX.Element;
  visibility?: boolean;
}

interface DescriptionProps {
  items: Array<DescriptionItem>;
  style?: React.CSSProperties;
  labelSpan?: number;
}

const Description = (props: DescriptionProps) => {
  const { items, style, labelSpan = 4 } = props;
  return (
    <div style={{ ...style }}>
      {items.map((detail) => (
        <Row key={detail.key} style={{ margin: '12px 0' }}>
          <Col span={labelSpan}>
            <Typography.Text type="secondary">{detail.label}:</Typography.Text>
          </Col>
          <Col span={24 - labelSpan}>{detail.children}</Col>
        </Row>
      ))}
    </div>
  );
};

export default Description;
