import { useMutation } from '@apollo/client';
import { Drawer, Popconfirm, message } from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
import { DISABLE_USER_ACCOUNT } from 'src/graphQL/user';
import ResetPasswordForm from './ResetPasswordForm';
import { axiosInstance } from 'src/setup/axiosDefaults';
import sessionStore from 'src/stores/SessionStore';

export const DisableUser = (props: any) => {
  const { user, onSuccess, onClose, onCancel, open, toggleOpen } = props;
  const [disableUser, { loading, error, data }] = useMutation(DISABLE_USER_ACCOUNT);

  const handleSuccess = () => {
    onSuccess && onSuccess();
    toggleOpen && toggleOpen(!open);
    user.disabled ? sessionStore.increaseTotalActiveUser() : sessionStore.decreaseTotalActiveUser();
  };

  const handleCancel = () => {
    onClose && onClose();
    onCancel && onCancel();
    toggleOpen && toggleOpen(!open);
  };

  useEffect(() => {
    if (error) {
      message.error(`Failed to ${user.disabled ? 'enable' : 'disable'} user`);
    } else if (data) {
      message.success(`Successfully ${user.disabled ? 'enabled' : 'disabled'} user`);
      handleSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, data]);

  return (
    <Popconfirm
      arrow={false}
      placement="topLeft"
      title={`Do you want to ${user.disabled ? 'enable' : 'disable'} user?`}
      okText="Yes"
      okButtonProps={{ loading: loading }}
      open={open ?? true}
      onConfirm={() => {
        disableUser({
          variables: {
            id: user.id,
            disabled: !user.disabled,
          },
        });
      }}
      onCancel={handleCancel}
    />
  );
};

export const ResetPassword = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const { disabled } = props;

  return !open ? (
    <span onClick={() => !disabled && setOpen(true)}>Reset Password </span>
  ) : (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <ResetPasswordForm user_id={props.user.id} onSuccess={() => setOpen(false)} />
    </Drawer>
  );
};

export const ResetPasswordDrawer = (props: any) => {
  const { user, onSuccess, onClose } = props;

  return (
    <Drawer open={true} onClose={onClose}>
      <ResetPasswordForm user_id={user.id} onSuccess={onSuccess} />
    </Drawer>
  );
};

export const ResendInvitationEmail = (props: any) => {
  const { user, onSuccess, disabled } = props;

  const sendInvitationEmail = async () => {
    message.success('Sending Email....');
    await axiosInstance
      .get(process.env.REACT_APP_NETWORK_API_URL + '/user_accounts/send_invitation_email', {
        params: {
          id: user.id,
        },
      })
      .then(() => {
        onSuccess && onSuccess();
      })
      .catch((e: any) => {
        message.error('Error sending email, please try again');
      });
  };

  return <span onClick={() => !disabled && sendInvitationEmail()}>Resend Invitation Email</span>;
};
